import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { addValidationRule } from "formsy-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import _ from "../utils/@lodash";

function TextFieldFormsy(props) {
  const params = useLocation()  ;
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const useStyles = makeStyles({
    input: {
      cursor: props.disabled ? 'not-allowed' : "alias",
      // color: props.isSupportChat ? "#fff" : props.isRating ? '#050505' : '#fff',
      color: '#fff',
      borderRadius: '10px',
      '&::selection':{
        backgroundColor: '#fff',
        color: '#022530',
      },
      // border: '2px solid #30505a',
      // '& .MuiFilledInput-root.Mui-focused': {
      //   border: '2px solid #30505a'
      // },
      '&::placeholder': {
        // textOverflow: 'ellipsis !important',
        filter: "brightness(500%)",
        opacity: "10",
        color:"#ffffff",
      },
      '& .MuiFormLabel-root':{
        color: 'rgba(0,0,0,0.54)',
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          // borderColor: "gray",
          border: `2px solid ${themes === '' ? localStorage.getItem('theme') === 'dark' ? "#022530" : "#050505" : themes === 'dark' ? "#022530" : "#050505"}`,
          borderRadius: '10px',
        },
        "&:hover fieldset": {
          // borderColor: "gray",
          border: `2px solid ${themes === '' ? localStorage.getItem('theme') === 'dark' ? "#022530" : "#050505" : themes === 'dark' ? "#022530" : "#050505"}`,
          borderRadius: '10px',
        },
        "&.Mui-focused fieldset": {
          // borderColor: "gray",
          border: `2px solid ${themes === '' ? localStorage.getItem('theme') === 'dark' ? "#022530" : "#050505" : themes === 'dark' ? "#022530" : "#050505"}`,
          borderRadius: '10px',
        },
      },
      "& .MuiFilledInput-root": {
        backgroundColor: 'transparent'
      },
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  });
  const classes = useStyles();

  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "children",
    "className",
    "defaultValue",
    "disabled",
    "FormHelperTextProps",
    "fullWidth",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "multiline",
    "name",
    "onBlur",
    "onChange",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
    "color",
  ]);

  // const { errorMessage } = props;
  // const value =
  //   typeof props.value === "object"
  //     ? AppConfig.currencyType +
  //       " " +
  //       props?.value[0] +
  //       " - " +
  //       AppConfig.currencyType +
  //       " " +
  //       props?.value[1]
  //     : props?.value || "";
  const [dis, setdis] = useState(false);

  function changeValue(event) {
    if (props.onChange) {
      // if (
      //   (props.name === "phone" || props.name === "mobile") &&
      //   event.currentTarget.value.length > 12
      // ) {
      //   setdis(true);
      // } else {
      props.onChange(event);
      // props.setValue(event.currentTarget.value);
      // }
      if (/\d+/.test(props.name)) {
        let number = props.name.match(/\d+/)[0];
        props.onChange(event, number);
      } else {
        // if (
        //   (props.name === "phone" || props.name === "mobile") &&
        //   event.currentTarget.value.length > 12
        // ) {
        //   setdis(true);
        // } else {
        props.onChange(event);
        // }
      }
    }
  }

  const changekey = (ele) => {
    if (props.onkeyPress) {
      props.onkeyPress(ele);
    }
  };

  addValidationRule("min", function (values, value) {
    return value < 0 ? false : true;
  });
  return (
    <TextField
      {...importedProps}
      onChange={changeValue}
      value={props.value}
      // #fef5e4
      className={clsx(props.className, `border-0 ${params.pathname !== '/checkOut' ? "bg-transparent" : 'bg-backgroundColor'}`)}
      error={Boolean(
        (!props.isPristine && props.showRequired) || props.serverError
      )}
      autoComplete="off"

      onWheel={(e) => e.target.blur()}

      InputProps={{
        inputProps: {
          min: props.type === "number" ? 1 : "",
          // maxLength: props.type === "tel" ? 11 : '',
          className: classes.input,
        },
      }}
      helperText={props.serverError}
    />
  );
}

export default React.memo(TextFieldFormsy);
