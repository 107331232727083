import axios from "axios";
import { withRouter } from "react-router";
import AppConfig from "../../appConfig";
import { ActionTypes } from "../../redux/contants/ActionType";
import { showMessage } from "../../redux/reducers/messageSlice";
import store from "../../redux/store";
import FuseUtils from "../../utils/FuseUtils";
import http from "../../utils/http";
/* eslint-disable camelcase */

class ApiService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  setInterseptorRequest = () => {
    axios.interceptors.request.use((req) => {
      let accessToken = this.getAccessToken();
      req.headers.authorization = "my secret token";
      req.baseURL = AppConfig.apiUrl;
      req.headers.accept = "application/json";
      req.headers["Content-Type"] = "application/json";
      req.headers.authorization = accessToken
        ? "Bearer " + accessToken
        : "Bearer ";
      req.headers.Authorization = accessToken
        ? "Bearer " + accessToken
        : "Bearer ";
      return req;
    });
  };

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            (err.response.code === 200) === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
            // JwtService.removeUserData();
          }
          throw err;
        });
      }
    );
  };

  getBanner = () => {
    return new Promise((resolve, reject) => {
      http.get("user/slider").then((response) => {
        if (response.code === 200) {
          resolve(response);
          // store.dispatch(showMessage({ message: 'Successfully Retrived', variant: 'success' }));
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  getFeaturedCollection = (type, page) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          type
            ? `user/sorting/featured-product/${type}?page=${page}`
            : "user/sorting/featured-product"
        )
        .then((response) => {
          if (response.code === 200) {
            resolve(response);
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            reject(response);
          }
        });
    });
  };


  getFreshArrivals = (type, page) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          'user/sorting/recent-product/new-arrivals'
        )
        .then((response) => {
          if (response.code === 200) {
            resolve(response);
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            reject(response);
          }
        });
    });
  };

  getDialingCode = (type, page) => {
    return new Promise((resolve, reject) => {
      http.get("/user/getDialingCode").then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  getCategories = () => {
    return new Promise((resolve, reject) => {
      http.get(`user/getcategory`).then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  getTrendingProducts = (type, page) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          type
            ? `user/sorting/trending-product/${type}?page=${page}`
            : "user/sorting/trending-product"
        )
        .then((response) => {
          if (response.code === 200) {
            resolve(response);
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            reject(response);
          }
        });
    });
  };

  getBannerList = (id, page) => {
    return new Promise((resolve, reject) => {
      http.get(`user/get-banner-with-categoryid/${id}`).then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  getBrandList = () => {
    return new Promise((resolve, reject) => {
      http.get(`user/homepage`).then((response) => {
        resolve(response);
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };
  getAttributesByCategoriesId = (id) => {
    return new Promise((resolve, reject) => {
      http.get(`user/get-attributes-by-categoryId/${id}`).then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };
  getTopProducts = (type, page) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          type
            ? `user/sorting/top-product/${type}?page=${page}`
            : "user/sorting/top-product"
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response);
          }
        });
    });
  };
  stripePayment = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/stripe-payment", data).then((response) => {
        if (response.data) {
          resolve(response.data);

          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };
  getBankDetails = () => {
    return new Promise((resolve, reject) => {
      http.get(`user/bank-details`).then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };
  addBankDetails = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/add-bank-details", data).then((response) => {
        if (response.data) {
          resolve(response.data);

          store.dispatch(
            showMessage({ message: response.data.message, variant: "success" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  DeleteAccount = () => {
    return new Promise((resolve, reject) => {
      http.post("user/delete-account").then((response) => {
        if (response) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };
  returnRequest = (data) => {
    return new Promise((resolve, reject) => {
      http.post("/user/return-request", data).then((response) => {
        if (response) {
          resolve(response);

          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };
  returnRequestCancel = (data) => {
    return new Promise((resolve, reject) => {
      http.post("/user/return-request-cancel", data).then((response) => {
        if (response) {
          resolve(response);

          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  stripePaymentConfirmation = (id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`https://admin.nily.com.br/api/v2/user/order/status/${id}`)
        .then((response) => {
          if (response.data) {
            resolve(response);

            // store.dispatch(
            //   showMessage({ message: response.data.message, variant: "success" })
            // );
          } else {
            reject(response);
          }
        });
    });
  };
  confirmPayment = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/stripe-payment-create", data).then((response) => {
        if (response.data) {
          resolve(response.data);

          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };
  getCategoriesById = (page) => {
    return new Promise((resolve, reject) => {
      http.post("user/category/product", page).then((response) => {
        if (response.data) {
          resolve(response.data.data);

          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  getSellers = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/nearBy/vendors", form).then((response) => {
        if (response.code === 200) {
          resolve(response);

          // store.dispatch(
          //   showMessage({ message: response.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  getMachineStore = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/topStore", form).then((response) => {
        if (response.code === 200) {
          resolve(response);
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };

  getchatbyproduct = (id) => {
    return new Promise((resolve, reject) => {
      http.post(`user/product-session/${id}/chats`).then((response) => {
        if (response) {
          if (response.code == 200) {
            resolve(response);
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getAdminChat = (id) => {
    return new Promise((resolve, reject) => {
      http.post(`user/session/${id}/chats`).then((response) => {
        if (response) {
          if (response.code == 200) {
            resolve(response);
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };


  getmyChats = () => {
    return new Promise((resolve, reject) => {
      http.get("/user/customer/productChat/list").then((response) => {
        if (response) {
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: "chat fetched successfully",
            //     variant: "success",
            //   })
            // );
            resolve(response);
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          // reject(response);
        }
      });
    });
  };
  sendChat = (session_id, data) => {
    return new Promise((resolve, reject) => {
      http
        .post(`user/product-session/send/${session_id}`, data)
        .then((response) => {
          if (response.code == 200) {
            // showMessage({ message: response.message, variant: "error" })
            resolve(response);
          } else if (response.code == 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            reject(response);
          }
        });
    });
  };
  sendChatToAdmin = (session_id, data) => {
    return new Promise((resolve, reject) => {
      http
        .post(`user/send/${session_id}`, data)
        .then((response) => {
          if (response.code == 200) {
            // showMessage({ message: response.message, variant: "error" })
            resolve(response);
          } else if (response.code == 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            reject(response);
          }
        });
    });
  };
  sendForgotPasswordOTP=(data)=>{
    return new Promise((resolve, reject) => {
      http
        .post(`forgot-password/otp-send`, data)
        .then((response) => {
          if (response.code == 200) {
            // showMessage({ message: response.message, variant: "error" })
            resolve(response);
          } else if (response.code == 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            reject(response);
          }
        });
    });
  }
  verifyForgotpasswordOtp=(data)=>{
    return new Promise((resolve, reject) => {
      http
        .post(`forgot-password`, data)
        .then((response) => {
          if (response) {

            // showMessage({ message: response.message, variant: "error" })
            resolve(response);
          } else if (response.code == 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            reject(response);
          }
        });
    });
  }
  CreateSessionIdForProduct = (data) => {
    return new Promise((resolve, reject) => {
      http.post("/user/product-session/create", data).then((response) => {
        if (response.code === 200) {
          // window.sessionStorage.setItem("session_id",session_id)

          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  updateAccountOtp = (data) => {
    return new Promise((resolve, reject) => {
      http.post("/user/update-request", data).then((response) => {
        if (response) {
          // window.sessionStorage.setItem("session_id",session_id)

          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  verifyEmailOtp = (data) => {
    return new Promise((resolve, reject) => {
      http.post("/user/update-request/otp", data).then((response) => {
        if (response) {
          // window.sessionStorage.setItem("session_id",session_id)

          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  createAdminSession = (data) => {
    return new Promise((resolve, reject) => {
      http.post("/user/session/create", data).then((response) => {
        if (response) {
          // window.sessionStorage.setItem("session_id",session_id)

          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };

  getProductById = (id) => {
    return new Promise((resolve, reject) => {
      http.get("user/productById/" + id).then((response) => {
        if (response.code === 200) {
          resolve(response);

          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  addToCart = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/cart/save", form).then((response) => {
        if (response) {
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
            resolve(response);
          } else if (response.code === 422) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "success" })
            // );

            resolve(response);
          } else {
            // store.dispatch(
            //   showMessage({ message: response.errors, variant: "error" })
            // );
          }
        } else {
          // store.dispatch(
          //   showMessage({ message: response.errors, variant: "error" })
          // );
          reject(response);
        }
      });
    });
  };
  readNotifications = (form) => {
    return new Promise((resolve, reject) => {
      http.get("user/customer/readNotifications", form).then((response) => {
        if (response) {
          if (response.code === 200) {
            store.dispatch({
              type: ActionTypes.ADD_USER_Notification_COUNT,
              payload: 0,
            });
            resolve(response);
          } else if (response.code === 422) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "success" })
            // );

            resolve(response);
          } else {
            // store.dispatch(
            //   showMessage({ message: response.errors, variant: "error" })
            // );
          }
        } else {
          // store.dispatch(
          //   showMessage({ message: response.errors, variant: "error" })
          // );
          reject(response);
        }
      });
    });
  };

  applyCouponCode = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/coupon/apply-coupon-code", form).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //   })
            // );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.errors, variant: "success" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.errors, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  AddOtherSellerItemInCart = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/cart/seller-not-same", form).then((response) => {
        if (response.code == 200) {
          store.dispatch(
            showMessage({
              message: response.data.message,
              variant: "success",
            })
          );

          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.errors, variant: "success" })
          );
        } else {
          store.dispatch(
            showMessage({ message: response.errors, variant: "error" })
          );
          reject(response);
        }
      });
    });
  };

  getCouponCodes = () => {
    return new Promise((resolve, reject) => {
      http.get("user/coupon/get-coupon-code").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //   })
            // );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "success" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  placeOrder = (form) => {
    return new Promise((resolve, reject) => {
      http.post("/user/order/create", form).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //   })
            // );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "success" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getCartDetails = (id) => {
    return new Promise((resolve, reject) => {
      http.post("user/cart/list", id).then((response) => {
        if (response.code === 200) {
          resolve(response.data);
          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };

  getOrderId = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/order/generate-order-id", data).then((response) => {
        if (response.code === 200) {
          resolve(response.data);
          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  getOrderById = (data) => {
    return new Promise((resolve, reject) => {
      http.get("user/order/order-Details-By/" + data).then((response) => {
        if (response.code === 200) {
          resolve(response.data);
          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  getFollowingById = (id, page) => {
    return new Promise((resolve, reject) => {
      http.get("user/vendorById/" + id + "?page=" + page).then((response) => {
        if (response.code === 200) {
          resolve(response);
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };

  searchProduct = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/globalSearch", data).then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
        } else {
          reject(response);
        }
      });
    });
  };
  followSellerById = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/follow", form).then((response) => {
        if (response.code === 200) {
          store.dispatch(
            showMessage({ message: response.data.message, variant: "success" })
          );
          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };
  getAllOrderList = (state, page) => {
    return new Promise((resolve, reject) => {
      http
        .get(`user/order/history${state && "/" + state}?page=${page}`)
        .then((response) => {
          if (response.code === 200) {
            resolve(response);

            // store.dispatch(
            //   showMessage({ message: response.data.message, variant: "success" })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            reject(response);
          }
        });
    });
  };

  getOrderList = (type, page) => {
    return new Promise((resolve, reject) => {
      http
        .get(`${type ? `user/order/historyByOrder/${type}` : 'user/order/historyByOrder'}`)
        .then((response) => {
          if (response.code === 200) {
            resolve(response);

            // store.dispatch(
            //   showMessage({ message: response.data.message, variant: "success" })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            reject(response);
          }
        });
    });
  };
  getCmsData = () => {
    return new Promise((resolve, reject) => {
      http.get("user/cms").then((response) => {
        if (response.code === 200) {
          resolve(response);

          // store.dispatch(
          //   showMessage({ message: response.data.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };
  getOrderCheckout = (id) => {
    return new Promise((resolve, reject) => {
      http.post("user/order/checkout", id).then((response) => {
        if (response.code == 200) {
          resolve(response);
        } else if (response.code === 504) {
        } else {
          reject(response);
        }
      });
    });
  };
  cancelOrderById = (id) => {
    return new Promise((resolve, reject) => {
      http.post("user/order/cancel", id).then((response) => {
        if (response.code === 200) {
          store.dispatch(
            showMessage({ message: response.data.message, variant: "success" })
          );
          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else if (response.code == 422) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };
  getCartCount = (id) => {
    return new Promise((resolve, reject) => {
      http.post("user/cart/item-count", id).then((response) => {
        if (response.code === 200) {
          resolve(response);
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "success" })
          // );
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };

  updateCartItem = (id) => {
    return new Promise((resolve, reject) => {
      http.post("user/cart/update", id).then((response) => {
        if (response.code === 200) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "success" })
          // );
          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };

  deleteCartItem = (id) => {
    return new Promise((resolve, reject) => {
      http.delete("user/cart/remove/" + id).then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };

  deleteCustomerAddress = (id) => {
    return new Promise((resolve, reject) => {
      http.delete("user/address/remove/" + id).then((response) => {
        if (response.code === 200) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "success" })
          // );
          resolve(response);
        } else if (response.code === 504) {
          store.dispatch(
            showMessage({ message: response.message, variant: "error" })
          );
        } else {
          reject(response);
        }
      });
    });
  };

  sendOtp = (data) => {
    return new Promise((resolve, reject) => {
      http.post("login", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  signUP = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user-signup", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  signUpWithEmail = (data) => {
    return new Promise((resolve, reject) => {
      http.post("login-with-password", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            localStorage.setItem(
              AppConfig.localAppKey,
              response.data.data.access_token
            );
            sessionStorage.setItem(
              AppConfig.localAppKey,
              response.data.data.access_token
            );
            sessionStorage.setItem(
              AppConfig.localIdKey,
              response.data.data.user.id
            );
            store.dispatch({
              type: ActionTypes.ADD_USER_DATA,
              payload: response.data.data,
            });
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  verifyOtp = (data) => {
    return new Promise((resolve, reject) => {
      http.post("otp/verify", data).then((response) => {
        if (response) {
          if (response.code === 200) {
            localStorage.setItem(
              AppConfig.localAppKey,
              response.data.data.access_token
            );
            sessionStorage.setItem(
              AppConfig.localAppKey,
              response.data.data.access_token
            );
            sessionStorage.setItem(
              AppConfig.localIdKey,
              response.data.data.user.id
            );
            store.dispatch({
              type: ActionTypes.ADD_USER_DATA,
              payload: response.data.data,
            });

            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
            setTimeout(() => {
              resolve(response);
            }, 200);
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else if (response.code === 422) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
            resolve(response);
          }
        } else {
          reject(response);
        }
      });
    });
  };

  resendCustomerOtp = (data) => {
    return new Promise((resolve, reject) => {
      http.post("otp/resend", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  addCustomerAddress = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/address/save", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  addItemToWishlist = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/add/wishlist", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  addProductRating = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/productRating", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.errors, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.errors, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  changePassword = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/password-change", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  updateCustomerAddress = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/address/update", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  acceptOffer = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/review-deal", data).then((response) => {
        if (response) {
          if (response.code === 200) {
            resolve(response);
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getAddress = (id) => {
    return new Promise((resolve, reject) => {
      http.get("user/address/get-all-address").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getFedexToken = (id) => {
    return new Promise((resolve, reject) => {
      http.post("user/fedex-tokan").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getCurrencyRate = () => {
    return new Promise((resolve, reject) => {
      http.get("currency").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getDeliveryFee = (data) => {
    return new Promise((resolve, reject) => {
      http.post("user/fedex-rate", data).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getPlaceAddress = (address) => {
    return new Promise((resolve, reject) => {
      http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCbZQKhsD_-nzwJSvF14bnkazQmuzsNtlM`).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  }
  getConfig = (id) => {
    return new Promise((resolve, reject) => {
      http.get("/user/userconfig").then((response) => {
        if (response) {
          if (response.code === 200) {
            window.sessionStorage.setItem("prefix_id", response.data.PREFIX_ID);
            resolve(response);
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getNotifications = (id) => {
    return new Promise((resolve, reject) => {
      http.get("user/customer/myNotifications").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getFollowingDetails = () => {
    return new Promise((resolve, reject) => {
      http.get(`user/myFollowings`).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getCustomerearning = () => {
    return new Promise((resolve, reject) => {
      http.get(`user/earnings`).then((response) => {
        if (response) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };
  getCustomerReview = () => {
    return new Promise((resolve, reject) => {
      http.get(`user/productReview/list`).then((response) => {
        if (response) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };
  contactUsData = (id) => {
    return new Promise((resolve, reject) => {
      http.get("user/common-option").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getWishlistDetails = (id) => {
    return new Promise((resolve, reject) => {
      http.get("user/myWishlist").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };
  getProfile = () => {
    return new Promise((resolve, reject) => {
      http.get("user/myProfile").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getMargin = () => {
    return new Promise((resolve, reject) => {
      http.get("user/userconfig").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  calculateMargin = (_form) => {
    return new Promise((resolve, reject) => {
      http.post("user/margin-percentage", _form).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.success,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };


  updateProfile = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/profileUpdate", form).then((response) => {
        if (response) {
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
            resolve(response);
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.data.message, variant: "error" })
            );
          } else {
            resolve(response);
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getAccessToken = () => {
    return window.sessionStorage.getItem(AppConfig.localAppKey);
  };
}

const instance = new ApiService();

export default withRouter(instance);
