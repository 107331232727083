import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ApiService from "../services/apiService";
import "../styles/Slick.css";

export default function HomeSlider({ viewWidth, mobile }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    ApiService.getBanner().then((res) => {
      if (res.code === 200) {
        setImages(res.data.data.slider);
        setLoading(false);
      }
    });
  }, []);
  const history = useHistory();
  return (
    <React.Fragment>
      {loading ? (
        <Skeleton height={400} width={'100%'} className="sm:px-10 sm:mb-2 px-4" />
      ) : (
        <div className="border-secondary    lg w-full  mb-4 shadow-xl">
          <Carousel
            infiniteLoop
            useKeyboardArrows
            autoPlay
            stopOnHover={false}
            showThumbs={false}
            height={200}
          >
            {images.map((img, key) => (
              <div
                className="cursor-pointer"
                onClick={() =>
                  img.link !== null && (
                    <>{(window.location.href = `${img.link}`)}</>
                  )
                }
                key={key}
              >
                {img.type === "Web" && (
                  <img className="object-contain" src={img.ImageSrc} />
                )}
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </React.Fragment>
  );
}
