import {
  Button, Checkbox, CircularProgress, Divider,
  FormControl, FormControlLabel, FormHelperText, InputLabel, makeStyles, Select, TextField,
  Typography
} from "@material-ui/core";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Hidden } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import ApiService from "../../services/apiService";
import SelectReason from "./SelectReason";


export default function CancelOrder() {
  const [option, setOption] = useState();

  const useStyles = makeStyles((theme) => ({

    ul: {
      "& .MuiPaginationItem-root": {
        padding: 0,
        overflow: 'hidden',
      }
    }
  }));
  const classes = useStyles();
  const { t } = useTranslation();
  // reasons for order Cancellation
  const reason = [
    {
      id: t("reasons.cancelReasonOne"),
      name: t("reasons.cancelReasonOne"),
    },
    {
      id: t("reasons.cancelReasonTwo"),
      name: t("reasons.cancelReasonTwo"),
    },
    {
      id: t("reasons.cancelReasonThree"),
      name: t("reasons.cancelReasonThree"),
    },
    {
      id: t("reasons.cancelReasonFour"),
      name: t("reasons.cancelReasonFour"),
    },
    {
      id: t("reasons.cancelReasonFive"),
      name: t("reasons.cancelReasonFive"),
    },
    { id: t("reasons.cancelReasonSix"), name: t("reasons.cancelReasonSix") },
    {
      id: t("reasons.cancelReasonSeven"),
      name: t("reasons.cancelReasonSeven"),
    },
    {
      id: t("reasons.cancelReasonEight"),
      name: t("reasons.cancelReasonEight"),
    },
  ];
  const [comment, setComment] = useState("");
  const [cancel_reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [order, setOrder] = useState();
  const [currency_type, setCurrencyType] = useState();
  const history = useHistory();
  const location = useLocation();
  const [tempArray, setTempArray] = useState([]);
  const [productindeletelist, setProductInDeleteList] = useState();
  // handle change on reasons dropdown
  const handleChange = (e) => {
    if (e.target.name === "reason") {
      setReason(e.target.value);
    } else {
      setComment(e.target.value);
    }
  };

  useEffect(() => {
    setOption(location.state.data);
    window.scrollTo(0, 0)
    setOrder(location.state.order);
    if (location.state.order) {
      setCurrencyType(location.state.order.get_item[0].product_detail[0].currency_data.symbol)
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [ids, setids] = useState();
  // function for cancelling order
  const cancelOrder = () => {
    let _form = new FormData();
    _form.append("reason", cancel_reason);

    _form.append("remark", comment);
    if (tempArray?.length > 0) {
      tempArray.map((ele, key) => {
        _form.append(`order_item_id[${key}]`, ele);
      })
    } else {
      alert('Please Select The Product');
    }


    _form.append("order_id", order.id);
    // calling cancel order api
    ApiService.cancelOrderById(_form).then((res) => {
      if (res.code === 200) {
        setIsLoading(false);
        history.goBack();
      } else if (res.code === 422) {
        setError(res.errors.reason[0]);
      }
    });
  };


  return (
    <React.Fragment>
      <div>
        <Typography
          variant={"h5"}
          className="py-5 text-center uppercase dark:bg-lightDarkBackground dark:text-fontWhite bg-gray-200 border-b-2"
        >
          {t("cancelOrder.orderCancel")}
        </Typography>

        {order?.status !== "Cancelled" ? (
          <div className="md:grid grid-cols-1  md:mx-10 my-5 mt-10 md:grid-cols-2">
            {isLoading ? (
              <>
                <div className="w-screen text-center">
                  <CircularProgress />
                </div>
              </>
            ) : (
              <>
                <div className="mx-4 px-2 pt-4 dark:bg-lightDarkBackground shadow-md">
                  <div className="text-center">
                    <div className="grid grid-cols-1 gap-5 mx-8 my-2 ">
                      <div>
                        <SelectReason reason={reason} cancelReason={cancel_reason} error={error} handleChange={handleChange} />
                      </div>
                      <div className="">
                        <TextField
                          id="outlined-basic"
                          name="comment"
                          placeholder={t("cancelOrder.comments")}
                          type="text"
                          onChange={handleChange}
                          value={comment}
                          multiline
                          rows={5}

                          variant="outlined"
                          className='text-fontDark dark:text-button w-full'
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          variant="contained"
                          className="px-4 w-auto bg-button text-white uppercase"
                          onClick={() => cancelOrder()}
                        >
                          {t("cancelOrder.continue")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-4 mx-4 mt-8 md:mt-0 shadow-md">
                  <Typography className="px-5 py-3 text-white uppercase bg-button">
                    {t("cancelOrder.itemInfo")}
                  </Typography>

                  <React.Fragment>
                    <div className="mt-4">

                      {order?.get_item.map((item, key) => (
                        <div className={`grid grid-cols-1 mx-0  bg-background md:grid-cols-3 ${item.status === 'Cancelled' ? 'opacity-60' : ''}`}>
                          <div className="mt-1 flex text-center md:mt-0">
                            <div className="dark:bg-lightDarkBackground">

                              <FormControlLabel
                                className="text-xs font-normal"
                                control={<Checkbox onChange={() => {
                                  if (tempArray.includes(item.id)) {
                                    setTempArray(
                                      tempArray.filter((data) => data !== item.id),
                                    );
                                  } else {
                                    setTempArray([...tempArray, item.id])
                                  }
                                }} disabled={item.status !== 'Cancelled' ? false : true} />}
                                // onClick={(prev, next) => {

                                // console.log(prev, next)
                                // setDeleteItem(item.id);
                                // console.log(tempArray)
                                // if (tempArray.includes(item.id)) {
                                //   let temp = tempArray.filter(data => data !== item.id)
                                //   console.log(temp)
                                //   setTempArray(temp);
                                // } else {
                                //   setTempArray({ ...tempArray, item.id })
                                // }
                                // }}
                                label={""}
                              />
                            </div>
                            <div className="dark:bg-lightDarkBackground"><img
                              draggable={false}
                              alt="text"
                              className="justify-center mx-auto text-center md:mx-0 md:w-100  w-64 md:h-auto h-64 justify-items-center"
                              src={item?.product_detail[0].ImageSrc}
                            />
                            </div>
                          </div>
                          <div className="grid-flow-row dark:bg-lightDarkBackground md:col-span-2 py-2 px-4">
                            <div>
                              <Typography
                                variant="subtitle1"
                                className="py-2 text-justify dark:text-fontWhite break-words"
                                onClick={() => {
                                  history.push({
                                    pathname: `/product-details/${item?.product_detail[0].product_name}`,
                                    state: {
                                      id: item?.product_detail[0].id,
                                      type: "Cancel Order",
                                    },
                                  });
                                }}
                              >
                                {item?.product_detail[0].product_name}
                              </Typography>
                            </div>

                            <div className="">
                              <Typography
                                variant="body2"
                                className="text-md font-normal dark:text-fontWhite text-gray-500 "
                              >
                                {t("cancelOrder.qty")} : {item?.quantity}
                              </Typography>
                            </div>

                            {typeof item !== "undefined" &&
                              Object.entries(
                                JSON.parse(item.product_option)
                              ).map((key, value) => (
                                <div className="my-1 flex-1 ">
                                  <Typography
                                    variant="body2"
                                    className="text-md dark:text-fontWhite text-gray-500 "
                                    key={value}
                                  >
                                    {`${key[0]} : ${key[1]}`}
                                  </Typography>
                                </div>
                              ))}

                            <div>
                              <Typography variant="body2" className=" dark:text-fontWhite text-md dark:text-button">
                                {t("cancelOrder.price")}: {currency_type}{" "}
                                {item?.offer_price}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2" className=" dark:text-fontWhite text-md dark:text-button">
                                {t("cancelOrder.deliveryFee")}: {currency_type}{" "}
                                {order?.delivery_charges}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2" className=" dark:text-fontWhite text-md dark:text-button">
                                {t("myOrders.orderAmount")}: {currency_type}{" "}
                                {order?.total_price}
                              </Typography>
                            </div>
                            <div className="flex my-2">
                              <div><FiberManualRecordIcon
                                sx={{
                                  height: 16,
                                  width: 16,
                                  color: item.status === 'Cancelled' ? "red" : "green",
                                }}
                                className=" mr-1"
                              /></div>
                              <Typography variant="body2" className=" dark:text-fontWhite text-md dark:text-button font-bold">
                                {item?.status}
                              </Typography>
                            </div>
                          </div>
                        </div>))}
                    </div>
                  </React.Fragment>
                  {/* ))} */}
                </div>
              </>
            )}
          </div>
        ) : (
          <React.Fragment>
            <div className="grid grid-cols-1 mx-10 my-4  ">
              <Typography className="px-5 py-3 dark:text-fontWhite text-white uppercase ">
                {t("cancelOrder.itemInfo")}{" "}
              </Typography>

              <React.Fragment>
                <div className="bg-gray-200 ">
                  {order.get_item.map((item, key) => (
                    <div className="grid grid-cols-1 mx-0 p-5  bg-gray-200 md:grid-cols-3">
                      <div className="grid-flow-row px-10">
                        <div>
                          <Typography
                            variant="h5"
                            className="py-3 text-justify break-words"
                            onClick={() => {
                              history.push({
                                pathname: `/product-details/${item.product_detail[0].product_name}`,
                                state: {
                                  id: item.product_detail[0].id,
                                  type: "Cancel Order",
                                },
                              });
                            }}
                          >
                            {item.product_detail[0].product_name}
                          </Typography>
                        </div>

                        <div className="mx-4">
                          <Typography
                            variant="body2"
                            className="text-md font-normal text-gray-500 "
                          >
                            {t("cancelOrder.qty")}: {item.quantity}
                          </Typography>
                        </div>

                        {typeof item !== "undefined" &&
                          Object.entries(JSON.parse(item.product_option)).map(
                            (key, value) => (
                              <div className="my-1 mx-4 flex-1">
                                <Typography
                                  variant="body2"
                                  className="text-md text-gray-500 "
                                  key={value}
                                >
                                  {`${key[0]} : ${key[1]}`}
                                </Typography>
                              </div>
                            )
                          )}

                        <div className="mx-4">
                          <Typography variant="body2" className=" text-md">
                            {currency_type} {item.offer_price}
                          </Typography>
                        </div>

                        <div className="py-4 px-2 border rounded-md">
                          {item?.status.includes("Completed") ||
                            item?.status.includes("Processing") ? (
                            <React.Fragment>
                              <Typography
                                variant={"span"}
                                className="text-base font-normal text-green-500 flex"
                              >
                                <div><FiberManualRecordIcon
                                  sx={{
                                    height: 16,
                                    width: 16,
                                    color: "red",
                                  }}
                                  className="my-1 mr-1"
                                /></div>
                                <div>
                                  {item?.status}
                                </div>
                              </Typography>
                              <span className="mx-2 text-md">{`(Order ID:${order?.order_number})`}</span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Typography
                                variant={"span"}
                                className="text-base font-normal text-red-500"
                              >
                                <div><FiberManualRecordIcon
                                  sx={{
                                    height: 16,
                                    width: 16,
                                    color: "red",
                                  }}
                                  className="my-1 mr-1"
                                /></div>
                                <div>
                                  {item?.status}
                                </div>
                              </Typography>
                              {item?.status === "Cancelled" ? (
                                <span className="mx-2 text-md">{`(Order ID:${order?.order_number})`}</span>
                              ) : (
                                <span className="mx-2 text-md">{`(Refund ID:${order?.order_number})`}</span>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className="mx-2 w-72 py-10 ">
                        <div className="flex justify-between">
                          <Typography variant={"body2"}>
                            {t("cancelOrder.productAmount")}:{" "}
                          </Typography>
                          <Typography>
                            {currency_type}{" "}
                            {item?.product_detail[0].price
                              ? item?.product_detail[0].price
                              : 0}
                          </Typography>
                        </div>
                        <div className="flex justify-between">
                          <Typography variant={"body2"}>
                            {t("cancelOrder.deliveryFee")}:{" "}
                          </Typography>
                          <Typography>
                            + {currency_type} {0}
                          </Typography>
                        </div>
                        <div className="flex justify-between">
                          <Typography variant={"body2"}>
                            {t("cancelOrder.orderDiscount")}:
                          </Typography>
                          <Typography>
                            - {currency_type}{" "}
                            {order.discount_price
                              ? order.discount_price
                              : 0}
                          </Typography>
                        </div>

                        <div className="py-3">
                          <Divider sx={{ width: 50 }} className=" w-full " />
                        </div>
                        <div className="flex justify-between">
                          <Typography variant={"body2"}>Order Total:</Typography>

                          <Typography>
                            {" "}
                            {currency_type}{" "}
                            {order.total_price}
                          </Typography>
                        </div>
                      </div>
                      <div className="mt-5 text-center float-center  md:my-auto py-4">
                        <img
                          draggable={false}
                          alt="text"
                          className="justify-center  text-right mx-6  h-28 w-28 float-center  justify-items-center"
                          src={item.product_detail[0].ImageSrc}
                        />
                      </div>
                    </div>))}
                  <div className="mx-6 mb-6 ">
                    <Divider />
                  </div>
                </div>
              </React.Fragment>
              {/* ))} */}
            </div>
          </React.Fragment>
        )
        }
      </div >
    </React.Fragment >
  );
}
