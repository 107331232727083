const sp = {
  translation: {
    alertComponent: {
      otherSellerProductsinCart:
        "Su carrito contiene productos de otros vendedores, elimine su Carrito",
      alert: "Alerta",
      removeOtherAddThis: "Eliminar otros elementos y añadir Este",
      cancel: "Cancelar",
    },
    productcard: {
      addtowishlist: "añadir a la lista de deseos",
      removefromwishlist: "Eliminar de la lista de deseos",
    },
    header: {
      search: "Buscar",
      language: "Idioma",
      categories: "Categorias",
      home: "Início",
      alert: "Alerta",
      stockNotAvailable: "Inventario no Disponible",
      ok: "OK",
      profile: "perfil",
      myChats: "Mis Chats",
      myFollowing: "Mis Seguidores",
      logout: "Sair",
      myCart: "Mi Carrito",
      myOrders: "Mis Ordenes",
      wishlist: "",
      notifications: "Notifications",
      cart: "Carro",
      login: "acceso",
      account: "Account",
    },
    paymentForm: {
      paymentOptions: "Tarjeta de Débito, Tarjeta de Crédito, Banca Neta,, UPI",
      cashOnDelivery: "Contrareembolso",
    },
    checkOutForm: {
      payNow: "Pagar Ahora",
    },
    addressForm: {
      city: "Ciudad",
      close: 'Cerca',
      document: 'Documento de la empresa',
      state: "Estado",
      locality: "Localidade",
      shippingAddress: "Dirección de entrega",
      addAddress: "Agregar nueva dirección",
      saveAddress: 'Guardar dirección',
      editAddress: 'Editar dirección',
      name: "Nombre",
      address: "Dirección",
      mobile: "Teléfono Celular",
      searchplace: 'Buscar lugares...',
      landmark: "Punto de referência",
      pinCode: "Código PIN",
      addressType: "Tipo de dirección",
      home: "Inicio",
      office: "Oficina",
      country: "Nada Encontrado",
      NearByLocation: "Ubicación cercana",
      statecode: "Código del estado",
      countrycode: "Código de país",
      confirmation: "Confirmación",
      confirmationMessage: "Debido a que se cambió el código de país de la dirección comercial, la moneda predeterminada y los productos agregados anteriormente se configurarán como inactivos. Por favor, confirme o cancele la solicitud."
    },
    noFound: {},
    order: {
      shippingAddress: "Dirección de Entrega",
      mobile: "Teléfono Celular",
      deliveryAt: "Entrega En",
      orderDetails: "Detalles del Pedido",
      qty: "Cantidad",
      status: "Pedido",
      paymentMethod: "Método de Pago",
      orderedOn: "Pedido En",
      customerDetails: "Detalles do Cliente",
      trackOrder: "Seguimiento del Pedido",
      backToOrder: "Volver al Pedido",
      orderAmount: "Total de la orden",
      orderId: 'Solicitar ID',
      more: 'Más',
      discountAmount: 'Importe de descuento del pedido',
      rating: 'Dar calificación',
    },

    cancelOrder: {
      reasonForCancel: "Razón de la Cancelación",
      reasonForReturn: "razón para regresar",
      orderCancellation: "Cancelamento do Pedido",
      cancelHeading: "¿Cancelar solicitud de devolución?",
      orderCancel: "Cancelar Pedido",
      returnCancel: "Devolución de pedido",
      continue: "Continuar",
      itemInfo: "Información del Item",
      returnRequestCancel: "Si, quiero cancelar",
      qty: "Qty",
      deliveryFee: "Gastos de Envío",
      orderDiscount: "Descuento del Pedido",
      productAmount: "Cantidad de Producto",
      price: "Precio",
      comments: "Reseñas",
      confirmMsg: "¿Está seguro de que desea cancelar la solicitud de devolución de este pedido?",
      feedback: "dé su opinión",
      feedbackMsg: "¿Como calificarias este producto?",
      submitRating: "Enviar",
      feedbackComment: "Añadir tu reseña",
    },
    footer: {
      quickShop: "Compra Rapida",
      informations: "Información",
      customerService: "Servicio al Cliente",
      contactUs: "Hable con Nosotros",
      supportChat: "Charla de soporte",
    },
    mobileFooter: {
      home: "Inicio",
      category: "Categoria",
      new: "Nuevo",
      me: "Yo",
      bag: "Bolsa",
      myOrders: "Mis ordenes",
      categoriesDrawer: {
        categories: "Categories",
      },
    },
    common: { addToCart: "Añadir al Carrito" },
    card: {
      button: { addToCart: "Añadir al Carrito", productDetail: "Ver Detalles" },
    },
    shop: {
      home: "Início",
      mobileDisplay: "Display del teléfono Celular",
      categoryHeading: "Categorias",
      showingHeading: "Demostración",
      size: "Tamaño",
      price: "Precio",
      brand: "Marca",
      discount: "Descuento",
      andMore: "Y Más",
      color: "Color",
      filter: "Filtrar",
      allFilter: "Todos los Filtros",
      applyAllFilters: "Aplicar todos los Filtros",
      clear: "Limpio",
      more: "Más",
      productFilters: "Filtros de Productos",
      noFilters: "Sin Filtros",
      showingProducts: "Mostrando Productos",
      products: "Productos",
      of: "Del",
      noProductFound: "No se encontró ningún producto",
      noProducts: "Sin productos",
    },
    productDetails: {
      all: "Todos",
      one: "Una",
      two: "Dos",
      three: "Tres",
      four: "Quatro",
      five: "Cinco",
      soldBy: "Vendido Por",
      outOfStock: "Agotado",
      addToWishList: "Añadir a la lista de Deseos",
      viewShop: "Ver Tienda",
      chatWithUs: "Hable con Nosotros",
      ratings: "Reseñas",
      followers: "Seguidores",
      products: "Produtos",
      productDetails: "Detalles de producto",
      productRatingAndReviews: "Clasificación y reseñas de Productos",
      similarProducts: "Productos Similares",
      reviews: "Clasificación",
      removeFromWishlist: "Eliminar de la lista de Deseos",
      share: "Cuota",
      off: "APAGADA",
      youSave: "Ahorras",
    },
    myOrders: {
      filters: "Filtrar",
      orderStatus: "Detalles del Pedido",
      onTheWay: "En Camino",
      delivered: "Entregado",
      cancelled: "Cancelar",
      returned: "Devuelto",
      orderTime: "Tiempo de la Pedido",
      last30Days: "Últimos 30 Días",
      processing: "Processing",
      older: "Más viejo",
      soldBy: "Vendido Por",
      viewOrder: "Ver Pedido",
      cancelOrder: "Cancelar Pedido",
      returnOrder: "Orden de devolución",
      returnCancelOrder: "Cancelar pedido de devolución",
      address: "Dirección",
      paymentDetail: "Detalles del Pago",
      COd: "Código",
      transactionId: "ID de Transacción",
      myOrders: "Minhas Ordens",
      filter: "Filtrar",
      openMainMenu: "Abrir menú Principal",
      orderedOn: "Pedido El",
      orderAmount: "Pedido El",
      qty: "Cantidad",
    },
    myProfile: {
      profile: "perfil",
      alert: "Alerta",
      ok: "Ok",
      updateProfile: "Actualizar",
      firstName: "Primer Nombre",
      city: "Ciudad",
      email: "Dirección de correo Electrónico",
      phone: "Número de Teléfono",
      state: "Estado",
      address: "Dirección",
      dialing_code: "Seleccionar código de país",
      contactMessage: "Póngase en contacto con el soporte para cambiar el número de teléfono",
      personalInfo: "Informação pessoal",
      accountInfo: "Información de la cuenta",
      alert: "Verificación OTP",
      message: "Se ha enviado OTP a su número de móvil",
      emailMessage: "OTP ha sido enviado a su correo electrónico",
      otpError: "el campo otp es obligatorio",
      image: "tamaño recomendado 500x500px",
      phoneError: "el número de teléfono ya existe",
      emailError: "el Email ya existe",
      changePassword: "Cambia la contraseña",
      password: "Contraseña anterior",
      newPass: "Nueva contraseña",
      confirmPass: "Confirmar contraseña",
      confirmPassError:"Confirmar contraseña no coincide",
    },
    providingFeatures: {
      features: {
        i: {
          heading: "Pago Seguro",
          subHeading: "Pague con los métodos de pago más seguros del Mundo",
        },
        ii: {
          heading: "Confianza",
          subHeading: "La protección cubre su compra y sus datos Personales",
        },
        iii: {
          heading: "Envíos a todo el Mundo",
          subHeading: "Envío gratuito y rápido a más de 200 Países y Regiones.",
        },
        iv: {
          heading: "Línea Directa",
          subHeading:
            "Póngase en contacto con la línea de ayuda para su pregunta En",
        },
      },
    },
    cartDetails: {
      product: "Productos",
      quantity: "La Cantidad",
      total: "Total",
      price: "Precio",
      shopNow: "Compre Ahora",
      noItems: "No hay itens en el Carrito",
      checkout: "Verificar",
      subtotal: "Total Parcial",
      discount: "Descuento",
      shipping: "Transporte",
      yourCart: "Tu Carrito",
      addOnlyTenProducts: "Solo puedes añadir diez Productos",
      action: "Acción",
      ok: "ok",
      nocartitem: 'No hay artículos en el carrito',
      cartEmpty: 'Tu carrito esta vacío.',
      wishlistEmpty: "Tu lista de deseos Esta vacía.",
    },

    checkout: {
      alert: "Alerta",
      ok: "Ok",
      checkout: "Check out",
      refresh: "Actualizar",
      orderProcessing: "Se está procesando el pago de su pedido",
      thankYouForOrder: "Gracias por el Pedido",
      orderNumberIs: "El numero de pedido Es",
      transactionIdIs: "El ID de la transacción Es",
      continueShopping: "Seguir Comprando",
      back: "De Vuelta",
      next: "Próximo",
      placeOrder: "Hacer el Pedido",
      payNow: "Pagar Ahora",
      shippingAddress: "Dirección de Envío",
      paymentMethod: 'Método de pago',
      orderSummary: "Resumen del pedido",
    },
    review: {
      to: "a",
      days: 'Días',
      minimumOrderValue: "La cantidad mínima de pedido Es",
      maimumDiscountIS: "El descuento máximo Es",
      orderSummary: "Resumen del Pedido",
      productName: "Nombre del Producto",
      originalPrice: "Precio Original",
      offerPrice: "Precio de Oferta",
      orderAmount: "Total de la orden",
      offerDiscount: "Descuento del producto",
      subTotal: "Total Parcial",
      Qty: "La cantidad",
      productAmount: "Precio del producto",
      deliveryFee: "Gastos de Envío",
      marginPrice: "Precio de Margen",
      orderDiscount: "Cupón de descuento",
      orderTotal: "Pedidos Totales",
      chooseCoupon: "Elegir Cupón",
      remove: "Para Eliminar",
      applyCoupon: "Aplicar Cupón",
      youSave:"Ahorras",
      youSaveAdditional: "Ahorras Adicional",
      shipping: "Transporte",
      paymentMethod: "Forma de Pago",
      errorMessage: 'Seleccione una dirección válida.',
      margin: "Ingrese la cantidad del margen",
      message: "Cálculo del costo de envío",
      orderPlaceMessage: "Tu pedido se está procesando...",
      paymentMessage: 'Paga Con Tu Tarjeta..',
      applyCoupen: "Aplicar código de cupón",
      sellingMessage: 'revender el producto?',
      marginMessage: 'haga clic en sí para agregar su margen',
      yes: "Sí",
      close: 'Cerca',
      enterMargin: 'Ingrese la cantidad del margen',
      addMargin: 'Agregar margen',
      marginError1: 'por favor ingrese margen hasta',
      marginError2: 'de la cantidad total',
      couponApply: 'Cupón aplicado',
      total: 'Total',
      valid: 'Válida hasta',
      totalAmount: 'Cantidad total',
      paymentdetails: 'Detalles del pago',
      reviewOrder: 'Revise su orden',
      online: 'En línea',
      deliveryServices: "Seleccionar servicios de entrega",
      freeDeliveryMessage: "en pedidos anteriores",
      freeDeliveryNotMessage: "no elegible",
      freeDelivery: 'Entrega gratis',
      serviceSelectMessage: "Seleccione el servicio de entrega",
      marginError:'El monto del margen debe ser menor o igual a'
    },
    reasons: {
      returnReasonOne: "Pieza defectuosa.",
      returnReasonTwo: "Producto recibido equivocado.",
      returnReasonThree: "No requerido.",
      cancelReasonOne: "Quiero cancelar por problemas de calidad del producto",
      cancelReasonTwo: "El precio del producto ha bajado",
      cancelReasonThree: "El tiempo de entrega esperado es muy largo.",
      cancelReasonFour: "He comprado el producto en otro lugar",
      cancelReasonFive: "Quiero cambiar la dirección del pedido.",
      cancelReasonSix: "he cambiado de opinión",
      cancelReasonSeven: "Quiero convertir mi pedido a Prepago",
      cancelReasonEight: "quiero cambiar mi numero de telefono",
    },
    wishList: {
      addToCart: "Añadir al Carrito",
      product: "Productos",
      offerPrice: "Precio de Oferta",
      price: "Precio",
      yourWishlist: "Mi lista de Deseos",
      addOnlyTenProducts: "Solo puedes añadir diez Productos",
    },
    attributeModal: {
      price: "Precio",
      chooseOptions: "Elegir Opciones",
      noProducts: "No hay artículos en la lista de Deseos",
      shopNow: "Compre Ahora",
      addToCart: "Añadir al Carrito",
      alert: "Alerta",
      ok: "Ok",
    },
    chats: {
      chats: "charlar",
      noChats: "No tienes ningun Chat",
      noThanks: "No Gracias",
      upgradeNow: "Actualizar Ahora",
      makeAOffer: "Haz una Oferta",
      acceptOffer: "Aceptar la Oferta",
      am: "Mañana",
      pm: "Tarde",
      addToCart: "Añadir al Carrito",
    },
    login: {
      loginWithPhone: "Entrar con Telefono",
      loginWithEmail: "Iniciar sesión con correo Electrónico",
      login: "Iniciar Sesión",
      newUser: "Nuevo Usuario",
      sendOtp: "Enviar OTP",
      or: "Ou",
      signUp: "Formulario de registro",
      signUpButton: "Inscribirse",
      resendOtp: "Reenviar la OTP",
      enterPhone: "Ingrese su número Telefónico",
      dialingCode: "Codigo para Marcar",
      alreadyAccount: "¿Ya tienes una Cuenta?",
      verify: "Controlar",
      signUp: "Inscribirse",
      enterTheOtp: "Por favor ingrese la OTP",
      forgotPassword:"Has olvidado tu contraseña",
      password: "Digite a Senha",
      emailPhone: "Correo electrónico/Número de móvil",
      email: "Ingrese correo Electrónico",
      name: "Ingresa tu nombre completo",
      codeError: 'seleccione el código de marcación del país',
      phoneError: 'por favor ingrese un número de móvil válido',
      termCondition: "Acepto todos los términos y condiciones.",
      termsError:"por favor acepte los términos y condiciones.",
      nameError:"el campo de nombre es obligatorio",
      forgotMessage:"Ingrese su teléfono registrado y le enviaremos una OTP",
    },
    pageNotFound: {
      oops: "página no encontrada",
      unAvailable:
        "    Es posible que la página a la que intenta acceder haya sido eliminada, modificada o no esté disponible.",
      goToHome: "ir a la página de inicio",
      tryExploring: "intenta explorar",
    },
    vendorDetails: {
      myFollowing: "Mis Seguidores",
      vendorDetails: "Detalles del Vendedor",
    },
    profileMenu: {
      profile: "Perfil",
      myBankDetails: "Mis datos bancarios",
      myEarnings: "Mis Ganancias",
      myReviews: "Mis reseñas",
      deleteAccount: "Borrar cuenta",
      myChats: "Mis Chats",
      myOrders: "Mis Ordenes",
      myCart: "Mi Carrito",
      manageAddress: "Administrar dirección",
      myFollowing: "Mis Seguidores",
      logout: "Salir",
    },
    deletePopup: {
      confirmToDelete: "Confirmar",
      delAccount: "Borrar cuenta",
      delMsg: "Al eliminar su cuenta, la siguiente información se eliminará y no se podrá deshacer",
      msgOne: "Detalles personales",
      msgTwo: "Perfil del vendedor (si está disponible)",
      msgThree: "Documentos personales",
      msgFour: "direcciones",
      msgFive: "Detalles del banco",
      delConfirm: "Haga clic en confirmar para eliminar su cuenta ahora.",
    },
    reviewTr: {
      title: "Mi reseña",
      titleEarn: "Mis Ganancias",
      viewRec: "Ver recibo",
      TransactionId: "ID de transacción",
    },
    bankDetails: {
      title: "Detalles del banco",
      bankName: "Nombre del banco",
      accountNumber: "Número de cuenta",
      confirmAccountNumber: "Confirmar número de cuenta",
      swiftCode: "CÓDIGO SWIFT / Número DE RUTA",
      accountHolderName: "nombre del titular de la cuenta",
      bankCode: "Codigo bancario",
      branchCode: "Código de sucursal",
      accountType: "Tipo de cuenta",
      submit: "Enviar",
      bankNameError: "El nombre del banco es obligatorio.",
      NameError: "Se requiere el nombre del titular de la cuenta.",
      AccountError: "El número de cuenta es obligatorio.",
      accountError: "El número de cuenta debe ser más de 6.",
      confirmAccountError: "Se requiere confirmar el número de cuenta.",
      ifscError: "Se requiere el código Swift/Número de ruta.",
      bankCodeError: "Se requiere código bancario.",
      branchCodeError: "Se requiere código de sucursal.",
      accountTypeError: "El tipo de cuenta es obligatorio.",
    },
    chatPopUp: {
      actualPrice: "Precio Actual",
      makeAOffer: "Haz una Oferta",
      am: "Mañana",
      pm: "Tarde",
      acceptOffer: "Aceptar la Oferta",
      send: "Enviar",
      addToCart: "Añadir al Carrito",
    },
    sortForm: {
      newArrivals: "Los recién Llegados",
      mostPopular: "Mas Popular",
      priceLowToHigh: "Precios (de barato a caro)",
      priceHighToLow: "Precio (de mayor a menor)",
      ratingHighToLow: "Calificaciones (de Mayor a Menor)",
      sort: 'Clasificar'
    },
    myFollowing: {
      ratings: "Calificaciones",
      followings: "Seguidores",
      products: "Productos",
      allProducts: "Todos los Productos",
      showing: "Demostración",
      follow: "Seguir",
      following: "Siguiendo",
    },
    home: {
      recentProducts: "recién llegados",
      messageText: "Escribe tu mensaje",
      button: { viewAll: "Ver Todo" },
      categories: { title: "Categorías" },
      brand: { title: "Comprar por Marcas" },
      fCollection: { title: "Productos Destacados" },
      flashSale: { title: "Venta Express", left: "Izquierda" },
      trProducts: { title: "Productos de Tendencia" },
      topProducts: { title: "Productos Principales" },
      nearbySellers: { title: "Vendedores Cercanos" },
      providingFeatures: {
        i: {
          main: "Envío y Devolución Gratis",
          secondary: "Envío gratis en todos los Pedidos ",
        },
        ii: {
          main: "Garantía de Dinero",
          secondary: "Garantía de devolución de dinero de 30 Días",
        },
        iii: {
          main: "Soporte en Línea",
          secondary: "Tenemos soporte en Línea 24/7",
        },
        iv: {
          main: "Pagos Seguros",
          secondary: "Todos los pagos son Seguros y Confiables",
        },
      },
    },
  },
};
export default sp;

