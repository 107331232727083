import { Card } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiService from "../services/apiService";
import {Typography} from "@material-ui/core";

export default function MyEarnings() {
  const { t } = useTranslation();
  const [myEarnings, setmyEarnings] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    ApiService.getCustomerearning().then(response => {
      setmyEarnings(response.data.data?.earnings);
    })
  }, [])
  return (
    <React.Fragment>
      <div>
        <div className="mb-4">
          <Typography
            variant={"h5"}
            className="py-5 text-center uppercase dark:text-fontWhite dark:bg-lightDarkBackground bg-gray-200 border-b-2"
          >
            {t("reviewTr.titleEarn")}
          </Typography>
        </div>
        <div className="w-full my-6 pl-8 ">
          <div className="grid md:grid-cols-2  grid-cols-1 gap-5">
            {
              myEarnings?.map((item) => {
                return (
                  <Card
                    className="flex lg:my-4 w-11/12 dark:bg-lightDarkBackground hover:bg-gray-100 shadow-lg rounded-2xl m-auto relative py-1 px-4 lg:py-4 box-content gap-4 border-2 border-gray-500 bg-gray-100">
                    <div className="flex flex-col w-1/4 h-full">
                      <div>
                        <label className="font-bold text-fontDark text-base">R$ {item?.amount}</label>
                      </div>
                      <div className="flex flex-col justify-between h-full">
                        <div className="flex flex-col">
                          <label className="text-fontDark text-base">{t("reviewTr.TransactionId")}:</label>
                          <label className="font-bold text-fontDark text-base">{item?.transaction_id}</label>
                        </div>
                        <div className="flex items-end text-base">
                          {moment(item?.created_at).format("DD-MM-yyyy")}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end w-3/4">
                      <div className="flex flex-col">
                        <div>
                          <img src={item?.ImageSrc} width='100' className="h-32" alt="demo" />
                        </div>
                        <div className="text-center">
                          <a href={item?.ImageSrc} download className="text-fontDark font-bold text-base mt-3 cursor-pointer">{t("reviewTr.viewRec")}</a>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}