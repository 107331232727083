import { Box, styled, Typography } from "@material-ui/core";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Slider from "react-slick";
import AppConfig from "../../appConfig";
import ProductCard from "../../core-components/ProductCard";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";
import Utils from "../../services/Utils";

export default function FlashSale({ flashSaleData }) {
  console.log(flashSaleData);
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";

    return (
      <div className=" relative z-10">
        {props.type === "next" ? (
          <ArrowRight
            className="absolute border-2 w-10 h-10 bg-black text-primary shadow-flashblack border-primary rounded-full top-32 right-0 scale-95 sm:scale-100 sm:top-36 sm:right-10"
            onClick={props.onClick}
          />
        ) : (
          <ArrowLeft
            onClick={props.onClick}
            className="absolute border-2 w-10 h-10 bg-black text-primary shadow-flashblack border-primary cursor-pointer rounded-full scale-95 sm:scale-100 top-32 left-0 sm:top-36 sm:left-10"
          />
        )}
      </div>
    );
  }
  const { t } = useTranslation();
  const history = useHistory();

  const settings = {
    dots: false,
    slidesToShow: 5,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
    autoplay: true,
    arrows: true,
    infinite: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 280, settings: { slidesToShow: 1 } },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: flashSaleData?.length <= 2 ? flashSaleData?.length : 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: flashSaleData?.length <= 4 ? flashSaleData?.length : 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: flashSaleData?.length <= 5 ? flashSaleData?.length : 5,
        },
      },
    ],
  };

  const cartCount = useSelector(({ allCategories }) => allCategories.cartCount);
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );
  const [loading, setLoading] = useState(true);
  const { innerWidth } = window;
  useEffect(() => {
    if (flashSaleData !== undefined) {
      setLoading(false);
    }
  }, [flashSaleData]);

  const addToWishlist = (data) => {
    // below checking if user is logged in or not
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      _form.append("product_id", data.id);

      // add item to wishlist api
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      // if user is not logged push it to login page
      history.push(AppConfig.routes.login);
    }
  };

  const session_id = Utils.generateGUID();
  const newSessionId = sessionStorage.getItem("session_id");
  const user_id = sessionStorage.getItem("user_id");
  const [addCart, setAddCart] = useState(false);

  // add product to cart function

  function diff_hours(startDate, endDate, startDateForTimeSpent, today) {
    // just take the help of console.log to understand the below logic
    var totalDuration = (endDate.getTime() - startDate.getTime()) / 1000;

    totalDuration /= 60 * 60;
    const DurationHours = Math.abs(Math.round(totalDuration));
    const pointDataDuration = totalDuration - Math.round(totalDuration);
    const DurationMinutes = parseInt(pointDataDuration * 60);
    const pointSecondDuration = pointDataDuration * 60 - DurationMinutes;
    const DurartionSeconds = parseInt(pointSecondDuration * 60);
    var timeSpent = (today.getTime() - startDateForTimeSpent.getTime()) / 1000;

    timeSpent /= 60 * 60;
    const HoursSpent = Math.abs(Math.round(timeSpent));
    const pointDataSpent = timeSpent - Math.round(timeSpent);
    const MinutesSpent = parseInt(pointDataSpent * 60);
    const pointSecondSpent = pointDataSpent * 60 - MinutesSpent;
    const SecondsSpent = parseInt(pointSecondSpent * 60);

    const durationTimeInSeconds =
      DurationHours * 3600 + DurationMinutes * 60 + DurartionSeconds;
    const SpentTimeInseconds =
      HoursSpent * 3600 + MinutesSpent * 60 + SecondsSpent;

    const timeLeft = durationTimeInSeconds - SpentTimeInseconds;
    const hoursLeft = parseInt(timeLeft / 3600);
    const data = timeLeft / 3600 - parseInt(timeLeft / 3600);
    const takingMin8utes = data * 60;
    const minutesLeft = parseInt(takingMin8utes);
    const takingSeconds = takingMin8utes - parseInt(takingMin8utes);
    const secondsLeft = parseInt(takingSeconds * 60);
    const finalTimeObject = {
      hoursLeft,
      secondsLeft,
      minutesLeft,
    };
    return finalTimeObject;
  }
  const renderer = (start, end) => {
    if (flashSaleData !== undefined) {
      const dt1 = new Date(start);
      const dt2 = new Date(end);
      var today = new Date();
      // calling diff_hours function to get the remaining time for sale
      const finalTimeObject = diff_hours(dt1, dt2, dt1, today);

      return (
        <RenderTimer className="text-base lg:text-2xl">
          {finalTimeObject.hoursLeft} : {finalTimeObject.minutesLeft} :{" "}
          {finalTimeObject.secondsLeft} {t("home.flashSale.left")}
        </RenderTimer>
      );
    } else {
      return null;
    }
  };
  const RenderTimer = styled(Typography)(({ theme }) => ({
    fontSize: "30px",
    color: AppConfig.color.secondary,
    fontWeight: "bolder",
    [theme.breakpoints.down("sm")]: {},
  }));
  const Timer = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  `;
  return (
    <React.Fragment>
      <div className="">
        {flashSaleData !== undefined && flashSaleData?.flash_sale?.length > 0 && (
          <div className="flex lg:flex-row gap-4 flex-col mx-6 my-2 sm:mx-10 items-center justify-between">
            <span className="animate-bounce inline-block py-4  bg-teal-500 text-teal-100  font-extrabold text-6xl  sm:text-2xl uppercase bg-transparent drop-shadow-2xl  text-red-500 "> {flashSaleData?.title}</span>
            {/* <span
              className="font-bold text-6xl  sm:text-2xl uppercase bg-transparent drop-shadow-2xl  text-red-500 "
            >
              {t("home.flashSale.title")}
            </span> */}
            <div className="bg-fontDark p-2 py-3 sm:py-2 px-1 lg:p-4 w-5/12 sm:w-3/12 lg:w-1/5 lg:px-2 flex justify-center items-center text-primary rounded-xl sm:rounded-2xl">
              <Timer className="text-sm ">
                <Countdown date={Date.now() + 5.04e7} renderer={() => renderer(flashSaleData?.start_date, flashSaleData?.end_date)} />
              </Timer>
            </div>
          </div>
        )}

        {/* if length of flashSaleData is greater than only we will show slider component else we will show grid layout Because when less than 5 products the slider starts repeating products */}
        {flashSaleData.flash_sale?.length > 5 ? (
          <div
            className={`container`}
            style={{
              width:
                innerWidth === 820
                  ? "720px"
                  : innerWidth < 900
                    ? innerWidth
                    : "100%",
              padding: innerWidth < 900 && innerWidth > 540 && "0px",
            }}
          >
            <Slider {...settings} className=" flex">
              {loading
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, key) => (
                  <Skeleton
                    height={300}
                    width={200}
                    className="mx-10"
                    key={key}
                  />
                ))
                : flashSaleData?.flash_sale.map((image, key) => {
                  return (
                    <div className="px-2 lg:px-0">
                      <ProductCard
                        image={image?.product?.length > 0 ? image.product[0] : []}
                        shop={true}
                        type="Flash Sale"
                        typeForUrl="featured-product"
                        addToWishlist={addToWishlist}
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        ) : (
          <div className="grid lg:grid-cols-5 sm:grid-cols-4 sm:gap-4 grid-cols-2  mt-4 gap-3 lg:gap-8 mx-2 sm:mx-10">
            {/* below condition reason : Because initially featuredProductData will be undefined untill data comes from home component */}
            {flashSaleData == undefined
              ? [1, 2, 3, 4, 5].map((ele, key) => (
                <Skeleton height={300} className="" key={key} />
              ))
              : flashSaleData?.flash_sale.map((image, key) => {
                return (
                  <React.Fragment key={key}>
                    <ProductCard
                      image={image?.product[0]}
                      type="Featured Product"
                      typeForUrl="featured-product"
                      addToWishlist={addToWishlist}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
