const pt = {
  translation: {
    alertComponent: {
      otherSellerProductsinCart:
        "Seu carrinho contém produtos de outros vendedores, remova o Carrinho",
      alert: "Alerta",
      removeOtherAddThis: "Remover outros itens e adicionar Este",
      cancel: "Cancelar",
    },
    productcard: {
      addtowishlist: "Adicionar a lista de desejos",
      removefromwishlist: "Remover da lista de desejos",
    },
    header: {
      search: "Pesquisar",
      language: "Idioma",
      categories: "Categorias",
      home: "Início",
      alert: "Alerta",
      stockNotAvailable: "Estoque não Disponível",
      ok: "OK",
      profile: "perfil",
      myChats: "Meus bate-papos",
      myFollowing: "Meus Seguidores",
      logout: "Sair",
      myCart: "Meu Carrinho",
      myOrders: "Minhas Ordens",
      wishlist: "",
      notifications: "Notifications",
      cart: "Carrinho",
      login: "Conecte-se",
      account: "Account",
    },
    paymentForm: {
      paymentOptions: "Cartão de débito, cartão de crédito, Banca Líquida, PIX",
      cashOnDelivery: "Dinheiro na Entrega",
    },
    checkOutForm: {
      payNow: "Formulário de Checkout",
    },
    addressForm: {
      city: "Cidade",
      document: 'Documento da Empresa',
      close: 'Perto',
      state: "Estado",
      locality: "Localidade",
      shippingAddress: "Endereço de entrega",
      addAddress: "Adicionar novo endereço",
      saveAddress: 'Salvar endereço',
      editAddress: 'Editar Endereço',
      address: "Endereço",
      name: "Nome",
      searchplace: 'Pesquisar lugares...',
      mobile: "Celular",
      landmark: "Ponto de referência",
      pinCode: "Código PIN",
      addressType: "tipo de endereço",
      home: "Início",
      office: "Escritório",
      country: "Nada Encontrado",
      NearByLocation: "Perto do local",
      statecode: "Código do Estado",
      countrycode: "Código do país",
      confirmation: "Confirmação",
      confirmationMessage: "Como o código do país do seu endereço comercial foi alterado, a moeda padrão e os produtos adicionados anteriormente serão definidos como inativos. Confirme ou cancele a solicitação."
    },
    noFound: {},
    order: {
      shippingAddress: "Endereço de Entrega",
      mobile: "Celular",
      deliveryAt: "Entrega Em",
      orderDetails: "Detalhes do Pedido",
      qty: "Quantidade",
      status: "Pedido",
      paymentMethod: "Forma de Pagamento",
      orderedOn: "Pedido Em",
      customerDetails: "Detalhes do Cliente",
      trackOrder: "Acompanhar Pedido",
      backToOrder: "Voltar ao Pedido",
      orderAmount: "Valor do pedido",
      orderId: 'Código do pedido',
      more: 'Mais',
      discountAmount: 'Valor do desconto do pedido',
      rating: 'Avaliar',
    },

    cancelOrder: {
      reasonForCancel: "Razão para Cancelamento",
      reasonForReturn: "Razão para retornar",
      orderCancellation: "Cancelamento do Pedido",
      orderCancel: "Cancelar Pedido",
      returnCancel: "Devolução de pedido",
      cancelHeading: "Cancelar solicitação de devolução?",
      continue: "Continuar",
      itemInfo: "Informação sobre o Item",
      returnRequestCancel: "Sim, quero cancelar",
      qty: "Qty",
      deliveryFee: "Taxa de Entrega",
      orderDiscount: "Desconto do Pedido",
      productAmount: "Quantidade do Produto",
      price: "Preço",
      comments: "Comentários",
      confirmMsg: "Tem certeza de que deseja cancelar a solicitação de devolução deste pedido?",
      feedback: "Dê seu feedback",
      feedbackMsg: "Como você avalia este produto?",
      submitRating: "Enviar",
      feedbackComment: "Adicione sua avaliação",
    },
    footer: {
      quickShop: "Compra Rápida",
      informations: "Informação",
      customerService: "Serviço ao Cliente",
      contactUs: "Fale Conosco",
      supportChat: "Bate-papo de suporte",
    },
    mobileFooter: {
      home: "Início",
      category: "Categoria",
      new: "Novo",
      me: "Eu",
      bag: "Sacola",
      myOrders: "Minhas ordens",
      categoriesDrawer: {
        categories: "Categories",
      },
    },
    common: { addToCart: "Adicionar ao Carrinho" },
    card: {
      button: { addToCart: "Adicionar ao Carrinho", productDetail: "Ver Detalhes" },
    },
    shop: {
      home: "Início",
      mobileDisplay: "Display do Celular",
      categoryHeading: "Categorias",
      showingHeading: "Mostrando",
      size: "Tamanho",
      price: "Preço",
      brand: "Marca",
      discount: "Desconto",
      andMore: "E Mais",
      color: "Cor",
      filter: "Filtrar",
      allFilter: "Todos os Filtros",
      applyAllFilters: "Aplicar todos os Filtros",
      clear: "Limpar",
      more: "Mais",
      productFilters: "Filtros de Produtos",
      noFilters: "Sem Filtros",
      showingProducts: "Showing Products",
      products: "Products",
      of: "of",
      noProductFound: "Nenhum produto encontrado",
      noProducts: "Nenhum produto",
    },
    productDetails: {
      all: "Todos",
      one: "Um",
      two: "Dois",
      three: "Três",
      four: "Quatro",
      five: "Cinco",
      soldBy: "Vendido Por",
      outOfStock: "Esgotado",
      addToWishList: "Adicionar a lista de Desejos",
      viewShop: "Ver Loja",
      chatWithUs: "Fale Conosco",
      ratings: "Avaliações",
      followers: "Seguidores",
      products: "Produtos",
      productDetails: "Detalhes do produto",
      productRatingAndReviews: "Avaliação & comentários do Produto",
      similarProducts: "Produtos Similares",
      reviews: "Avaliações",
      removeFromWishlist: "Remover da lista de Desejos",
      share: "compartilhar",
      off: "DESLIGADA",
      youSave: "Você salva",
    },
    myOrders: {
      filters: "Filtrar",
      orderStatus: "Detalhes do Pedido",
      onTheWay: "A Caminho",
      delivered: "Entregue",
      cancelled: "Cancelar",
      returned: "Retornou",
      orderTime: "Hora do Pedido",
      last30Days: "Últimos 30 Dias",
      processing: "Processing",
      older: "Mais velho",
      soldBy: "Sold ",
      viewOrder: "Ver pedido",
      cancelOrder: "Cancelar Pedido",
      returnOrder: "Pedido de devolução",
      returnCancelOrder: "Cancelar pedido de devolução",
      address: "Endereço",
      paymentDetail: "Detalhes do Pagamento",
      COd: "Código",
      transactionId: "ID da Transação",
      myOrders: "Minhas Ordens",
      filter: "Filtrar",
      openMainMenu: "Abrir menu Principal",
      orderedOn: "Pedido Em",
      orderAmount: "Valor do Pedido",
      qty: "Quantidade",
    },
    myProfile: {
      profile: "perfil",
      alert: "Alerta",
      ok: "Ok",
      updateProfile: "Atualizar",
      firstName: "Primeiro Nome",
      city: "Cidade",
      email: "Endereço de Email",
      phone: "Número de Telefone",
      state: "Estado",
      address: "Endereço",
      dialing_code: "Selecione o código do país",
      contactMessage: "Entre em contato com o suporte para alterar o número de telefone",
      personalInfo: "Informação pessoal",
      accountInfo: "Informação da conta",
      alert: "Verificação OTP",
      message: "OTP foi enviado para o seu número de celular",
      emailMessage: "OTP foi enviado para o seu e-mail",
      otpError: "campo otp é obrigatório",
      image: "tamanho recomendado 500x500px",
      phoneError: "número de telefone já existe",
      emailError: "e-mail já existe",
      changePassword: "Mudar senha",
      password: "Senha Antiga",
      newPass: "Nova Senha",
      confirmPass: "Confirme a Senha",
      confirmPassError: "Confirmar senha não corresponde",
    },
    providingFeatures: {
      features: {
        i: {
          heading: "Pagamento Seguro",
          subHeading: "Pague com os métodos de pagamento mais seguros do Mundo",
        },
        ii: {
          heading: "Confiança",
          subHeading: "A proteção cobre sua compra e dados Pessoais",
        },
        iii: {
          heading: "Entrega Mundial",
          subHeading: "Frete grátis e rápido para mais de 200 Países e Regiões.",
        },
        iv: {
          heading: "Linha Direta",
          subHeading:
            "Fale com a linha de ajuda para sua pergunta Em",
        },
      },
    },
    cartDetails: {
      product: "Produtos",
      quantity: "Quantidade",
      total: "Total",
      price: "Preço",
      shopNow: "Compre Agora",
      noItems: "Não há itens no Carrinho",
      checkout: "Confira",
      subtotal: "Subtotal",
      discount: "Desconto",
      shipping: "Envio",
      yourCart: "Seu Carrinho",
      addOnlyTenProducts: "Você só pode adicionar dez Produtos",
      action: "Ação",
      ok: "ok",
      nocartitem: 'Nenhum item no carrinho',
      cartEmpty: 'Seu carrinho está vazio.',
      wishlistEmpty: 'Sua Lista de Desejos está vazia.',
    },

    checkout: {
      alert: "Alerta",
      ok: "Ok",
      checkout: "Check out",
      refresh: "Atualizar",
      orderProcessing: "O pagamento do seu pedido está sendo processado",
      thankYouForOrder: "Obrigado pelo Pedido",
      orderNumberIs: "O número do pedido É",
      transactionIdIs: "ID da transação É",
      continueShopping: "Continue Comprando",
      back: "De Volta",
      next: "Próximo",
      placeOrder: "Faça a Encomenda",
      payNow: "Pague Agora",
      shippingAddress: "Endereço de entrega",
      paymentMethod: 'Forma de pagamento',
      orderSummary: "Resumo do pedido",
    },
    review: {
      to: "para",
      days: 'Dias',
      minimumOrderValue: "O valor mínimo do pedido É",
      maimumDiscountIS: "O desconto máximo É",
      orderSummary: "Resumo do Pedido",
      productName: "Nome do Produto",
      originalPrice: "Preço Original",
      offerPrice: "Preço de Oferta",
      orderAmount: "Order amount",
      offerDiscount: "Desconto do produto",
      subTotal: "Subtotal",
      Qty: "Quantidade",
      productAmount: "Preço do produto",
      deliveryFee: "Taxa de Entrega",
      marginPrice: "Preço de Margem",
      orderDiscount: "Cupom de Desconto",
      orderTotal: "Total de Pedidos",
      chooseCoupon: "Escolher Cupom",
      remove: "Remove",
      applyCoupon: "Aplicar Cupom",
      youSave: "Você salva",
      youSaveAdditional: "Você economizou Adicional",
      shipping: "Envio",
      paymentMethod: "Forma de Pagamento",
      errorMessage: 'Por favor, selecione um endereço válido.',
      margin: "Insira o valor da margem",
      message: "Calculando o custo de envio",
      orderPlaceMessage: "Seu pedido está sendo processado...",
      paymentMessage: 'Pague com seu cartão..',
      applyCoupen: "Aplicar código de cupom",
      sellingMessage: 'Revenda do produto?',
      marginMessage: 'clique em sim para adicionar sua margem',
      yes: "Sim",
      close: 'Perto',
      enterMargin: 'Insira o valor da margem',
      addMargin: 'Adicionar margem',
      marginError1: 'por favor insira a margem até',
      marginError2: 'do montante total',
      couponApply: 'Cupom Aplicado',
      total: 'Total',
      valid: 'Válida até',
      totalAmount: 'Valor total',
      paymentdetails: 'Detalhes do pagamento',
      reviewOrder: 'Revise seu pedido',
      online: 'Conectadas',
      deliveryServices: "Selecionar serviços de entrega",
      freeDeliveryMessage: "em pedidos acima",
      freeDeliveryNotMessage: "não elegível",
      freeDelivery: 'Entrega grátis',
      serviceSelectMessage: "Selecione o serviço de entrega",
       marginError:'O valor da margem deve ser menor ou igual a'
    },

    reasons: {
      returnReasonOne: "Peça com defeito.",
      returnReasonTwo: "Produto errado recebido.",
      returnReasonThree: "Não requerido.",
      cancelReasonOne: "Quero cancelar devido a problemas de qualidade do produto",
      cancelReasonTwo: "O preço do produto diminuiu",
      cancelReasonThree: "A previsão de entrega é muito longa",
      cancelReasonFour: "Comprei o produto em outro lugar",
      cancelReasonFive: "Eu quero mudar o endereço para o pedido",
      cancelReasonSix: "Eu mudei de idéia",
      cancelReasonSeven: "Quero converter meu pedido para Pré-pago",
      cancelReasonEight: "quero mudar meu numero de telefone",
    },

    wishList: {
      addToCart: "Adicionar ao Carrinho",
      product: "Produtos",
      offerPrice: "Preço de Oferta",
      price: "Preço",
      yourWishlist: "Você só pode adicionar dez Produtos",
      addOnlyTenProducts: "You can only add ten products",
    },
    attributeModal: {
      price: "Preço",
      chooseOptions: "Escolha as Opções",
      noProducts: "Não há nenhum item na lista de Desejos",
      shopNow: "Compre Agora",
      addToCart: "Adicionar ao Carrinho",
      alert: "Alerta",
      ok: "Ok",
    },
    chats: {
      chats: "Bate-papo",
      noChats: "Você não tem nenhum Bate-papo",
      noThanks: "Não, Obrigado",
      upgradeNow: "Atualize Agora",
      makeAOffer: "Faça uma Oferta",
      acceptOffer: "Aceitar Oferta",
      am: "Manhã",
      pm: "Tarde",
      addToCart: "Adicionar ao Carrinho",
    },
    login: {
      loginWithPhone: "Entrar com Telefone",
      loginWithEmail: "Entrar com E-mail",
      login: "Entrar",
      newUser: "Novo Usuário",
      sendOtp: "Enviar OTP",
      or: "Ou",
      signUp: "Formulário de inscrição",
      signUpButton: "Inscrever-se",
      resendOtp: "Reenviar o OTP",
      enterPhone: "Digite seu número de Telefone",
      dialingCode: "Código de Discagem",
      forgotPassword:"Esqueceu a senha",
      alreadyAccount: "Já tem uma Conta? ? Entrar",
      verify: "Verificar",
      signUp: "Inscrever-se",
      enterTheOtp: "Por favor, insira o OTP",
      password: "Digite a Senha",
      emailPhone: "E-mail/Número de celular",
      email: "Digite o E-mail",
      name: "Escreva seu nome completo",
      codeError: 'selecione o código de discagem do país',
      phoneError: 'por favor insira um número de celular válido',
      termCondition: 'Aceito todos os termos e condições.',
      termsError:"aceite os termos e condições. ",
      nameError:"campo nome é obrigatório",
      forgotMessage:"Por favor, digite seu telefone cadastrado e nós lhe enviaremos um OTP",
    },
    pageNotFound: {
      oops: "    ",
      unAvailable:
        "A página que você está tentando acessar pode ter sido removida, alterada ou não está disponível.",
      goToHome: "vá para a página inicial",
      tryExploring: "tente explorar      ",
    },
    vendorDetails: {
      myFollowing: "Meus Seguidores",
      vendorDetails: "Detalhes do Vendedor",
    },
    profileMenu: {
      profile: "Perfil",
      myBankDetails: "Dados do meu banco",
      myEarnings: "meus ganhos",
      myReviews: "Minhas avaliações",
      deleteAccount: "Deletar conta",
      myChats: "Meus bate-papos",
      myOrders: "Minhas Ordens",
      myCart: "Meu Carrinho",
      manageAddress: "Gerenciar endereço",
      myFollowing: "Meus Seguidores",
      logout: "Sair",
    },
    deletePopup: {
      confirmToDelete: "confirme",
      delAccount: "Deletar conta",
      delMsg: "Ao excluir sua conta, as seguintes informações serão excluídas e não poderão ser desfeitas",
      msgOne: "Detalhes pessoais",
      msgTwo: "Perfil do vendedor (se disponível)",
      msgThree: "Documentos pessoais",
      msgFour: "Endereços",
      msgFive: "Detalhes bancários",
      delConfirm: "Por favor, clique em confirmar para excluir sua conta agora?",
    },
    reviewTr: {
      title: "Minha avaliação",
      titleEarn: "meus ganhos",
      viewRec: "Veja o recibo",
      TransactionId: "ID da transação",
    },
    bankDetails: {
      title: "Detalhes bancários",
      bankName: "Nome do banco",
      accountNumber: "Número da conta",
      confirmAccountNumber: "Confirmar número da conta",
      swiftCode: "CÓDIGO SWIFT / Número de ROTEIRO",
      accountHolderName: "nome do titular da conta",
      bankCode: "Código bancário",
      branchCode: "Código da Agência",
      accountType: "tipo de conta",
      submit: "Enviar",
      bankNameError: "O nome do banco é obrigatório.",
      NameError: "O nome do titular da conta é obrigatório.",
      AccountError: "O número da conta é obrigatório.",
      accountError: "O número da conta deve ser maior que 6.",
      confirmAccountError: "Confirme que o número da conta é obrigatório.",
      ifscError: "O código Swift/Número de roteamento é obrigatório.",
      bankCodeError: "O código do banco é obrigatório.",
      branchCodeError: "O código da filial é obrigatório.",
      accountTypeError: "O tipo de conta é obrigatório.",
    },
    chatPopUp: {
      actualPrice: "Preço Atual",
      makeAOffer: "Faça uma Oferta",
      am: "Manhã",
      pm: "Tarde",
      acceptOffer: "Aceitar Oferta",
      send: "Enviar",
      addToCart: "Adicionar ao Carrinho",
    },
    sortForm: {
      newArrivals: "Novidades",
      mostPopular: "Mais Popular",
      priceLowToHigh: "Preço (barato para caro)",
      priceHighToLow: "Preço (maior para menor)",
      ratingHighToLow: "Avaliações (Maior a Menor)",
      sort: 'Ordenar'
    },
    myFollowing: {
      ratings: "Avaliações",
      followings: "Seguidores",
      products: "Produtos",
      allProducts: "Todos os Produtos",
      showing: "Mostrando",
      follow: "Seguir",
      following: "Seguindo",
    },
    home: {
      recentProducts: "Recém-chegados",
      messageText: "Digite sua mensagem",
      button: { viewAll: "Ver Tudo" },
      categories: { title: "Categorias" },
      brand: { title: "Compre por Marcas" },
      fCollection: { title: "Produtos em Destaque" },
      flashSale: { title: "Venda Rápida", left: "Esquerda" },
      trProducts: { title: "Produtos em Alta" },
      topProducts: { title: "Principais Produtos" },
      nearbySellers: { title: "Vendedores Próximos" },
      providingFeatures: {
        i: {
          main: "Frete e Devolução Grátis",
          secondary: "Frete grátis em todos os pedidos do Brasil",
        },
        ii: {
          main: "Garantia de Dinheiro",
          secondary: "Garantia de devolução do dinheiro em 30 Dias",
        },
        iii: {
          main: "Suporte Online",
          secondary: "Temos Suporte online 24/7 ao Dia",
        },
        iv: {
          main: "Pagamentos Seguros",
          secondary: "Todos os pagamentos são Seguros e Confiáveis",
        },
      },
    },
  },
};
export default pt;