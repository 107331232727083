import React from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
export default function OfferZone(props) {
  const history = useHistory();
  var urlString;
  const { innerWidth } = window;
  return (
    <React.Fragment>

      {typeof props.offerzone === 'undefined' || props.offerzone.length === 0 ?
        <div className='pl-8 my-6 text-center grid sm:grid-cols-3 grid-cols-1'>
          {[1, 2, 3].map((ele, key) => (<Skeleton height={150} width={innerWidth < 1000 ? innerWidth - 50 : 400} key={key} />))}
        </div>
        :
        <div className="grid sm:grid-cols-3 grid-cols-1  cursor-pointer lg:mx-12 ">
          {props.offerzone.map((item, index) => (
            <div
              onClick={() => {
                urlString = `start_discount=${item.start_discount}-end_discount=${item.end_discount}`;
                history.push({
                  pathname: `/shop/offer/${item.slug}/${urlString}`,
                  state: {
                    id: null,
                    type: "offer",
                    offerData: item,
                  },
                });
              }}
              key={index}
              className="text-center border-r-2 bg-background dark:bg-lightDarkBackground p-4 my-2 shadow-2xl"
            >
              <div className="text-4xl fw-bold">
                <span className="dark:text-fontWhite text-muted text-fontDark">   {item.title.slice(0, 8)} </span>
              </div>

              <div className="text-md tracking-widest mt-1">
                <span className="dark:text-fontWhite text-muted text-fontDark text-md" > {item.title.slice(8, item.title.length)} </span>
              </div>
            </div>

          ))}
        </div>
      }
    </React.Fragment>
  );
}
