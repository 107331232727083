import {
  Button,
  Card,
  createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AppConfig from "../../appConfig";
import ApiService from "../../services/apiService";

import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { useTranslation } from "react-i18next";
import TextFieldFormsy from "../../core-components/TextFieldFormsy";
const useStlyes = makeStyles((theme) => ({
  root: {
    // height: 300,
    flexGrow: 1,
    // minWidth: 300,
    transform: "translateZ(0)",

    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },

  modal: {
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
  },
  button: {
    // backgroundImage: 'linear-gradient(to right, #E55D87 0%, #5FC3E4  51%, #E55D87  100%)',
    background:
      "linear-gradient(to bottom,rgba(255,255,255,0) 0%, #597593 100%)",

    textAlign: "center",
    textTransform: "uppercase",
    transition: "0.5s",
    color: AppConfig.color.button,
    borderRadius: "10px",
    display: "block",
    boxShadow: "0 0 5px 0 #9a7b4d inset",
    "&:hover": {
      background: "#597593",
    },
  },
  paper: {
    // border: '2px solid #000',
    height: "auto",
    borderRadius: "20px",
    backgroundColor: "#484848",
    boxShadow: theme.shadows[5],

    padding: theme.spacing(2, 4, 3),
  },
  MenuCard: {
    width: "30vw",
    height: "60vh",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
    },
  },
  dialogPaper: {
    marginTop: "35vh",
    marginLeft: "65vw",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0vw",
      marginTop: "4vh",
    },
  },
  productNameinMenu: {
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  cardContent: {
    height: "85%",
  },
}));

const creatingStyle = makeStyles(() =>
  createStyles({
    list: {
      padding: "0",
    },
  })
);




const SupportChat = ({ openChatModel, setOpenChatModel }) => {


  const user_id = sessionStorage.getItem('user_id')
  const [msg, setMsg] = useState('')
  const classes = useStyles()
  const creatingStyleforMenu = creatingStyle();
  const { t } = useTranslation()
  const [sessionId, setSessionId] = useState()
  const [renderChatMenu, setRenderChatMenu] = useState(1);
  const messageEndRef = useRef(null)
  const [chatData, setChatData] = useState([

  ])
  const setChangeMessage = (e) => {
    setMsg(e.target.value);
  };
  const sendMessageOnEnter = (e, offer) => {
    if (e.code == "Enter") {

      if (msg == "") {
      } else {
        const form = new FormData();



        form.append("content", msg);
        form.append('to_user', '1')
        // Below function is for sending message to the Vendor

        ApiService.sendChatToAdmin(sessionId, form).then((res) => {
          if (res.code == 200) {
            setMsg("");
            setRenderChatMenu(renderChatMenu + 1);
          }
        });
      }
    }

  };
  const sendMessage = () => {

    if (msg == "") {
    } else {

      const user_id = sessionStorage.getItem("user_id");

      const form = new FormData();
      form.append("content", msg);

      form.append("to_user", '1');

      // Below function is for sending message to the admin
      ApiService.sendChatToAdmin(sessionId, form).then((res) => {
        if (res.code == 200) {
          setMsg("");
          setRenderChatMenu(renderChatMenu + 1);
        }
      });
    }
  };

  // function to get product chat
  const getChat = () => {
    ApiService.getAdminChat(sessionId).then((res) => {
      console.log("this is respone from admin chats", res)
      setChatData(res.data.data)
    })
    // });
  };

  useEffect(() => {
    const form = new FormData()
    form.append('friend_id', '1')
    ApiService.createAdminSession(form).then((res) => {
      console.log("response from admin seesion mcrratw", res)
      if (res.data.id !== null && res.data.id !== 'null') {
        setSessionId(res.data.data.id)
        messListener(res.data.data.id)
        ApiService.getAdminChat(res.data.data.id).then((res) => {
          console.log("this is respone from admin chats", res)
          setChatData(res.data.data)

        })
      }
    })
  }, [])

  useEffect(() => {
    // .MuiInputBase-inputMultiline
    setTimeout(() => {
      const placeholder = document.querySelectorAll('.MuiInputBase-inputMultiline')


      const placeholderArray = [...placeholder]
      console.log("this is dropdown ", placeholderArray)
      placeholderArray.forEach((ele) => {
        // ele.classList.add('dark:bg-lightDarkBackground')
        ele.classList.add('dark:text-fontWhite')
      })
    }, 0);

    // to scroll  to bottom of the div to show user latest message
    messageEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  }, [chatData]);
  // close chat popup
  const handlechatClose = () => {
    setOpenChatModel(false);
    setMsg('')
  };
  // the div which has updateMenu have refernce will get re render whenever the value of renderChatMenu changes
  const updateMenu = useMemo(
    function MultiCount() {
      if (renderChatMenu == 1) {
      } else {
        openChatModel && getChat();
      }
    },
    [renderChatMenu]
  );
  const messListener = (id) => {
    // below creating new Pusher object and passing our app configuration to it
    let PusherClient = new Pusher("87909e984abafa89a22c", {
      cluster: "ap2",
      wsHost: "nily.com.br",
      wsPort: "6001",
      wssHost: "nily.com.br",
      wssPort: "6001",
      enabledTransports: ["ws", "wss"],
      forceTLS: false,
    });
    // now we will use PusherClient to subscribe channel
    // now we will productChat channel to get message from this channel
    const channel = PusherClient.subscribe("Chat");

    // for listening to any channel we have to create Echo object

    const echo = new Echo({
      broadcaster: "pusher",
      client: PusherClient,
    });

    // now we will echo to listen to the channel
    const data1 = echo
      // we will listen to productChat event and pass it our product chat is which in the terms of pusher is a room id which is unique id
      .channel(`PrivateChatEvent.${id}`)
      .listen("PrivateChatEvent", (ev) => {
        // compiler will comes inisde this function only when a message is recieved from other person
        setChatData((chatData) => [...chatData, ev.data]);
      });
    echo
      .channel(`PrivateChatEndEvent.${id}`)
      .listen("PrivateChatEndEvent", async (e) => { });
    console.log("staryted listefs on chaneel", data1)
  };


  return (
    <Dialog
      className=""
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={handlechatClose}
      open={openChatModel}
    >
      <DialogTitle className="p-0">

        <div className="flex p-4 dark:bg-lightDarkBackground bg-primary justify-between">
          <Typography className="text-fontWhite">
            Admin
          </Typography>
          <div>
            <CloseIcon className="text-fontWhite" onClick={() => {
              setMsg('')
              setOpenChatModel(false)
            }
            } />
          </div>
        </div>
      </DialogTitle>
      <DialogContent className="p-0">
        <div className="-mt-2 relative w-full h-96 dark:bg-darkBackground p-2">

          {updateMenu}

          {/* Below is chat div  */}
          <div className=" overflow-y-auto w-full h-2/3 mt-2 p-2">
            {
              chatData.length > 0 &&
              chatData.map((ele, key) => (
                <>

                  {key == 0 && <Typography className="m-auto text-center shadow-md w-fit  dark:bg-darkBackground dark:text-login self-center mt-2.5 p-2">

                    {key == 0 ? ele.createdAt.slice(0, 10) : ele.createdAt.slice(0, 10) !== chatData[key - 1].createdAt.slice(0, 10) ? ele.createdAt.slice(0, 10) : ""}


                  </Typography>}
                  <Card className={`w-fit dark:bg-lightDarkBackground ml-${Number(ele.user._id) == Number(user_id) ? 'auto' : '2'}  p-2  mt-2 mb-6 h-auto max-w-70p`}>
                    <h1 className=" dark:text-fontWhite text-sm">{ele.text}</h1>
                    <p className="dark:text-fontWhite">{ele.createdAt.slice(11, 20)}</p>
                  </Card>
                </>
              ))
            }
          </div>
          <Card ref={messageEndRef}></Card>
        </div>
      </DialogContent>
      <DialogActions className="p-0">
        <div className="absolute justify-between w-full flex  bottom-0">
          {/* <TextFieldFormsy
            variant="outlined"
            label=""
            value={msg}
            multiline
            // variant="outlined"
            onKeyPress={(event) => sendMessageOnEnter(event)}
            className="bottom-0 text-darkBackground dark:text-login border-2 dark:border-secondary  p-2 w-full rounded-xl"
            Action
            onChange={setChangeMessage}
            inputProps={{ className: classes.input }}
            sx={{
              input: { color: "black" },
              "& label": {
                color: "white",
              },
              "& label.Mui-focused": {
                color: "white",
              },
            }}
          /> */}
          <TextFieldFormsy
            type="text"
            isSupportChat={true}
            name="message"
            className="w-100 bg-primary my-2 mx-2"
            label={t("home.messageText")}
            onKeyPress={(event) => sendMessageOnEnter(event)}
            onChange={setChangeMessage}
            value={msg}
            componenttype={'chat'}
            validations={{
              minLength: 20,
            }}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            aria-label="LOG IN"
            value="legacy"
            onClick={() => {
              sendMessage()
            }}
            className="bg-primary my-2 mr-4  rounded-md h-auto   p-2 px-4">
            Send
          </Button>
        </div>
      </DialogActions>

    </Dialog >
  );

}

export default SupportChat








