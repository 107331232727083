import { Button, FormHelperText, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextFieldFormsy from "../core-components/TextFieldFormsy";
import ApiService from "../services/apiService";

export default function BankDetails() {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    account_type: "",
    bank_code: "",
    branch_code: "",
    account: "",
    confirmaccount: "",
  })

  const [bankErrors, setBankErrors] = useState([]);
  const [error, setError] = useState(null);
  const addBankDetails = () => {
    if (form.account.length < 7 || form.account === "" || form.bank_code === "" || form.branch_code === "" || form.name === "" || form.account_type === "" || form.confirmaccount === "") {
      let er = {
        name: "",
        bank_code: "",
        branch_code: "",
        account_type: "",
        account: "",
        confirmaccount: "",
      }
      if (form.bank_code === "" || form.bank_code === null) {
        er.bank_code = t("bankDetails.bankCodeError");
      } else {
        er.bank_code = ""
      }
      if (form.name === "" || form.name === null) {
        er.name = t("bankDetails.NameError");
      } else {
        er.name = ""
      }
      if(form.account === "" || form.account === null) {
        er.account = t("bankDetails.AccountError");
      } else if(form.account.length < 7) {
        er.account = t("bankDetails.accountError");
      } else {
        er.account = ""
      }
      if (form.confirmaccount === "" || form.confirmaccount === null) {
        er.confirmaccount = t("bankDetails.confirmAccountError");
      }
      if (form.branch_code === "" || form.branch_code === null) {
        er.branch_code = t("bankDetails.branchCodeError");
      } else {
        er.branch_code = ""
      }
      if (form.account_type === "" || form.account_type === null) {
        er.account_type = t("bankDetails.accountTypeError");
      } else {
        er.account_type = ""
      }
      setError({...er});
    } else {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key])
    }
    ApiService.addBankDetails(formData).then(res => {
      console.log(res);
      if (res.code === 200) {
        getBankDetails();
      } else {
        setBankErrors(res.errors);
      }
    })
    .catch(e => {
      console.log(e.errors);
      setBankErrors(e.errors);
    })
    }
  }

  React.useEffect(() => {
    window.scroll(0,0);
    getBankDetails();
  }, [])
  const getBankDetails = () => {
    ApiService.getBankDetails().then(result => {
      console.log(result)
      if (result.code === 200) {
        if (result?.data?.data.length === 0) {
          setForm({
            name: "",
            bank_code: "",
            branch_code: "",
            account_type: "",
            account: "",
            confirmaccount: "",
          })
        } else {
          setForm({
            name: result?.data?.data[0]?.name,
            bank_code: result?.data?.data[0]?.bank_code,
            branch_code: result?.data?.data[0]?.branch_code,
            account: result?.data?.data[0]?.account,
            account_type: result?.data?.data[0]?.account_type,
            confirmaccount: ""
          })
        }
      }
    })
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  return (
    <React.Fragment>
      <div>
        <div className="mb-4">
          <Typography
            variant={"h5"}
            className="py-5 text-center uppercase dark:text-fontWhite dark:bg-lightDarkBackground bg-gray-200 border-b-2"
          >
            {t("bankDetails.title")}
          </Typography>
        </div>
        <div className="flex flex-col gap-4">
          {/* <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.bankName")}
                name="bank_name"
                value={form?.bank_name}
                onChange={handleChange}
                className="w-3/5 text-fontWhite bg-loginDark"
                variant="filled"
              />
            </div>
            <div className="flex justify-center">
              <FormHelperText className="w-3/5">{error?.bank_name}</FormHelperText>
            </div>
          </div> */}
          <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.bankCode")}
                name="bank_code"
                value={form?.bank_code}
                onChange={handleChange}
                className="w-3/5 text-fontWhite bg-loginDark"
                variant="filled"
              />
            </div>
            <div className="flex justify-center">
              <FormHelperText className="w-3/5">{error?.bank_code}</FormHelperText>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.branchCode")}
                name="branch_code"
                value={form?.branch_code}
                onChange={handleChange}
                className="w-3/5 text-fontWhite bg-loginDark"
                variant="filled"
              />
            </div>
            <div className="flex justify-center">
              <FormHelperText className="w-3/5">{error?.branch_code}</FormHelperText>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.accountType")}
                name="account_type"
                value={form?.account_type}
                onChange={handleChange}
                className="w-3/5 text-fontWhite bg-loginDark"
                variant="filled"
              />
            </div>
            <div className="flex justify-center">
              <FormHelperText className="w-3/5">{error?.account_type}</FormHelperText>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.accountNumber")}
                name="account"
                onChange={handleChange}
                className="w-3/5 text-fontWhite bg-loginDark"
                value={form?.account}
                variant="filled"
              />
            </div>
            <div className="flex justify-center">
              <FormHelperText className="w-3/5">{error?.account}</FormHelperText>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.confirmAccountNumber")}
                name="confirmaccount"
                onChange={handleChange}
                value={form?.confirmaccount}
                className="w-3/5 text-fontWhite bg-loginDark"
                variant="filled"
              />
            </div>
            <div className="flex justify-center">
            {form?.confirmaccount === '' ?
              <FormHelperText className="w-3/5">{error?.confirmaccount}</FormHelperText> :
              form?.confirmaccount === form?.account ? '' :
                <FormHelperText className="w-3/5">{bankErrors?.confirmaccount}</FormHelperText>}
            </div>
          </div>
          {/* <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.swiftCode")}
                name="ifsc"
                value={form?.ifsc}
                onChange={handleChange}
                className="w-3/5 text-fontWhite bg-loginDark"
                variant="filled"
              />
            </div>
            <div className="flex justify-center">
              <FormHelperText className="w-3/5">{error?.ifsc}</FormHelperText>
            </div>
          </div> */}
          <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <TextFieldFormsy
                label={t("bankDetails.accountHolderName")}
                name="name"
                className="w-3/5 text-fontWhite bg-loginDark"
                variant="filled"
                onChange={handleChange}
                value={form?.name}
              />
            </div>
            <div className="flex justify-center">
              <FormHelperText className="w-3/5">{error?.name}</FormHelperText>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-center mb-6">
          <Button
            variant="contained"
            className="text-base bg-primary text-white dark:bg-button font-normal uppercase"
            onClick={addBankDetails}
          >
            {t("bankDetails.submit")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}