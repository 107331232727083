import {
  AppBar,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { FormControl, FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import Formsy from "formsy-react";
import { AsYouType } from "libphonenumber-js";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";
import AutoCompleteFormsy from "../../core-components/AutoCompleteFormsy";
import SelectFormsy from "../../core-components/SelectFormsy";
import TextFieldFormsy from "../../core-components/TextFieldFormsy";
import ApiService from "../../services/apiService";
import PlaceAutoComplete from "./PlaceAutoComplete";
export default function AddressForm(props) {
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = React.useState();
  const [result, setResult] = React.useState([]);
  const [fedexToken, setFedexToken] = React.useState();
  const [show, setShow] = React.useState();
  const [errorsLogin, setErrorsLogin] = React.useState(null);
  const [formatedDialingCode, setFormatedDialingCode] = React.useState([]);
  const [form, setForm] = React.useState({
    state: " ",
    city: "  ",
    name: "",
    address: "",
    mobile: "",
    landmark: "",
    country: "",
    pincode: "",
    address_type: "",
    state_code: "",
    country_code: "",
    dialing_code: "",
    document: "",
  });
  const user_id = sessionStorage.getItem("user_id");
  const [errors, setErrors] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [state, setState] = React.useState();
  const [address, setAddress] = React.useState([]);
  const [phone, setPhone] = React.useState("");
  const [country, setCountry] = React.useState([]);
  const [searchAddress, setSearchAddress] = React.useState();
  const [currentAddress, setCurrentAddress] = React.useState();

  // edit address function
  const editAddress = (id) => {
    const record = result.filter((item) => item.id === id);
    let countryData;
    country?.map((item, key) => {
      if (item.name === record[0].country) {
        countryData = { id: item.code, name: item.name, code: item.code };
      }
    });
    setForm({
      id: record[0].id,
      name: record[0].name,
      mobile: record[0].mobile,
      address: record[0].address,
      state: record[0].state,
      city: record[0].city,
      landmark: record[0].landmark,
      country: countryData,
      pincode: record[0].pincode,
      address_type: record[0].address_type,
      state_code: record[0].state_code,
      country_code: record[0].country_code,
      document: record[0].document,
      dialing_code: record[0].dialing_code,
    });
    setShow(true);
  };
  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const updatedCountryCode = usePrevious(form.country_code);
  // delete address function
  const deleteAddress = (id) => {
    ApiService.deleteCustomerAddress(id).then((res) => {
      const record = result.filter((item) => item.id !== id);
      setResult(record);
      props.handleReceiveProps(id, "delete", "delete");
    });
  };

  const cancel = () => {
    setShow(false);
    setForm([]);
    setErrors(null);
  };
  const setCheck = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleChange = (e) => {
    // var phoneNumber = parsePhoneNumber(e.target.value, form.dialing_code);
    if (e.target.name !== "document") {
      let temp;
      formatedDialingCode.map((item, key) => {
        if (item.id === form.dialing_code) {
          temp = item.code;
        }
      });

      let phoneNew = e.target.value;
      if (e.target.name === "mobile") {
        if (
          form.dialing_code === "" ||
          form.dialing_code === null ||
          typeof form.dialing_code === "null"
        ) {
          setErrorsLogin({
            ...errorsLogin,
            ["dialing_code"]: t("login.codeError"),
          });
        } else {
          let value = phoneNew.toString();
          let final = "";
          if (value.includes("(") && !value.includes(")")) {
            phoneNew = value.replace("(", "");
            var type = new AsYouType(temp);
            type.input(phoneNew);
            final = type.getChars();
          }

          if (final === "") {
            var number = new AsYouType(temp).input(phoneNew);
            setPhone(number);
            setForm({ ...form, [e.target.name]: phoneNew });
          } else {
            setPhone(final);
            setForm({ ...form, [e.target.name]: final });
          }
        }
      } else {
        setForm({ ...form, [e.target.name]: e.target.value });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };
  const handleSearchChange = (address) => {
    setSearchAddress(address);
    // setForm({ ...form, ['address']: address })
  };
  const [addressUpdated, setAddressUpdated] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  // add address function
  const addAddress = () => {
    const _form = new FormData();
    if (updatedCountryCode !== form.country_code && agree === false) {
      alert("country code changed");
      setOpenModal(true);
    } else {
      for (const value in form) {
        if (
          form[value] === "undefined" ||
          typeof form[value] === "undefined" ||
          form[value] === null ||
          form[value] === "null" ||
          form[value].id === ""
        ) {
          delete form[value];
        }
        if (
          form[value] !== "" &&
          typeof form[value] !== "undefined" &&
          value !== "undefined"
        ) {
          if (value === "address") {
            _form.append(value, form[value]);
          } else if (value === "document") {
            if (form.country_code === "BR") {
              _form.append("document", form.document);
            }
          } else if (value === "country") {
            _form.append(value, form[value].name);
          } else if (value === "mobile") {
            let temp;
            formatedDialingCode.map((item, key) => {
              if (item.id === form.dialing_code) {
                temp = item.code;
              }
            });
            var type = new AsYouType(temp);
            type.input(form.mobile);

            _form.append("mobile", type.getChars());
            _form.append(
              "dialing_code",
              typeof form.dialing_code === "undefined"
                ? "+91"
                : form.dialing_code
            );
          } else if (value === "dialing_code") {
          } else {
            _form.append(value, form[value]);
          }
        }
      }
      if (form.id) {
        _form.append("id", form.id);
        // update customer address api
        ApiService.updateCustomerAddress(_form).then((res) => {
          setAddressUpdated(!addressUpdated);
          setShow(false);
          setForm({
            state: "",
            city: "",
            name: "",
            address: "",
            mobile: "",
            landmark: "",
            country: "",
            pincode: "",
            address_type: "",
            document: "",
          });
          setErrors(null);
        });
      } else {
        let page = [...result];
        // add address api
        ApiService.addCustomerAddress(_form).then((res) => {
          if (res.code === 200) {
            page.unshift(res.data.data.result);
            setResult(page);
            setShow(false);
            setForm({
              state: "",
              city: "",
              name: "",
              address: "",
              mobile: "",
              landmark: "",
              country: "",
              pincode: "",
              address_type: "",
              document: "",
            });
            setPhone("");
            setErrors(null);
          } else {
            setErrors(res.errors);
          }
        });
      }
    }
  };
  const onPropsReceiveChange = (name, data) => {
    if (name === "country") {
      setForm({ ...form, ["country"]: data });
    } else {
      setForm({ ...form, [name]: data });
      setErrorsLogin({ ["dialing_code"]: "" });
    }
  };
  React.useEffect(() => {
    ApiService.getDialingCode().then((res) => {
      let tempArray = [];
      res.data.data.dialing_code.map((dialingCode) => {
        tempArray.push(dialingCode);
      });
      setCountry(tempArray);
    });
    ApiService.getDialingCode().then((res) => {
      let tempArray = [];
      res.data.data.dialing_code.map((dialingCode) => {
        tempArray.push({
          id: dialingCode.dialing_code,
          name: `${dialingCode.dialing_code}(${dialingCode.name})`,
          code: dialingCode.code,
        });
      });
      setFormatedDialingCode(tempArray);
    });
  }, []);

  React.useEffect(() => {
    setErrors(null);
    // get address api
    ApiService.getAddress(user_id).then((res) => {
      if (res.code === 200) {
        setLoading(false);
        setResult(res?.data?.data);

        setCurrentAddress(res.data.data.length && res.data.data[0].id);

        if (res.data.data.length > 0) {
          window.sessionStorage.setItem("pincode", res.data.data[0].pincode);

          props.handleReceiveProps(
            res.data.data[0].id,
            res.data.data[0].address,
            res.data.data[0].name
          );
        }

        if (res?.data?.data.length > 0) {
          setShow(false);
        } else {
          setShow(true);
        }
      }
    });
  }, [addressUpdated]);
  React.useEffect(() => {
    setTimeout(() => {
      const element = document.querySelectorAll(
        ".MuiInputBase-root.MuiOutlinedInput-root"
      );
      const arr = [...element];
      arr.forEach((ele) => {
        ele.classList.add("text-fontWhite");
      });
      const filledsTextField = document.querySelectorAll(
        ".MuiFilledInput-input"
      );
      const filledsTextFieldArray = [...filledsTextField];
      filledsTextFieldArray.forEach((ele) => {
        ele.classList.add("text-fontWhite");
      });
      const inputBackground = document.querySelectorAll(".MuiFormControl-root");

      const inputBackgroundArray = [...inputBackground];
      inputBackgroundArray.forEach((ele, key) => {
        ele.classList.add("dark:bg-lightDarkBackground");
        ele.classList.add("text-darkBackground");
      });
    }, 0);
  }, [show]);

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const setLocationData = (data) => {
    setForm({
      ...form,
      ["address"]: data.address,
      ["pincode"]: data.pincode,
      ["state"]: data.state,
      ["city"]: data.city,
      ["state_code"]: data.state_code,
      ["country_code"]: data.country_code,
    });
  };
  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        // fullWidth
        // maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar
          position="static"
          className="bg-primary text-white"
          elevation={1}
        >
          <Toolbar className="flex w-full">
            <Typography variant={"subtitle1"} color="inherit">
              {t("addressForm.confirmation")}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          className="dark:bg-lightDarkBackground text-center justify-center"
          classes={{ root: "p-2" }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            className="p-4 py-6 text-lg dark:text-fontWhite"
          >
            <Label className="text-lg dark:text-fontWhite text-justify">
              {t("addressForm.confirmationMessage")}
            </Label>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dark:bg-lightDarkBackground">
          <Button
            className="normal-case whitespace-no-wrap bg-primary text-white"
            variant="contained"
            onClick={() => {
              setAgree(false);
              handleModalClose();
            }}
            type="button"
          >
            {t("alertComponent.cancel")}
          </Button>
          <Button
            className="normal-case whitespace-no-wrap bg-primary text-white"
            variant="contained"
            onClick={() => {
              setAgree(true);
              addAddress();
            }}
            type="button"
          >
            {t("myProfile.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <div className="flex dark:bg-DarkBackground justify-center lg:p-3  items-center ">
        <div className="h-auto mx-2 mb-2 text-center justify-center">
          {show ? (
            <span className="rounded-full p-2 bg-button  text-white  text-center">
              {" "}
              <span
                onClick={() => cancel()}
                className="rounded-full h-10 p-2 cursor-pointer bg-button text-white"
              >
                {t("addressForm.close")}
              </span>
              {/* <CloseIcon
                className="cursor-pointer "
                onClick={() => cancel()}
                sx={{}}
              /> */}
            </span>
          ) : (
            <span
              onClick={() => setShow(true)}
              className="rounded-full h-10 p-2 cursor-pointer bg-button text-white"
            >
              {t("addressForm.addAddress")}
            </span>
          )}
        </div>
      </div>
      <Grid
        className="dark:bg-darkBackground mt-1 w-100 lg:m-auto lg:p-auto m-0 p-0"
        container
        spacing={2}
      >
        {show ? (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.name")}
                serverError={errors?.name}
                name="name"
                className="w-100"
                variant="filled"
                onChange={handleChange}
                value={form.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PlaceAutoComplete
                address={form?.address}
                serverError={errors?.address}
                getLocationFromPlace={setLocationData}
                t={t}
                // getLatLngFromPlace={getLatLngFromPlace}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Formsy className="flex-1">
                {formatedDialingCode.length > 0 && (
                  <SelectFormsy
                    onChange={onPropsReceiveChange}
                    className={`w-100 my-2 lg:my-0 bg-backgroundColor text-fontWhite flex-1`}
                    name="dialing_code"
                    label={t("myProfile.dialing_code")}
                    variant="filled"
                    value={form.dialing_code}
                    validationError={errorsLogin?.dialing_code}
                  >
                    {formatedDialingCode.map((item, index) => (
                      <MenuItem
                        value={item.id}
                        key={index}
                        className="dark:text-darkBackground -my-2"
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </SelectFormsy>
                )}
              </Formsy>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.mobile")}
                variant="filled"
                serverError={errors?.mobile}
                className="w-100 flex-1 "
                onChange={handleChange}
                name="mobile"
                value={phone !== "" ? phone : form.mobile}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                className="w-100"
                label={t("addressForm.city")}
                variant="filled"
                serverError={errors?.city}
                name="city"
                value={form?.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.state")}
                onChange={handleChange}
                className="w-100"
                serverError={errors?.state}
                name="state"
                value={form?.state}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.landmark")}
                serverError={errors?.landmark}
                name="landmark"
                variant="filled"
                className="w-100"
                onChange={handleChange}
                value={form.landmark}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.locality")}
                serverError={errors?.locality}
                name="locality"
                className="w-100"
                variant="filled"
                onChange={handleChange}
                value={form.locality}
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              {/* <TextFieldFormsy
                label={t("addressForm.country")}
                serverError={errors?.locality}
                name="country"
                variant="filled"
                onChange={handleChange}
                className="w-100"
                value={form.country}
              /> */}
              <AutoCompleteFormsy
                className="w-100 bg-backgroundColor text-fontWhite"
                name="country"
                options={country}
                variant="filled"
                // onSearchChange={handleSearchChange}
                value={form.country}
                label={` ${t("addressForm.country")}`}
                placeholder={` ${t("addressForm.country")}`}
                serverError={errors?.country}
                onSelectValueChange={onPropsReceiveChange}
              />
            </Grid>
            {form.country_code === "BR" && (
              <Grid item xs={12} sm={6}>
                <TextFieldFormsy
                  label={t("addressForm.document")}
                  serverError={errors?.document}
                  onChange={handleChange}
                  name="document"
                  variant="filled"
                  className="w-100"
                  type={"number"}
                  value={form.document}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.statecode")}
                serverError={errors?.state_code}
                onChange={handleChange}
                name="state_code"
                variant="filled"
                className="w-100"
                value={form.state_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.countrycode")}
                serverError={errors?.country_code}
                onChange={handleChange}
                name="country_code"
                variant="filled"
                className="w-100"
                value={form.country_code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                label={t("addressForm.pinCode")}
                serverError={errors?.pincode}
                onChange={handleChange}
                name="pincode"
                variant="filled"
                className="w-100"
                value={form.pincode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" className=" w-100 px-4">
                <FormLabel component="legend">
                  {t("addressForm.addressType")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label={t("addressForm.addressType")}
                  value={form?.address_type}
                  name="address_type"
                  className="dark:text-fontWhite text-button"
                  onClick={setCheck}
                >
                  <FormControlLabel
                    value="home"
                    control={
                      <Radio className="dark:text-fontWhite text-button" />
                    }
                    className="dark:text-fontWhite text-button"
                    label={t("addressForm.home")}
                  />
                  <FormControlLabel
                    value="office"
                    control={
                      <Radio className="dark:text-fontWhite text-button" />
                    }
                    className="dark:text-fontWhite text-button"
                    label={t("addressForm.office")}
                  />
                </RadioGroup>
                {errors?.address_type && (
                  <Typography className="text-red-600 font-normal text-xs">
                    {errors?.address_type}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            {/* <div className="w-full mt-2 mx-6 ">
              <TextFieldFormsy
                label={t("addressForm.NearByLocation")}
                onChange={handleChange}
                className="w-full"
                name="address"
                variant="outlined"
                value={form.address}
                multiline
                rows={5}
              />
            </div> */}

            <Grid item xs={12}>
              <Button
                className="text-white bg-button mb-3 p-2"
                variant="contained"
                color="primary"
                sx={{ mt: 3, ml: 1 }}
                onClick={() => addAddress()}
              >
                {form.id
                  ? t("addressForm.editAddress")
                  : t("addressForm.saveAddress")}
              </Button>
            </Grid>
          </React.Fragment>
        ) : (
          <div className=" w-full  lg:pl-12 pl-8 ">
            {loading ? (
              <div className="text-center">
                <CircularProgress
                  style={{
                    width: "50px",
                    height: "50px",
                    margin: "100px",
                    color: "#597593",
                  }}
                />
              </div>
            ) : (
              <div className="grid md:grid-cols-2  grid-cols-1 ">
                {result?.map((ele, key) => (
                  <Card
                    key={key}
                    onClick={() => {
                      setSelectedAddress(ele);
                      window.sessionStorage.setItem("address_id", ele.id);
                      setCurrentAddress(ele.id);
                      window.sessionStorage.setItem("pincode", ele.pincode);
                      props.handleReceiveProps(
                        ele.id,
                        ele.address,
                        ele.name,
                        ele.pincode
                      );
                    }}
                    className={
                      "flex lg:my-3 w-11/12 bg-background dark:bg-lightDarkBackground hover:bg-gray-100 shadow-lg rounded-2xl my-6  m-auto relative cursor-pointer p-2  lg:py-4  box-content gap-4 " +
                      (currentAddress === ele.id &&
                        "border-2 border-gray-500 bg-gray-100")
                    }
                  >
                    {currentAddress === ele.id ? (
                      <div className="text-right float-right absolute right-4 top-2">
                        <CheckCircleIcon className="text-button" />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className=" w-full p-1 flex">
                      {/* <FormControlLabel
                          checked={address === ele.id}
                          value="Online"
                          onChange={() => {}}
                          className="float-right md:px-4 absolute top-1 left-0 mx-0"
                          control={<Radio />}
                          label=""
                        /> */}
                      <div className="flex flex-col gap-2 px-2 w-full">
                        <Typography
                          variant={"subtitle2"}
                          className="text-md absolute top-10 right-4 font-extrabold"
                        >
                          <span className="font-bold bg-gray-500 dark:bg-darkBackground   px-2.5 py-1 rounded-xl text-xs text-white">
                            {ele.address_type.toUpperCase()}{" "}
                          </span>
                        </Typography>
                        <Typography
                          className="text-md mb-4 dark:text-fontWhite text-primary font-bold uppercase tracking-wider"
                          variant="subtitle2"
                        >
                          {ele.name}
                          <Typography
                            variant="subtitle2"
                            className="flex normal-case dark:text-fontWhite gap-4"
                          >
                            <div className="">{ele.address}</div>
                          </Typography>
                        </Typography>

                        <div className="flex gap-0 mr-16">
                          <div className="">
                            {/* <Typography variant="subtitle2">
                              <span className="font-bold dark:text-fontWhite text-gray-700">
                                {t("addressForm.address")}
                              </span>
                            </Typography> */}
                            <Typography variant="subtitle2">
                              <span className="font-bold dark:text-fontWhite text-gray-700">
                                {t("addressForm.city")}
                              </span>
                            </Typography>
                            <Typography variant="subtitle2">
                              <span className="font-bold  dark:text-fontWhite text-gray-700">
                                {t("addressForm.state")}{" "}
                              </span>
                            </Typography>
                            <Typography variant="subtitle2">
                              <span className="font-bold  dark:text-fontWhite text-gray-700">
                                {t("addressForm.country")}{" "}
                              </span>
                            </Typography>
                            <Typography variant="subtitle2">
                              <span className="font-bold dark:text-fontWhite text-gray-700">
                                {t("addressForm.pinCode")}{" "}
                              </span>
                            </Typography>
                            <Typography variant="subtitle2">
                              <span className="font-bold dark:text-fontWhite text-gray-700">
                                {t("addressForm.landmark")}
                              </span>
                            </Typography>
                          </div>
                          <div className="w-0.5 self-center bg-gray-400 h-4/5 mx-10"></div>
                          <div className="">
                            <Typography
                              variant="subtitle2"
                              className="flex dark:text-fontWhite gap-4"
                            >
                              <div className="">{ele.city}</div>
                            </Typography>
                            <Typography
                              className="dark:text-fontWhite"
                              variant="subtitle2"
                            >
                              {ele.state}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              className="flex dark:text-fontWhite gap-4"
                            >
                              <div className="">{ele.country}</div>
                            </Typography>
                            <Typography
                              className="dark:text-fontWhite"
                              variant="subtitle2"
                            >
                              {ele.pincode}
                            </Typography>
                            <Typography
                              className="dark:text-fontWhite"
                              variant="subtitle2"
                            >
                              {ele.landmark}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" float-right absolute bottom-4 right-4">
                      <div className=" ">
                        <EditIcon
                          className="my-2 dark:text-fontWhite cursor-pointer mr-2"
                          onClick={() => editAddress(ele.id)}
                        />
                        <DeleteIcon
                          className=" dark:text-fontWhite cursor-pointer"
                          onClick={() => deleteAddress(ele.id)}
                        />
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="w-full  text-right   pr-8"></div>
      </Grid>
    </React.Fragment>
  );
}
