import { Transition } from "@headlessui/react";
import {
  Card,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  makeStyles,
  MenuList
} from "@material-ui/core";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "semantic-ui-react";
import ApiService from "../../services/apiService";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid gray",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  marginLeft: 0,
  width: "auto",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function MyOrders({ mobile }) {
  const getAddress = (ele) => {


    const address = JSON.parse(ele?.address);
    if (!address) return;
    console.log(address);
    return address.address;

  };
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [filter, setFilter] = useState([]);
  const [check, setCheck] = useState([]);
  const [checked, setChecked] = useState([]);
  const [orderData, setOrderData] = useState();
  const [lastPage, setLastPage] = useState();
  const [loading, setLoading] = useState(true);
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const useStyles = makeStyles((theme) => ({
    ul: {
      "& .MuiPaginationItem-root": {
        color: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#fff" : "#050505" : themes === 'dark' ? "#fff" : "#050505"
      }
    }
  }));
  const [currentStatus, setCurrentStatus] = useState("");
  const [data, setData] = useState([]);
  const [currency_type, setCurrencyType] = useState();
  let msg = [];
  const { t } = useTranslation();
  // const setCurrentStatus = (currmsg) => {
  //   if (filter.includes(currmsg) === true) {
  //     msg.shift(currmsg);
  //     setFilter(filter.filter((item) => item !== currmsg));
  //   } else {
  //     msg.push(currmsg);
  //     setFilter([...filter, currmsg]);
  //   }
  // };
  const handleDelete = (ele) => {
    let data = filter.filter((item) => item !== ele);
    setFilter(data);
  };
  const [isOpen, setIsOpen] = React.useState(false);
  useEffect(() => {
    ApiService.getOrderList(currentStatus, page).then((res) => {

      if (res.code === 200) {
        if (res.data.data.orderdata.data.length > 0) {
          setCurrencyType(res.data.data.orderdata.data[0].get_item[0].product_detail[0].currency_data.symbol);
        }
        setData(res.data.data.orderdata.data);
        setLastPage(res.data.data.orderdata.last_page);
        setLoading(false);
        // over writing css of input label
        const inputLabelInMyOrder = document.querySelectorAll(
          ".css-1kcggdq-MuiInputBase-root .MuiInputBase-input "
        );
        const inputLabelInMyOrderArray = [...inputLabelInMyOrder];
        inputLabelInMyOrderArray.forEach((ele) => {
          ele.classList.add("dark:text-fontWhite");
          ele.classList.add("text-bolder");
          ele.classList.add("text-primary");
        });
      }
    });
    window.scrollTo(0, 0)
  }, [currentStatus, page]);
  const [order, setOrder] = useState();
  const cancelOrder = (neworder, neworderdata, type) => {
    if (neworder && neworderdata) {
      if (type === 'cancel') {
        history.push({
          pathname: `/cancel-order/${type}/${neworder}`,
          state: { data: neworderdata, order: neworderdata },
        });
      } else {
        history.push({
          pathname: `/return-order/${type}/${neworder}`,
          state: { data: neworderdata, order: neworderdata },
        });
      }
    } else {
      if (type === 'cancel') {
        history.push({
          pathname: `/cancel-order/${type}/${order}`,
          state: { data: check, order: orderData },
        });
      } else {
        history.push({
          pathname: `/return-order/${type}/${order}`,
          state: { data: check, order: orderData },
        });
      }
    }
  };
  const classes = useStyles();
  let temp = [...check];
  let id = [...checked];
  return (
    <React.Fragment>
      <Typography
        variant={"h5"}
        className="py-5 text-center uppercase dark:bg-lightDarkBackground dark:text-fontWhite bg-gray-200 border-b-2"
      >
        {t("myOrders.myOrders")}
      </Typography>
      <div className="grid mx-0 grid-cols-4 my-10 sm:mx-10">
        <div className="hidden dark:text-fontWhite lg:block h-fit lg:mt-4 lg:mb-8 lg:sticky lg:top-36">
          <div className="border">
            <Typography className="px-3 dark:text-fontWhite py-2">
              {t("myOrders.filters")}
            </Typography>
            <div className="mt-3">
              {filter.length > 0 &&
                filter.map((ele) => (
                  <Chip
                    className="mx-1 my-1"
                    label={ele}
                    onDelete={() => handleDelete(ele)}
                  />
                ))}
            </div>
            <div className="p-3">
              <Typography
                variant={"span"}
                className="text-sm dark:text-fontWhite font-normal uppercase"
              >
                {t("myOrders.orderStatus")}
              </Typography>
              <div className="w-100 px-2 py-1  border-1 rounded-xl border-gray">
                <MenuList>
                  <h1
                    className={`text-lg dark:text-fontWhite  ${currentStatus == "Processing"
                      ? "bg-button dark:bg-darkBackground dark:border-secondary border-1"
                      : "bg-background dark:bg-lightDarkBackground"
                      } cursor-pointer  p-2 rounded-sm font-normal`}
                    // control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Processing");
                    }}
                  //   label=
                  >
                    {t("myOrders.processing")}
                  </h1>
                </MenuList>
                <MenuList>
                  <h1
                    className={`text-lg dark:text-fontWhite cursor-pointer p-2 rounded-sm font-normal ${currentStatus == "Cancelled"
                      ? "bg-button  dark:bg-darkBackground dark:border-secondary border-1"
                      : "bg-background dark:bg-lightDarkBackground"
                      }`}
                    // control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Cancelled");
                    }}
                  //   label=
                  >
                    {t("myOrders.cancelled")}
                  </h1>
                </MenuList>
                <MenuList>
                  <h1
                    // className="text-xs font-normal"

                    className={`text-lg dark:text-fontWhite cursor-pointer p-2 rounded-sm font-normal ${currentStatus == "Delivered"
                      ? "bg-button dark:bg-darkBackground dark:border-secondary border-1"
                      : "bg-background dark:bg-lightDarkBackground"
                      }`}
                    // control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Delivered");
                    }}
                  //   label=
                  >
                    {t("myOrders.delivered")}
                  </h1>
                </MenuList>
                {/* <FormControlLabel
                  className="text-xs font-normal"
                  control={<Checkbox />}
                  onClick={() => {
                    setCurrentStatus("Delivered");
                  }}
                  label={t('myOrders.delivered')}
                />
                <FormControlLabel
                  className="text-xs font-normal"
                  control={<Checkbox />}
                  onClick={() => {
                    setCurrentStatus("Cancelled");
                  }}
                  label={t("myOrders.cancelled")}
                />
                <FormControlLabel
                  className="text-xs font-normal"
                  control={<Checkbox />}
                  onClick={() => {
                    setCurrentStatus("Returned");
                  }}
                  label={t("myOrders.returned")}
                /> */}
              </div>
            </div>
            {/* <div className="p-3">
              <Typography
                variant={"span"}
                className="text-sm font-normal uppercase"
              >
                {t("myOrders.orderTime")}
              </Typography>
              <FormGroup className="mt-2">
                <FormControlLabel
                  className="text-xs font-normal"
                  control={<Checkbox />}
                  onClick={() => {
                    setCurrentStatus("Last 30 days");
                  }}
                  label={t('myOrders.last30Days')}
                />
                <FormControlLabel
                  className="text-xs font-normal"
                  control={<Checkbox />}
                  onClick={() => {
                    setCurrentStatus("2020");
                  }}
                  label="2020"
                />
                <FormControlLabel
                  className="text-xs font-normal"
                  control={<Checkbox />}
                  onClick={() => {
                    setCurrentStatus("2019");
                  }}
                  label="2019"
                />
                <FormControlLabel
                  className="text-xs font-normal"
                  control={<Checkbox />}
                  onClick={() => {
                    setCurrentStatus("Older");
                  }}
                  label={t('myOrders.older')}
                />
              </FormGroup>
            </div> */}
          </div>
        </div>
        <div className="w-full h-full grid-flow-row col-span-4 sm:px-4 lg:col-span-3">
          <div className="flex justify-center">
            <Box sx={{ flexGrow: 1 }}>
              <div className="flex m-4 mt-0 justify-between gap-2 ">
                <div className="block flex-1 lg:-mr-2 lg:hidden">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    className="inline-flex w-full items-center justify-center p-2 text-gray-400 border-2 border-gray-600 rounded-md"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                  >
                    <span className="sr-only">
                      {t("myOrders.openMainMenu")}
                    </span>
                    {t("myOrders.filter")}
                  </button>
                </div>
                <Search className="flex-4">
                  <SearchIconWrapper>
                    <SearchIcon className="dark:text-fontWhite" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={t("header.search")}
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </div>
            </Box>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="border">
              <Typography className="px-3 py-2">Filters</Typography>
              <div className="mt-3">
                {filter.length > 0 &&
                  filter.map((ele) => (
                    <Chip
                      className="mx-1 my-1"
                      label={ele}
                      onDelete={() => handleDelete(ele)}
                    />
                  ))}
              </div>
              <div className="p-3">
                <Typography
                  variant={"span"}
                  className="text-sm font-normal uppercase"
                >
                  {t("myOrders.orderStatus")}
                </Typography>
                <FormGroup className="mt-2">
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("On the way");
                    }}
                    label="On the way"
                  />
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Delivered");
                    }}
                    label="Delivered"
                  />
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Cancelled");
                    }}
                    label="Cancelled"
                  />
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Returned");
                    }}
                    label="Returned"
                  />
                </FormGroup>
              </div>
              <div className="p-3">
                <Typography
                  variant={"span"}
                  className="text-sm font-normal uppercase"
                >
                  {t("myOrders.orderTime")}
                </Typography>
                <FormGroup className="mt-2">
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Last 30 days");
                    }}
                    label="Last 30 days"
                  />
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("2020");
                    }}
                    label="2020"
                  />
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("2019");
                    }}
                    label="2019"
                  />
                  <FormControlLabel
                    className="text-xs font-normal"
                    control={<Checkbox />}
                    onClick={() => {
                      setCurrentStatus("Older");
                    }}
                    label="Older"
                  />
                </FormGroup>
              </div>
            </div>
          </Transition>
          <div className="sm:px-4">
            {loading
              ? [1, 2, 3, 4, 5].map((ele, key) => (
                <Skeleton
                  height={100}
                  width={850}
                  className=" my-4"
                  key={key}
                />
              ))
              : data &&
              data.length > 0 &&
              data.map((ele, key) => (
                <React.Fragment>
                  <Accordion className="mb-10">
                    <Card
                      className="dark:bg-lightDarkBackground"
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="grid-flow-row  w-full px-4 pb-4">
                        <div className="mt-3 flex  justify-between">
                          <span className="w-auto px-4 dark:bg-darkBackground gap-1 sm:gap-3 sm:px-1 py-2 text-sm flex flex-col sm:flex-row font-normal text-center break-normal bg-yellow-100 lg:p-2 mx-2  rounded-xl">
                            <span className="text-nowrap dark:text-fontWhite font-bold">
                              {t("myOrders.soldBy")}
                            </span>
                            <span className="text-nowrap dark:text-fontWhite tracking-wider text-sm font-light">
                              {ele?.get_vendor?.business_name}
                            </span>
                          </span>
                          <span className="px-1 py-2 dark:text-fontWhite text-sm text-black">{`(${t('order.orderId')}:${ele?.order_number})`}</span>
                        </div>
                        <div className="grid grid-cols-1 mb-2 lg:grid-cols-2 mt-7 mx-2">
                          <div className="flex gap-5 p-2 ">
                            {ele.get_item.slice(0, 1).map((item, key) => (<div key={key} className='flex gap-5 p-2'>
                              <img
                                draggable={false}
                                alt="text"
                                src={item.product_detail[0]?.ImageSrc}
                                className="w-24 h-20 object-contain"
                              />

                              <div className="grid w-full grid-flow-row px-3 pl-10 ">
                                <Typography className="dark:text-fontWhite">
                                  {item?.product_detail[0]?.product_name}
                                </Typography>
                                <Typography className="dark:text-fontWhite">
                                 <span className="text-sm "> {`${Object.keys(JSON.parse(item?.product_option))}`}</span> : <span className="text-md font-bold">{`${Object.values(JSON.parse(item?.product_option))}`}</span>
                                </Typography>

                                <Typography
                                  variant={"span"}
                                  className="text-xs dark:text-fontWhite font-normal text-gray-500"
                                >
                                <span className="text-sm ">{t("myOrders.qty")}</span>  : <span className="text-md font-bold">{item?.quantity}</span>
                                </Typography>
                                {ele?.get_item.length > 1 && (
                                  <Typography variant="span" className="text-sm font-normal my-1 ">+1 {t('order.more')}..</Typography>
                                )}
                                <Typography
                                  variant={"span"}
                                  className="text-xs dark:text-fontWhite font-normal text-gray-500"
                                >
                                  <span className="mt-2  dark:text-fontWhite text-base font-normal text-left ">
                                    {currency_type}{" "}
                                    {item?.offer_price}
                                  </span>
                                </Typography>
                              </div>
                            </div>))}
                          </div>
                          <div className="grid ">
                            <div className="grid grid-flow-row sm:ml-0 ml-3 p-4">
                              <div className="flex">
                                {ele.status.includes("Refund") ||
                                  ele.status.includes("Cancelled") ? (
                                  <FiberManualRecordIcon
                                    sx={{
                                      height: 16,
                                      width: 16,
                                      color: "red",
                                    }}
                                    className="my-1 mr-1"
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    sx={{
                                      height: 16,
                                      width: 16,
                                      color: "green",
                                    }}
                                    className="my-1 mr-1"
                                  />
                                )}
                                <Typography
                                  variant={"subtitle1"}
                                  className="text-sm  dark:text-fontWhite font-bold "
                                >
                                  {ele?.status}
                                </Typography>
                              </div>
                              <Typography
                                variant={"subtitle2"}
                                className="text-xs dark:text-fontWhite font-bold text-gray-500"
                              >
                                {t("myOrders.orderAmount")}:{" "}
                                <span className="text-black">
                                  {currency_type}{" "}

                                  {ele.total_price}

                                </span>
                              </Typography>
                              <Typography
                                variant={"subtitle2"}
                                className="text-xs dark:text-fontWhite font-bold text-gray-500"
                              >
                                {t("cancelOrder.deliveryFee")}:{" "}
                                <span className="text-black">
                                  {currency_type}{" "}

                                  {ele.delivery_charges}


                                </span>
                              </Typography>
                              <Typography
                                variant={"body2"}
                                className="text-xs  dark:text-fontWhite font-bold text-gray-500"
                              >
                                {t("myOrders.orderedOn")}:{" "}
                                <span className="text-black dark:text-fontWhite">
                                  {moment(ele.created_at).format(
                                    "DD-MM-yyyy"
                                  )}
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex justify-between">
                          <div>
                            <Typography
                              variant={"subtitle1"}
                              className="text-sm sm:text-lg dark:text-fontWhite text-gray-500 font-extrabold"
                            >
                              {t("myOrders.address")} :{" "}
                              <span className="text-sm font-normal">
                                {getAddress(ele)}
                              </span>
                            </Typography>
                          </div>
                          <div className="p-2 flex-col sm:flex-row flex gap-2">
                            {(ele.status !== "Cancelled" && ele.status !== 'Delivered' && ele.status !== 'Dispatched') && (
                              <Button
                                onClick={() => {
                                  cancelOrder(ele?.order_number, ele, 'cancel');
                                }}
                                className="hover:opacity-80 opacity-100 rounded-md bg-primary text-nowrap h-10 lg:self-end m-3 mt-4 text-light px-3"
                              >
                                {t("myOrders.cancelOrder")}
                              </Button>
                            )}
                            <Button
                              className="hover:opacity-80 opacity-100 rounded-md bg-primary text-nowrap h-10 lg:self-end m-3 mt-4 text-light px-3"
                              onClick={() => {
                                history.push("/my-orders/" + ele.id);
                              }}
                            >
                              {t("myOrders.viewOrder")}
                            </Button>
                          </div>
                        </div>
                        <div className="w-full my-2">
                          <Typography
                            variant={"subtitle1"}
                            className="text-sm sm:text-lg dark:text-fontWhite text-gray-500 font-extrabold"
                          >
                            {t("myOrders.paymentDetail")}
                          </Typography>
                          <div className="flex justify-between py-1">
                            <div>
                              <Typography className="dark:text-fontWhite">
                                {ele?.payment_method}{" "}
                                {(ele?.status !== "Delivered" ||
                                  ele?.status !== "Cancelled") && (
                                    <span>{ele?.payment_status}</span>
                                  )}
                              </Typography>
                            </div>
                            <div>
                              <Typography className="dark:text-fontWhite">
                                {t("myOrders.transactionId")} :{" "}
                                {ele?.transaction_id}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className="p-4 border-2 rounded-md">
                          {ele.status.includes("Cancelled") ? (
                            <React.Fragment>
                              <Typography
                                variant={"span"}
                                className="text-base dark:text-fontWhite font-normal text-red-500"
                              >
                                {ele.status}
                              </Typography>
                              {ele.status === "Cancelled" ? (
                                <span className="mx-2 dark:text-fontWhite text-black text-sm">{`(Refund ID:${ele?.order_number})`}</span>
                              ) : (
                                <span className="mx-2 dark:text-fontWhite text-sm text-black">{`(${t('order.orderId')}:${ele?.order_number})`}</span>
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Typography
                                variant={"span"}
                                className="text-base dark:text-fontWhite font-normal text-green-500"
                              >
                                {ele.status}
                              </Typography>
                              <span className="mx-2 dark:text-fontWhite text-sm text-black">{`(${t('order.orderId')}:${ele?.order_number})`}</span>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Card>
                  </Accordion>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>

      <div className="lg:py-2 py-12 my-8 text-center w-full  flex  justify-center">
        <Pagination
          count={lastPage}
          classes={{ ul: classes.ul }}
          defaultPage={page}
          size={mobile ? "medium" : "large"}
          page={page}
          onChange={(event, value) => {
            setPage(value);
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </React.Fragment>
  );
}
