import { Chip, Divider, Typography } from "@material-ui/core";
import StarIcon from "@mui/icons-material/Star";
import StarRateIcon from "@mui/icons-material/StarRate";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React from "react";
import { List } from "semantic-ui-react";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function AllReviews(props) {
  const data = props.location.state.data;
  console.log(data);
  const product = props.location.state.product;
  console.log(product);

  return (
    <React.Fragment>
      <div className="w-full block px-10 py-10">
        <div className="grid lg:grid-cols-4 grid-cols-1  ">
          <div className=" p-6  flex-1 border-b-2 lg:border-b-0 lg:border-r-2">
            <img
              src={product.ImageSrc}
              alt={product.product_name}
              className="h-52 w-full"
            />
            <Typography className="pt-2 text-lg font-bold" variant="h6">
              {product.product_name}
            </Typography>
            <div className="flex">
              <Chip
                variant="outlined"
                className="bg-success border-success text-white"
                size="small"
                label={
                  <React.Fragment>
                    <div className="flex">
                      <Typography
                        variant="subtitle2"
                        className="text-sm  font-normal text-white"
                      >
                        {parseInt(product.rating)}
                      </Typography>
                      <StarIcon
                        sx={{ color: "white", height: 18, width: 18 }}
                        className="ml-1"
                      />
                    </div>
                  </React.Fragment>
                }
              />
              <Typography
                variant="body2"
                className="text-md font-normal p-1 px-2"
              >
                {product.total_rating} Ratings && {product.total_review} Reviews
              </Typography>
            </div>
            <div className="flex">
              <div
                variant="body2"
                className=" text-lg font-normal text-left text-red-600"
              >
                {product.currency_data.symbol} {product.offer_price}
              </div>
              <div
                variant="body2"
                className="text-xs font-normal px-2 py-2 line-through "
              >
                {product.currency_data?.symbol} {product.price}
              </div>
              <div
                variant="body2"
                className="text-xs font-normal py-2 text-green-800 "
              >
                {product.gst}% Off
              </div>
            </div>
            <div>
              <Divider />
            </div>
          </div>
          <div className="lg:col-span-3 grid-col-1 w-full border-1 border-black">
            <div className="grid-flow-row grid">
              <div>
                <Typography variant="h5" className="text-left pt-10 px-16 ">
                  {product.product_name}
                </Typography>
              </div>
              <div className="mx-8 ml-16 mt-4">
                <Divider />
              </div>
              <div className="px-16 pt-4">
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 ">
                  <div className="flex-1  lg:border-r-4">
                    <div className=" py-3 text-3xl text-center font-normal">
                      {Math.round(product?.rating)}
                      <StarRateIcon
                        sx={{ height: 24, width: 24, color: "green" }}
                      />
                      <div className="text-center text-sm font-normal">
                        {product?.total_rating} Ratings{" "}
                      </div>
                      <div className="text-center text-sm font-normal">&</div>
                      <div className="text-center text-sm font-normal">
                        {product?.total_review} Reviews
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-3">
                    <List>
                      <div className="grid-flow-row grid w-full  p-3">
                        <div className="text-sm font-normal mx-2 justify-all grid lg:grid-cols-6 md:grid-cols-4 grid-cols-1 px-10">
                          <div>Five</div>
                          <div className="lg:col-span-5 flex ">
                            <StarRateIcon
                              sx={{ height: 18, width: 18, color: "green" }}
                              className=" mr-2"
                            />
                            <BorderLinearProgress
                              variant="determinate"
                              value={product?.FiveStar}
                              sx={{ width: 300 }}
                              className="my-1"
                            />

                            <span className="float-right mx-2">
                              {product?.FiveStar}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="text-sm font-normal mx-2 justify-all grid lg:grid-cols-6 md:grid-cols-4 grid-cols-1 px-10">
                          <div>Four</div>
                          <div className="lg:col-span-5 flex ">
                            <StarRateIcon
                              sx={{ height: 18, width: 18, color: "green" }}
                              className=" mr-2"
                            />
                            <BorderLinearProgress
                              variant="determinate"
                              value={product?.FourStar}
                              sx={{ width: 300 }}
                              className="my-1"
                            />

                            <span className="float-right mx-2">
                              {product?.FourStar}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="text-sm font-normal mx-2 justify-all grid lg:grid-cols-6 md:grid-cols-4 grid-cols-1 px-10">
                          <div>Three</div>
                          <div className="lg:col-span-5 flex ">
                            <StarRateIcon
                              sx={{ height: 18, width: 18, color: "green" }}
                              className=" mr-2"
                            />
                            <BorderLinearProgress
                              variant="determinate"
                              value={product?.ThreeStar}
                              sx={{ width: 300 }}
                              className="my-1"
                            />

                            <span className="float-right mx-2">
                              {product?.ThreeStar}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="text-sm font-normal mx-2 justify-all grid lg:grid-cols-6 md:grid-cols-4 grid-cols-1 px-10">
                          <div>Two</div>
                          <div className="lg:col-span-5 flex ">
                            <StarRateIcon
                              sx={{ height: 18, width: 18, color: "green" }}
                              className=" mr-2"
                            />
                            <BorderLinearProgress
                              variant="determinate"
                              value={product?.TwoStar}
                              sx={{ width: 300 }}
                              className="my-1"
                            />

                            <span className="float-right mx-2">
                              {product?.TwoStar}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="text-sm font-normal mx-2 justify-all grid lg:grid-cols-6 md:grid-cols-4 grid-cols-1 px-10">
                          <div>One</div>
                          <div className="lg:col-span-5 flex ">
                            <StarRateIcon
                              sx={{ height: 18, width: 18, color: "green" }}
                              className=" mr-2"
                            />
                            <BorderLinearProgress
                              variant="determinate"
                              value={product?.OneStar}
                              sx={{ width: 300 }}
                              className="my-1"
                            />

                            <span className="float-right mx-2">
                              {product?.OneStar}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </List>
                  </div>
                </div>
                <div className="mt-4">
                  <Divider />
                </div>
              </div>
              <div className="flex-1 px-16 py-10">
                {data.map((ele, key) => (
                  <div className="flex-1 " key={key}>
                    <div className="flex my-4">
                      <Chip
                        variant="outlined"
                        size="small"
                        className="bg-success border-success text-white"
                        label={
                          <React.Fragment>
                            <div className="flex">
                              <Typography
                                variant="subtitle2"
                                className="text-sm  font-normal text-white"
                              >
                                {parseInt(ele.rating)}
                              </Typography>
                              <StarIcon
                                sx={{ color: "white", height: 18, width: 18 }}
                                className="ml-1"
                              />
                            </div>
                          </React.Fragment>
                        }
                      />
                      <Typography variant="subtitle1" className="px-4">
                        {ele.description}
                      </Typography>
                    </div>
                    <div className="flex">
                      <Typography
                        variant="body2"
                        className="text-md font-normal"
                      >
                        {ele.username[0].username}
                      </Typography>
                      <Typography
                        variant="span"
                        className="text-sm font-normal px-2"
                      >
                        {moment(ele.username[0].created_at).format(
                          "DD-MM-yyyy "
                        )}
                      </Typography>
                    </div>
                    <div className="my-2">
                      <Divider />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
