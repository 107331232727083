import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { useTranslation } from "react-i18next";
export default function BasicSelect(props) {
  // .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  const handleChange = (event) => {
    props.setValue(event.target.value);



    const dropdown = document.querySelectorAll('.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper')
    const dropdownArray = [...dropdown]
    console.log("this is dropdown ", dropdownArray)
    dropdownArray.forEach((ele) => {
      ele.classList.add('dark:bg-lightDarkBackground')
      // ele.classList.add('text-fontDark')
    })

  };


  const { t } = useTranslation();
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" className="mb-2 text-primary dark:text-fontWhite">
          {t('sortForm.sort')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.sortValue}
          label="Sort"
          onChange={handleChange}
          className="text-left relative dark:bg-lightDarkBackground dark:text-fontWhite"
        >

          <MenuItem className="dark:bg-lightDarkBackground dark:text-fontWhite" value={"new-arrivals"}>
            {t("sortForm.newArrivals")}
          </MenuItem>
          <MenuItem className="dark:bg-lightDarkBackground dark:text-fontWhite" value={"most-popular"}>{t("sortForm.mostPopular")}</MenuItem>
          <MenuItem className="dark:bg-lightDarkBackground dark:text-fontWhite" value={"price-low-to-high"}>{t("sortForm.priceLowToHigh")}</MenuItem>
          <MenuItem className="dark:bg-lightDarkBackground dark:text-fontWhite" value={"price-high-to-low"}>{t("sortForm.priceHighToLow")}</MenuItem>
          <MenuItem className="dark:bg-lightDarkBackground dark:text-fontWhite" value={"ratings-high-to-low"}>
            {t("sortForm.ratingHighToLow")}
          </MenuItem>

        </Select>
      </FormControl>
    </Box>
  );
}
