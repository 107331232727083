import { Card, makeStyles, Typography } from "@material-ui/core";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CardContent from "@mui/material/CardContent";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { Button } from "semantic-ui-react";
import AppConfig from "../appConfig";
import ApiService from "../services/apiService";
const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    objectFit: "cover",
  },

  button: {
    background:
      "linear-gradient(to bottom,#597593 0%, #597593 100%)",
    margin: 10,

    textAlign: "center",
    textTransform: "uppercase",
    transition: "0.5s",
    color: AppConfig.color.button,
    borderRadius: "10px",
    display: "block",
    boxShadow: "0 0 5px 0 #597593 inset",
    "&:hover": {
      background: "#597593",
    },
  },
}));
const { innerWidth } = window;
function Arrow(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow";
  className += " arrow";

  return (
    <div className=" relative ">
      {props.type === "next" ? (
        <ArrowRight
          className="rounded-full mt-36 w-8 h-8 text-white bg-primary"
          onClick={props.onClick}
        />
      ) : (
        <ArrowLeft
          onClick={props.onClick}
          className="rounded-full mt-36 w-8 h-8 text-white bg-primary"
        />
      )}
    </div>
  );
}

const SellersSlider = ({ mobile }) => {
  const { t } = useTranslation();

  const [brands, setBrands] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const settings = {
    dots: false,
    slidesToShow: 5,
    // nextArrow: <Arrow type="next" />,
    // prevArrow: <Arrow type="prev" />,
    autoplay: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 280, settings: { slidesToShow: 1 } },
      { breakpoint: 375, settings: { slidesToShow: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 2 } },
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 1280, settings: { slidesToShow: 5 } },
      { breakpoint: 1300, settings: { slidesToShow: 5 } },
    ],
  };
  const classes = useStyles();
  useEffect(() => {
    const _form = new FormData();
    const location_lat = sessionStorage.getItem("location_lat");
    const location_long = sessionStorage.getItem("location_long");
    _form.append("location_lat", location_lat);
    _form.append("location_long", location_long);
    _form.append("city", "udaipur");
    _form.append("state", "rajasthan");
    ApiService.getBrandList(_form).then((res) => {
      setBrands(res?.data?.data?.brand);
      setLoading(false);
    });
  }, []);
  return (
    <React.Fragment>
      <div>
        <div className=" d-flex flex-column  sm:flex-row align-items-center pr-8 pl-8 sm:px-10 justify-content-between ">
          <Typography
            variant="subtitle1"
            className="font-normal text-fontDark dark:text-fontWhite sm:pt-10 uppercase text-lg sm:text-2xl tracking-wider sm:tracking-normal"
          >
            {t("home.brand.title")}
          </Typography>

          <div className="lg:float-right ">
            <Button
              className={clsx(
                classes.button,
                "fw-bold p-2.5  my-2 mx-0  sm:mt-4 sm:-mb-4 text-black rounded-md lg:mr-2 text-xs sm:text-md font-normal w-auto sm:w-30 uppercase"
              )}
              onClick={() => {
                history.push({
                  pathname: `/brands/shop-by-brands`,
                  state: { id: 0, type: "Shop By Brand" },
                });
              }}
            >
              <RemoveRedEyeIcon className="p-0.5" />{" "}
              <span
                className="  pr-1 text-normal
                font-bold w-auto"
              >
                {t("home.button.viewAll")}
              </span>
            </Button>
          </div>
        </div>
        {loading
          ?
          <div className="grid lg:grid-cols-5 sm:grid-cols-4 sm:gap-4 grid-cols-2  pt-10 gap-3 lg:gap-2 mx-2 sm:mx-10">
            {[1, 2, 3, 4, 5].map((ele, key) => (
              <Skeleton height={150} className="" key={key} />
            ))}
          </div>
          : brands?.length < 5 ? (
            <div className="grid lg:grid-cols-5 sm:grid-cols-4 sm:gap-4 grid-cols-2  pt-10 gap-3 lg:gap-2 mx-2 sm:mx-10">
              {/* below condition reason : Because initially featuredProductData will be undefined untill data comes from home component */}
              {brands?.map((image, key) => {
                return (
                  <React.Fragment key={key}>
                    <div key={key} className="px-1 dark:bg-lightDarkBackground sm:px-1 lg:px-2">
                      <Card className="relative lg:mb-14 lg:mt-10 my-4 h-20 sm:h-32 hover:shadow-2xl  hover:shadow-inherit">
                        <img
                          draggable={false}
                          alt="text"
                          className="object-contain w-full cursor-pointer h-full lg:p-0 justify-items-center   "
                          src={image.imageUrl}
                          onClick={() => {
                            history.push({
                              pathname: `/shop/brands/${image?.slug}/${image.id}`,
                              state: {
                                id: image.id,
                                type: "Brand",
                              },
                            });
                          }}
                        />
                        {/* <CardContent
                          className="p-2 py-1 sm:py-2"
                          onClick={() => {
                            history.push({
                              pathname: `/my-following/${image?.name}`,
                              state: {
                                id: image.id,
                                type: "Seller",
                              },
                            });
                          }}
                        >
                          <div className="flex-1">
                            <div>
                              <span className="text-md dark:text-primary sm:text-lg sm:font-bold text-left ">
                                {image.name}
                              </span>
                            </div> */}
                            {/* <div>
                              <Rating
                                className="-ml-1 pointer-events-none"
                                name="simple-controlled"
                                value={image.rating}
                              />
                            </div> */}
                          {/* </div>
                        </CardContent> */}
                      </Card>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div className={`container  `} style={{ width: innerWidth === 820 ? "720px" : innerWidth < 900 ? innerWidth : '100%', padding: ((innerWidth < 913 && innerWidth > 540) || (innerWidth > 1000)) && '0px' }}>
              <Slider {...settings} className="flex mx-2 sm:mx-8">
                {loading
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, key) => (
                    <Skeleton
                      height={150}
                      width={100}
                      className="mx-10"
                      key={key}
                    />
                  ))
                  : brands?.map((image, key) => {
                    return (
                      <div key={key} className="px-1  sm:px-1 lg:px-2">
                        <Card className="relative dark:bg-lightDarkBackground mb-4 sm:mb-14 mt-10 h-20 sm:h-32 hover:shadow-2xl  hover:shadow-inherit">
                          <img
                            draggable={false}
                            alt="text"
                            className="object-contain w-full cursor-pointer h-full lg:p-0 justify-items-center   "
                            src={image.imageUrl}
                            onClick={() => {
                              history.push({
                                pathname: `/shop/brands/${image?.slug}/${image.id}`,
                                state: {
                                  id: image.id,
                                  type: "Brand",
                                },
                              });
                            }}
                          />
                          {/* <CardContent
                            className="p-2 py-1 sm:py-2"
                          // onClick={() => {
                          //   history.push({
                          //     pathname: `/my-following/${image?.name}`,
                          //     state: {
                          //       id: image.id,
                          //       type: "Seller",
                          //     },
                          //   });
                          // }}
                          >
                            <div className="flex-1">
                              <div>
                                <span className="text-md dark:text-primary sm:text-lg sm:font-bold text-left  ">
                                  {image.name}
                                </span>
                              </div> */}
                              {/* <div>
                                <Rating
                                  className="-ml-1 pointer-events-none"
                                  name="simple-controlled"
                                  value={image.rating}
                                />
                              </div> */}
                            {/* </div>
                          </CardContent> */}
                        </Card>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          )}
      </div>
    </React.Fragment>
  );
};

export default SellersSlider;
