import { Button, Typography } from "@material-ui/core";

import CircularProgress from "@mui/material/CircularProgress";
import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";
export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [hide, setHide] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  useEffect(() => {
    if (!stripe) {
      return;
    }
    setClientSecret(props.clientSecret);
    if (!clientSecret) {
      return;
    }

  }, []);

  const handleSubmit = async (e) => {
    // passing paymentInitiated as an argument will let component know that user has intiatted the payment and loader has to be shown

    props.setIsLoading(true);
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const error = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      // return_url:"localhost:3000/checkOut",
      // },
    });
    // below api is for final payment confirmation from stripe it will need paymentable id which it will get from props
    setTimeout(() => {
      // final api for payment confirmation
      ApiService.stripePaymentConfirmation(props.paymentableId).then((res) => {
        if (res.code == 200) {
          setHide(false)
          props.handlePaymentModalClose("paymentInitiated");
          props.setConfirmOrder(res.data.data);
          props.setIsLoading(false);
          props.handleNext();
          store.dispatch({
            type: ActionTypes.ADD_USER_CART_COUNT,
            payload: 0,
          });
        }
        else if (res.code === 400) {
          setHide(false)
          props.handlePaymentModalClose('failed');
          props.setIsLoading(false);
          props.paymentFailedError(res);
          // props.handleNext();
        }
        else {
          setHide(false)
          props.setIsLoading(false);
        }
      });
    }, 500);

    // }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
    }
  };

  return (
    <>
      <div className='text-center'>

        {props.loading ? <CircularProgress className='mt-10 text-center' style={{ color: '#597593' }} /> :
          <div id="payment-form" className='bg-login dark:bg-darkBackground  gap-10'>
            <div className='flex justify-between'><div>
              <Typography className='text-center text-darkBackground dark:text-button text-lg pt-4'>{t('review.totalAmount')}: </Typography>
            </div><div>

                <Typography className='text-center text-darkBackground dark:text-button text-lg pt-4'>{props.currency_type} {props.totalAmount} </Typography>
              </div>
            </div>
            <PaymentElement id="payment-element" className='py-10 gap-10' />
            {/* <button id="submit" onClick={(e) => handleSubmit(e)}>
          <span id="button-text">{t("checkOutForm.payNow")}</span>
        </button> */}
            <Button
              onClick={(e) => { setHide(true); handleSubmit(e); }}
              disabled={hide}
              className={`float-right bg-button dark:text-fontWhite text-login mr-4 mb-4 lg:mr-0 ${hide ? 'opacity-60 cursor-not-allowed' : ''}`} >
              {t("checkOutForm.payNow")}
            </Button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </div>}
      </div>
    </>
  );
}
