import {
  Button,
  Card,
  CardContent,
  createTheme,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import Formsy from "formsy-react";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import AppConfig from "../../appConfig";
import logo from "../../assets/images/NewLogo.png";
import SelectFormsy from "../../core-components/SelectFormsy";
import TextFieldFormsy from "../../core-components/TextFieldFormsy";
import ApiService from "../../services/apiService";
// const BootstrapInput = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position: "relative",
//     // backgroundColor: theme.palette.background.paper,
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     padding: "10px 26px 10px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       borderRadius: 4,
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//     },
//   },
// }));

const theme = createTheme();

export default function Login({ mobile }) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [hideButton, setHideButton] = useState(false);
  const [forgotPasswordState, setForgotPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showLoginWithEmailForm, setShowLoginWithEmailForm] = useState(false);
  const [form, setForm] = useState({
    phone: "",
    dialing_code: "",
    otp: "",
    email: "",
    code: "",
    password: "",
    name: "",
  });
 const [forgotpasswordError,setForgotPasswordError]=useState(null);
 const [forgotpasswordShow,setForgotPasswordShow]=useState(false);
  const [phone, setPhone] = useState("");
  const [errorsSignUp, setErrorsSignUp] = useState(null);
  const [termsError, setTermsError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [errorsLogin, setErrorsLogin] = useState(null);
  const [errorsLoginWithEmail, setErrorsLoginWithEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changeStatus, setChangeStatus] = useState(false);
  const { innerHeight } = window;
  const [formatedDialingCode, setFormatedDialingCode] = useState([]);
  const image = useSelector(({ allCategories }) => allCategories.loginImage);
  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.primary.contrastText,
      zIndex: "-1",
    },
    leftSection: {},
    rightSection: {
      // background: `linear-gradient(to right, ${theme.palette.primary.dark
      //   } 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
      color: theme.palette.primary.contrastText,
    },
    button: {
      background:
        "linear-gradient(to bottom,rgba(255,255,255,0) 0%, #597593 100%)",
      padding: "15px 45px",
      textAlign: "center",
      textTransform: "uppercase",
      transition: "0.5s",
      color: AppConfig.color.button,
      borderRadius: "10px",
      display: "block",
      boxShadow: "0 0 5px 0 #597593 inset",
      borderColor: "#597593",
      "&:hover": {
        background: "#597593",
      },
    },
    backImage: {
      backgroundImage: `url(${image})`,
      backgroundSize: "cover",
    },
  }));
  const classes = useStyles();
  const handleChange = (e) => {
    var phoneNumber = parsePhoneNumber(e.target.value, form.dialing_code);
    let temp;
    formatedDialingCode.map((item, key) => {
      if (item.id === form.dialing_code) {
        temp = item.code;
      }
    });
    setHideButton(false);
    let phoneNew = e.target.value;
    if (e.target.name === "phone") {
      if (
        form.dialing_code === "" ||
        form.dialing_code === null ||
        typeof form.dialing_code === "null"
      ) {
        if(forgotPasswordState){
          setForgotPasswordError({
            ...forgotpasswordError,
            ["dialing_code"]: t("login.codeError"),
          });
        }else{
        setErrorsLogin({
          ...errorsLogin,
          ["dialing_code"]: t("login.codeError"),
        });
      }
      } else {
        let value = phoneNew.toString();
        let final = "";
        if (value.includes("(") && !value.includes(")")) {
          phoneNew = value.replace("(", "");
          var type = new AsYouType(temp);
          type.input(phoneNew);

          final = type.getChars();
        }

        if (final === "") {
          var number = new AsYouType(temp).input(phoneNew);
          setPhone(number);
          setForm({ ...form, [e.target.name]: phoneNew });
        } else {
          setPhone(final);
          setForm({ ...form, [e.target.name]: final });
        }
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };
  const onPropsReceiveChange = (name, data) => {
    setForm({ ...form, [name]: data });
    setErrorsLogin({ ["dialing_code"]: "" });
  };
  const handleClick = () => {
    // phoneNumber.country === 'US'
    // phoneNumber.number === '8078604774'
    let temp;
    formatedDialingCode.map((item, key) => {
      if (item.id === form.dialing_code) {
        temp = item.code;
      }
    });

    if (
      form.dialing_code === "" ||
      form.dialing_code === null ||
      typeof form.dialing_code === "null"
    ) {
      setErrorsLogin({
        ...errorsLogin,
        ["dialing_code"]: t("login.codeError"),
      });
    } else {
      var phoneNumber = parsePhoneNumber(
        form.phone !== undefined ? form.phone : phone,
        temp
      );
      if (form.phone !== "" && phoneNumber?.isValid()) {
        setErrorsLogin({
          ...errorsLogin,
          ["phone"]: "",
        });
        let _form = new FormData();
        var type = new AsYouType(temp);
        type.input(form.phone);
        _form.append("phone", type.getChars());
        _form.append(
          "dialing_code",
          typeof form.dialing_code === "undefined" ? "+91" : form.dialing_code
        );
        if (form.otp) {
          _form.append("otp", form.otp);
          ApiService.verifyOtp(_form).then((res) => {
            if (res.code === 200) {
              setHideButton(false);
              // if (location.state == undefined) {
              history.push("/home");
              window.location.reload();
              // }
              // window.location.href = `${location.state.navigationUrl}`;
              // window.location.reload();
            } else {
              setHideButton(false);
              setErrorsLogin(res.errors);
            }
          });
        } else {
          ApiService.sendOtp(_form).then((res) => {
            if (res.code === 200) {
              setHideButton(false);
              setShow(true);
            } else if (res.code === 422) {
              setHideButton(false);
              setErrorsLogin(res.errors);
            }
          });
        }
      } else {
        setErrorsLogin({
          ...errorsLogin,
          ["phone"]: t("login.phoneError"),
        });
      }
    }
  };
  const sendForgotPasswordOTP=()=>{
    if (
      form.dialing_code === "" ||
      form.dialing_code === null ||
      typeof form.dialing_code === "null"
    ) {
      setForgotPasswordError({
        ...forgotpasswordError,
        ["dialing_code"]: t("login.codeError"),
      });
    } else{let temp;
    formatedDialingCode.map((item, key) => {
      if (item.id === form.dialing_code) {
        temp = item.code;
      }
    });
    var phoneNumber = parsePhoneNumber(
      form.phone !== undefined ? form.phone : phone,
      temp
    );
    if (form.phone !== "" && phoneNumber?.isValid()) {
      setErrorsSignUp({
        ...errorsSignUp,
        ["phone"]: "",
      });
      let _form = new FormData();
      var type = new AsYouType(temp);
      type.input(form.phone);
      _form.append("phone", type.getChars());
      _form.append(
        "dialing_code",
        typeof form.dialing_code === "undefined" ? "+91" : form.dialing_code
      );
      if (form.otp) {
        _form.append("otp", form.otp);
        _form.append("password", form.password);
        ApiService.verifyForgotpasswordOtp(_form).then((res) => {
          if (res.code === 200) {
            setForgotPasswordShow(false);
            setForgotPasswordError(null);
            setForgotPassword(false)
            window.location.reload();
              history.push("/");


          } else  {
            // setForgotPasswordShow(false);
            if(res.errors)
            {setForgotPasswordError(res.errors);}else if(res.message.includes('OTP')){
              setForgotPasswordError({'otp':res.message});
            }
          }
        });
      } else {
        ApiService.sendForgotPasswordOTP(_form).then((res) => {
          if (res.code === 200) {
            setForgotPasswordShow(true);

          } else if (res.code === 422) {
            setForgotPasswordShow(false);
            setForgotPasswordError(res.errors);
          }
        });
      }
    }else {
      setErrorsSignUp({
        ...errorsSignUp,
        ["phone"]: t("login.phoneError"),
      });
    }}
  }
  const hanlleLoginWithEmail = () => {
    let _form = new FormData();
    if (form.email.includes("@")) {
    }
    _form.append("password", form.password);
    _form.append(form.email.includes("@") ? "email" : "phone", form.email);
    ApiService.signUpWithEmail(_form).then((res) => {
      if (res.code === 200) {
        setHideButton(false);
        if (location.state == undefined) {
          history.push("/home");
          window.location.reload();
        }
        window.location.href = `${location.state.navigationUrl}`;
      } else if (res.code === 422) {
        setHideButton(false);
        setErrorsLoginWithEmail(res.errors);
      }
    });
  };
  const handleSignUp = () => {
    let _form = new FormData();
    // if (form.dialing_code === "" || form.dialing_code === null || typeof form.dialing_code === 'null') {
    //   setErrorsLogin({
    //     ...errorsLogin,
    //     ['dialing_code']: t('login.codeError')
    //   })
    // } else {
   
      let temp;
      formatedDialingCode.map((item, key) => {
        if (item.id === form.dialing_code) {
          temp = item.code;
        }
      });
      var phoneNumber = parsePhoneNumber(
        form.phone !== undefined ? form.phone : phone,
        temp
      );
      if (form.phone !== "" && phoneNumber?.isValid()) {
        var type = new AsYouType(temp);
        type.input(form.phone);
        _form.append("phone", type.getChars());
        setPhoneError({phone: ''});
      } else if (form.phone !== ""){
        setPhoneError({
          // ...errorsSignUp,
          phone: t("login.phoneError")
        });
      }
        _form.append(
          "dialing_code",
          typeof form.dialing_code === "undefined" ? "+91" : form.dialing_code
        );
        _form.append("name", form.name);
        _form.append("username", form.name);
        _form.append(
          "dialing_code",
          typeof form.dialing_code === "undefined" ? "+91" : form.dialing_code
        );
        _form.append("password", form.password);
        _form.append("email", form.email);
        if (form.otp) {
          _form.append("otp", form.otp);
          ApiService.verifyOtp(_form).then((res) => {
            if (res.code === 200) {
              setHideButton(false);
              if (location.state == undefined) {
                history.push("/home");
                window.location.reload();
              }
              window.location.href = `${location.state.navigationUrl}`;
            } else if (res.code === 422) {
              setHideButton(false);
              setErrorsSignUp(res.errors);
            }
          });
        } else {
          ApiService.signUP(_form).then((res) => {
            if (res.code === 200) {
              setHideButton(false);
              setShow(true);
              setChangeStatus(true);
            } else if (res.code === 422) {
              setHideButton(false);
              setErrorsSignUp(res.errors);
            }
          });
        }
    if (TermsConditionAccepted === false) {
      let error = { terms: "" };
      if (TermsConditionAccepted === false) {
        error.terms = t("login.termsError");
      }
      setTermsError(error);
    }
  };

  const resendOtp = () => {
    let temp;
    formatedDialingCode.map((item, key) => {
      if (item.id === form.dialing_code) {
        temp = item.code;
      }
    });
    setForm({ ...form, otp: "" });
    let _form = new FormData();
    var type = new AsYouType(temp);
    type.input(form.phone);
    _form.append("phone", type.getChars());
    _form.append(
      "dialing_code",
      typeof form.dialing_code === "undefined" ? "+91" : form.dialing_code
    );
    ApiService.resendCustomerOtp(_form).then((res) => {
      if (res.code === 200) {
        setHideButton(false);
      } else {
        setHideButton(false);
      }
    });
  };

  const [TermsConditionAccepted, setTermsConditionAccepted] = useState(false);

  const handleSubmit = () => {};
  useEffect(() => {
    ApiService.getDialingCode().then((res) => {
      let tempArray = [];
      res.data.data.dialing_code.map((dialingCode) => {
        tempArray.push({
          id: dialingCode.dialing_code,
          name: `${dialingCode.dialing_code}(${dialingCode.name})`,
          code: dialingCode.code,
        });
      });
      setFormatedDialingCode(tempArray);
    });
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <div className="text-center " style={{ height: innerHeight - 180 }}>
            <CircularProgress
              style={{
                width: "50px",
                height: "50px",
                margin: "100px",
                color: "#597593",
              }}
            />
          </div>
        ) : (
          <div
            className={clsx(
              classes.root,
              "flex   flex-auto pb-1 items-center justify-center bg-contain bg-left-top bg-no-repeat   dark:bg-darkBackground "
            )}
          >
            <div
              className={`flex ${
                show ? "my-24 mx-0" : "m-0"
              }	w-full mb-6 lg:mt-0 mt-6 sm:w-8/12 md:w-6/12 border-color: rgb(180 83 9);  items-center bg-loginDark  rounded-2xl  bg-cyan-500 shadow-lg shadow-cyan-500/50 justify-center overflow-hidden loginCard
            `}
            >
              <Card
                className={clsx(
                  classes.backImage,
                  "w-full bg-contain bg-right-center bg-no-repeat bg-backgroundColor  justify-center items-center"
                )}
              >
                {!showSignUpForm && (
                  <div className="flex items-center mt-4 mx-auto justify-center text-center">
                    <img
                      className="logo-icon  w-22 h-28 sm:h-28 "
                      src={logo}
                      alt="logo"
                    />
                  </div>
                )}
                <CardContent className="lg:grid block px-10 lg:px-0 lg:grid-cols-3 items-center justify-center ">
                  <div className="lg:col-span-3 ">
                    <div className="w-full ">
                      {forgotPasswordState ? (
                        <div className="grid-cols-1 gap-5 lg:px-16 my-10 mb-20 ">
                          <Typography className="text-white text-center text-xl mt-16 py-2 font-bold">
                            {t("login.forgotMessage")}
                          </Typography>
                          <Formsy className="my-4">
                            {formatedDialingCode.length > 0 && (
                              <>
                                <SelectFormsy
                                  onChange={onPropsReceiveChange}
                                  className="w-100 my-2  "
                                  name="dialing_code"
                                  labelId="demo-simple-select-error-label"
                                  id="demo-simple-select-outlined"
                                  label={t("myProfile.dialing_code")}
                                  variant="filled"
                                  value={form.dialing_code}
                                  validationError={forgotpasswordError?.dialing_code}
                                >
                                  {formatedDialingCode.map((item, index) => (
                                    <MenuItem
                                      className=" -my-2"
                                      value={item.id}
                                      key={index}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </SelectFormsy>
                              </>
                            )}
                            <TextFieldFormsy
                              className="w-100 my-2"
                              type="text"
                              // id="filled-basic"
                              name="phone"
                              label={t("login.enterPhone")}
                              onChange={(e) => handleChange(e)}
                              value={phone !== "" ? phone : form.phone}
                              serverError={forgotpasswordError?.phone}
                              validations={{
                                minLength: 4,
                              }}
                              variant="filled"
                            />
                            {forgotpasswordShow && (
                              <>
                              <TextFieldFormsy
                                className="w-100 my-2"
                                type="password"
                                name="otp"
                                label={t("login.enterTheOtp")}
                                value={form.otp}
                                onChange={(e) => handleChange(e)}
                                serverError={forgotpasswordError?.otp}
                                validations={{
                                  minLength: 4,
                                }}
                                validationErrors={{
                                  minLength: "Min character length is 4",
                                }}
                                variant="filled"
                                inputProps={{
                                  className: classes.input,
                                }}
                              />
                              <TextFieldFormsy
                                className="w-100 my-2"
                                type="password"
                                name="password"
                                label={t("login.password")}
                                value={form.password}
                                onChange={(e) => handleChange(e)}
                                serverError={forgotpasswordError?.password}
                                validations={{
                                  minLength: 4,
                                }}
                                validationErrors={{
                                  minLength: "Min character length is 4",
                                }}
                                variant="filled"
                                inputProps={{
                                  className: classes.input,
                                }}
                              />
                              </>
                            )}
                          </Formsy>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={clsx(
                              classes.button,
                              `w-100 normal-case my-2 lg:my-0`
                            )}
                            aria-label="LOG IN"
                            disabled={forgotpasswordShow?form.otp!==""&&form.password!=="" ? false : true:false}
                            onClick={() => {
                              // setHideButton(true);
                             sendForgotPasswordOTP();
                            }}
                            value="legacy"
                          >
                            {forgotpasswordShow?t("login.verify"):t("login.sendOtp")}
                          </Button>
                        </div>
                      ) : showSignUpForm ? (
                        <React.Fragment>
                          <div className="flex items-center mt-4 ">
                            <img
                              className="logo-icon mr-auto ml-auto w-22 h-28 sm:h-28 "
                              src={logo}
                              alt="logo"
                            />
                          </div>
                          <div className="text-center   my-3">
                            <Typography variant="h6">
                              {t("login.signUp")}
                            </Typography>
                          </div>
                          <Formsy
                            autoComplete="off"
                            className=" items-center justify-center  gap-2"
                          >
                            <div className=" items-center justify-center ">
                              <div className="py-4 lg:grid lg:grid-cols-1 block justify-between   ">
                                <div className="dialDark lg:grid block lg:grid-cols-2 gap-5 justify-center lg:px-10">
                                  <TextFieldFormsy
                                    type="text"
                                    name="name"
                                    className="w-100 border-0 my-2"
                                    label={t("login.name")}
                                    onChange={(e) => handleChange(e)}
                                    value={form.name}
                                    serverError={errorsSignUp?.name}
                                    validations={{
                                      minLength: 20,
                                    }}
                                    variant="filled"
                                  />
                                  <TextFieldFormsy
                                    type="email"
                                    className="w-100 border-0 my-2"
                                    name="email"
                                    label={t("login.email")}
                                    onChange={(e) => handleChange(e)}
                                    value={form.email}
                                    serverError={
                                      errorsSignUp &&
                                      errorsSignUp.email &&
                                      errorsSignUp?.email[0]
                                    }
                                    validations={{
                                      minLength: 20,
                                    }}
                                    variant="filled"
                                  />

                                  {formatedDialingCode.length > 0 && (
                                    <>
                                      <SelectFormsy
                                        onChange={onPropsReceiveChange}
                                        className="w-100 my-2  "
                                        name="dialing_code"
                                        labelId="demo-simple-select-error-label"
                                        id="demo-simple-select-outlined"
                                        label={t("myProfile.dialing_code")}
                                        variant="filled"
                                        value={form.dialing_code}
                                        validationError={
                                          errorsSignUp?.dialing_code
                                        }
                                      >
                                        {formatedDialingCode.map(
                                          (item, index) => (
                                            <MenuItem
                                              className=" -my-2"
                                              value={item.id}
                                              key={index}
                                            >
                                              {item.name}
                                            </MenuItem>
                                          )
                                        )}
                                      </SelectFormsy>
                                    </>
                                  )}
                                  <TextFieldFormsy
                                    className="w-100 my-2"
                                    type="text"
                                    // id="filled-basic"
                                    name="phone"
                                    label={t("login.enterPhone")}
                                    onChange={(e) => handleChange(e)}
                                    value={phone !== "" ? phone : form.phone}
                                    serverError={!phoneError?.phone ? errorsSignUp?.phone : phoneError?.phone}
                                    validations={{
                                      minLength: 4,
                                    }}
                                    variant="filled"
                                  />
                                  <TextFieldFormsy
                                    type="password"
                                    className="w-100 border-0 my-2"
                                    name="password"
                                    label={t("login.password")}
                                    onChange={(e) => handleChange(e)}
                                    value={form.password}
                                    serverError={errorsSignUp?.password}
                                    variant="filled"
                                  />
                                  {show && (
                                    <TextFieldFormsy
                                      className="w-100 my-2"
                                      type="password"
                                      name="otp"
                                      label={t("login.enterTheOtp")}
                                      value={form.otp}
                                      onChange={(e) => handleChange(e)}
                                      serverError={errorsSignUp?.otp}
                                      validations={{
                                        minLength: 4,
                                      }}
                                      validationErrors={{
                                        minLength: "Min character length is 4",
                                      }}
                                      variant="filled"
                                      inputProps={{
                                        className: classes.input,
                                      }}
                                    />
                                  )}
                                </div>
                                <div className=" lg:grid block lg:grid-cols-1 justify-between  lg:px-10 ">
                                  {show && (
                                    <Typography
                                      variant={"body2"}
                                      color="inherit"
                                      className=""
                                    >
                                      <Typography
                                        className="cursor-pointer text-white"
                                        onClick={() => resendOtp()}
                                      >
                                        {t("login.resendOtp")}
                                      </Typography>
                                    </Typography>
                                  )}

                                  <FormControlLabel
                                    className="mt-2 dark:text-darkBackground text-button cursor-pointer"
                                    control={
                                      <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                        onChange={() => {
                                          setTermsConditionAccepted(
                                            !TermsConditionAccepted
                                          );
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        className="my-2  text-white cursor-pointer"
                                        onClick={() =>
                                          // history.push({
                                          //   pathname: `/cms/terms-conditions`,
                                          //   state: { type: "terms-conditions" },
                                          // })
                                          window.open('/cms/terms-conditions')
                                        }
                                      >
                                        {t("login.termCondition")}
                                      </Typography>
                                    }
                                  />
                                      {!TermsConditionAccepted && termsError?.terms && (
                                    <Typography className="cursor-pointer  text-xs mb-1 text-danger">
                                          {termsError?.terms}
                                    </Typography>
                                  )}

                                  {!changeStatus ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={clsx(
                                        classes.button,
                                        "w-100 normal-case m-0"
                                      )}
                                      aria-label="LOG IN"
                                      disabled={!TermsConditionAccepted}
                                      onClick={() => {
                                        handleSignUp();
                                      }}
                                      value="legacy"
                                    >
                                      {t("login.signUpButton")}
                                    </Button>
                                  ) : (
                                    <Button
                                      disabled={
                                        form.otp == "" ||
                                        !TermsConditionAccepted ||
                                        hideButton
                                      }
                                      variant="contained"
                                      color="primary"
                                      className={clsx(
                                        classes.button,
                                        "w-100 normal-case m-0"
                                      )}
                                      aria-label="LOG IN"
                                      onClick={() => {
                                        handleSignUp();
                                        setHideButton(true);
                                      }}
                                      value="legacy"
                                    >
                                      {t("login.verify")}
                                    </Button>
                                  )}
                                  <Typography
                                    onClick={() => {
                                      setShowSignUpForm(false);
                                      setForm({
                                        phone: "",
                                        dialing_code: "",
                                        otp: "",
                                        email: "",
                                        password: "",
                                        name: "",
                                      });
                                      setPhone("");
                                      setShow(false);
                                      setErrorsLogin({ ["dialing_code"]: "" });
                                      setHideButton(false);
                                    }}
                                    className="fs-6 my-4 mb-4 text-white w-100 text-center cursor-pointer"
                                  >
                                    {t("login.alreadyAccount")}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </Formsy>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className="w-100 text-center my-3">
                            <Typography variant="h6" className="text-white">
                              {showLoginWithEmailForm
                                ? t("login.loginWithEmail")
                                : t("login.loginWithPhone")}
                            </Typography>
                          </div>
                          <Formsy
                            autoComplete="off"
                            className=" items-center justify-center  gap-2 lg:px-28"
                          >
                            <div className=" items-center justify-center ">
                              <div className="py-4 lg:grid block lg:grid-cols-1 justify-between gap-4  ">
                                <div className="dialDark justify-center">
                                  {showLoginWithEmailForm ? (
                                    <TextFieldFormsy
                                      className="w-100 border-none my-2 lg:my-0"
                                      type="email"
                                      name="email"
                                      label={t("login.emailPhone")}
                                      onChange={(e) => handleChange(e)}
                                      value={form.email}
                                      serverError={
                                        errorsLoginWithEmail &&
                                        errorsLoginWithEmail.email &&
                                        errorsLoginWithEmail?.email[0]
                                      }
                                      validations={{
                                        minLength: 20,
                                      }}
                                      variant="filled"
                                    />
                                  ) : (
                                    <>
                                      {/* <AutoCompleteFormsy
                                      className="w-75"
                                      name="dialing_code"
                                      options={formatedDialingCode}
                                      variant="filled"
                                      label="+91(India)"
                                      value={form.dialing_code}
                                      disabled={show ? true : false}
                                      serverError={errorsLogin?.dialing_code}
                                      onSelectValueChange={onPropsReceiveChange}
                                    /> */}
                                      {formatedDialingCode.length > 0 && (
                                        <SelectFormsy
                                          onChange={onPropsReceiveChange}
                                          className="w-100 my-2 lg:my-0 text-white"
                                          name="dialing_code"
                                          label={t("myProfile.dialing_code")}
                                          variant="filled"
                                          value={form.dialing_code}
                                          validationError={
                                            errorsLogin?.dialing_code
                                          }
                                        >
                                          {formatedDialingCode.map(
                                            (item, index) => (
                                              <MenuItem
                                                value={item.id}
                                                key={index}
                                                className=" -my-2"
                                              >
                                                {item.name}
                                              </MenuItem>
                                            )
                                          )}
                                        </SelectFormsy>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="">
                                  {showLoginWithEmailForm ? (
                                    <>
                                      <TextFieldFormsy
                                        className=" w-100 my-2 lg:my-0 border-none"
                                        type="password"
                                        name="password"
                                        label={t("login.password")}
                                        onChange={(e) => handleChange(e)}
                                        value={form.password}
                                        serverError={
                                          errorsLogin &&
                                          errorsLogin.password &&
                                          errorsLogin?.password
                                        }
                                        variant="filled"
                                      />
                                      <Typography
                                        className="cursor-pointer text-white my-1 text-right"
                                        onClick={() => {
                                          setForgotPassword(true);
                                        }}
                                      >
                                        {t("login.forgotPassword")}
                                      </Typography>
                                    </>
                                  ) : (
                                    <TextFieldFormsy
                                      className="border-none lg:my-0 my-2 w-100"
                                      type="text"
                                      name="phone"
                                      disabled={show ? true : false}
                                      label={t("login.enterPhone")}
                                      onChange={(e) => handleChange(e)}
                                      value={phone !== "" ? phone : form.phone}
                                      serverError={errorsLogin?.phone}
                                      validations={{
                                        minLength: 4,
                                      }}
                                      variant="filled"
                                    />
                                  )}
                                </div>
                                {show && (
                                  <React.Fragment>
                                    <TextFieldFormsy
                                      className="my-2 lg:my-0 w-100"
                                      type="password"
                                      name="otp"
                                      label={t("login.enterTheOtp")}
                                      value={form.otp}
                                      onChange={(e) => handleChange(e)}
                                      serverError={errorsLogin?.otp}
                                      validations={{
                                        minLength: 4,
                                      }}
                                      validationErrors={{
                                        minLength: "Min character length is 4",
                                      }}
                                      variant="filled"
                                      inputProps={{ className: classes.input }}
                                    />
                                    <div className="flex items-start  w-100">
                                      <div className="text-left justify-content-start  ">
                                        <Typography
                                          variant={"body2"}
                                          color="inherit"
                                          className=""
                                        >
                                          {!hideButton ? (
                                            <Typography
                                              className="cursor-pointer text-white"
                                              onClick={() => {
                                                resendOtp();
                                                setHideButton(true);
                                              }}
                                            >
                                              {t("login.resendOtp")}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              className="cursor-pointer text-white"
                                              onClick={() => {
                                                resendOtp();
                                                setHideButton(true);
                                              }}
                                            >
                                              {t("login.resendOtp")}
                                            </Typography>
                                          )}
                                        </Typography>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}

                                {showLoginWithEmailForm ? (
                                  <React.Fragment>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={clsx(
                                        classes.button,
                                        "w-100 normal-case"
                                      )}
                                      aria-label="LOG IN"
                                      onClick={() => {
                                        setHideButton(true);
                                        hanlleLoginWithEmail();
                                      }}
                                      value="legacy"
                                      disabled={hideButton ? true : false}
                                    >
                                      {t("login.login")}
                                    </Button>
                                    <div className="font-semibold w-100 text-center">
                                      {t("login.or")}
                                    </div>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={clsx(
                                        classes.button,
                                        "w-100 normal-case"
                                      )}
                                      aria-label="LOG IN"
                                      onClick={() => {
                                        setShowLoginWithEmailForm(false);
                                        setShow(false);
                                        setHideButton(false);
                                      }}
                                      value="legacy"
                                    >
                                      {t("login.loginWithPhone")}
                                    </Button>
                                  </React.Fragment>
                                ) : show ? (
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={clsx(
                                      classes.button,
                                      "w-100 my-6 lg:my-0 normal-case"
                                    )}
                                    aria-label="LOG IN"
                                    // disabled={!isFormValid}
                                    disabled={hideButton ? true : false}
                                    onClick={() => {
                                      setHideButton(true);
                                      handleClick();
                                    }}
                                    value="legacy"
                                  >
                                    {t("login.login")}
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={clsx(
                                      classes.button,
                                      `w-100 normal-case my-2 lg:my-0`
                                    )}
                                    aria-label="LOG IN"
                                    // disabled={hideButton ? true : false}
                                    onClick={() => {
                                      // setHideButton(true);
                                      handleClick();
                                    }}
                                    value="legacy"
                                  >
                                    {t("login.sendOtp")}
                                  </Button>
                                )}

                                {!showLoginWithEmailForm && !show && (
                                  <>
                                    <div className="font-semibold w-100 my-2 lg:my-0 text-center">
                                      {t("login.or")}
                                    </div>
                                    <div className=" items-center justify-center ">
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={clsx(
                                          classes.button,
                                          "w-100 normal-case m-0"
                                        )}
                                        aria-label="LOG IN"
                                        onClick={() => {
                                          setShowLoginWithEmailForm(true);
                                          setShow(false);
                                          setHideButton(false);
                                        }}
                                        value="legacy"
                                      >
                                        {t("login.loginWithEmail")}
                                      </Button>
                                    </div>
                                  </>
                                )}
                                <div>
                                  <Typography
                                    onClick={() => {
                                      setShowSignUpForm(true);
                                      setForm({
                                        phone: "",
                                        dialing_code: "",
                                        otp: "",
                                        email: "",
                                        password: "",
                                        name: "",
                                      });
                                      setPhone("");
                                      setShow(false);
                                      setErrorsLogin({ ["dialing_code"]: "" });
                                    }}
                                    className="fs-6 my-4 mb-4 w-100 text-white text-center cursor-pointer"
                                  >
                                    {t("login.newUser")}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </Formsy>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
