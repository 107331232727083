import { getToken, isSupported, onMessage } from "@firebase/messaging";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import branch from "branch-sdk";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../src/assets/css/style.css";
import backimage from "./assets/images/NewBackground.jpg";
import Message from "./core-components/Message";
import { ActionTypes } from "./redux/contants/ActionType";
import store from "./redux/store";
import Routing from "./Routing";
import ApiService from "./services/apiService";
import apiService from "./services/apiService/apiService";
import { messaging } from "./services/Firebase/firebase";
import "./styles/Custom.css";
import "./styles/output.css";
import "./assets/css/style.css";
import http from "./utils/http";
import whatsApp from "./assets/images/whatsapp.png";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

// Important common comments
// useTranslation library is used for tranlation in the site
// TextFieldFomrsy Component is just a textField which takes Placeholder and other things as a props

function App() {
  const [foreground, setForeground] = useState(null);
  const [isTokenFound, setTokenFound] = useState(false);
  const currenttheme = localStorage.getItem("theme");
  const [currentTheme, setCurrentTheme] = useState(currenttheme);
  const { innerWidth, innerHeight } = window;
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const [contactNumber, setContactNumber] = useState();

  // seting font family dynamically

  useEffect(() => {
    store.dispatch({
      type: ActionTypes.SET_IMAGE,
      payload: backimage,
    });
    setTimeout(() => {
      let font = new FontFace(
        "loadedFont",
        "url(../../assets/fonts/Poppins-Regular.woff')"
      );
      font
        .load()
        .then(function (loadedFont) {
          // const ele = document.getElementsByTagName('*')
          // console.log("body error",ele)
          // document.querySelector('head').style.fontFamily=loadedFont
          document.body.style.fontFamily = loadedFont.family;
          document.head.style.fontFamily = loadedFont.family;
          const typography = document.querySelectorAll(".MuiTypography-root");
          const typographyArray = [...typography];

          typographyArray.forEach((ele) => {
            ele.style.fontFamily = loadedFont.family;
          });
          const span = document.querySelectorAll(".span");
          const spanArray = [...span];
          spanArray.forEach((ele) => {
            ele.style.fontFamily = loadedFont.family;
          });
        })
        .catch(function (error) {
          // error occurred
        });
    }, 0);
    ApiService.getBrandList().then((res) => {
      if (res.code === 200) {
        // setAllData(res.data.data);
        store.dispatch({
          type: ActionTypes.ADD_ALL_DATA,
          payload: res.data.data,
        });
      }
    });
    handleGetContactNumber();
  }, []);
  useEffect(() => {
    if (currenttheme == null || currenttheme == "null") {
      setCurrentTheme("white");
      localStorage.setItem("theme", "white");
    }
    if (currentTheme == "dark") {
      const root = window.document.documentElement;
      root.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else if (currentTheme == "white") {
      const root = window.document.documentElement;
      root.classList.remove("dark");
      localStorage.setItem("theme", "white");
    }
  }, [currentTheme]);
  // setting css for components which css we cant access directly

  const handleGetContactNumber = () => {
    ApiService.contactUsData().then((res) => {
      // there can be more than one contact nbumber,Contact Person ,email,address so we will be pushing all contact number in one array and will map it while rendering
      res.data?.map((ele, key) => {
        if (ele.attr_key == "Contact Number") {
          setContactNumber(ele?.attr_value);
        }
      });
    });
  };

  useEffect(async () => {
    // branch library is used for sharing product or category or page url
    branch.init(
      "key_test_lf7VVSzdoKDSZi3Shzkuqpjmtxe3wI2C",
      function (err, data) {}
    );
    branch.data(function (err, data) {});

    const prefix_id = window.sessionStorage.getItem("prefix_id");
    const user_id = sessionStorage.getItem("user_id");
    if (prefix_id == null || prefix_id == "null" || prefix_id == undefined) {
      apiService.getConfig().then((res) => {});
    }
    let data;

    console.log("Requesting permission...");
    const isSupportedBrowser = await isSupported();
    console.log("isSupported", isSupportedBrowser);
    if (isSupportedBrowser) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          getToken(messaging, {
            vapidKey:
              "BCYB0awFSMf6ePYCq7S4na6fQfxAEDz3IFc4mAYosDPS-9o4yA029inOvRVSjb53SQoNjK7i7Cs-Hod5KaQYwMw",
          })
            .then((currentToken) => {
              if (currentToken) {
                console.log("Latest Token", currentToken, user_id);
                if (
                  user_id !== null &&
                  user_id !== "null" &&
                  user_id !== undefined
                ) {
                  let formData = new FormData();
                  formData.append("token", currentToken);
                  formData.append("topic", `${prefix_id}_user_id_${user_id}`);
                  formData.append("topic", `${prefix_id}_nily`);

                  http.post("/user/subscribe", formData).then((response) => {});
                }

                onMessage(messaging, (payload) => {
                  setForeground(payload);
                  // ...
                });
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // ...
            });
        } else {
        }
      });
    } else {
      console.log("Firebase not supported this browser");
    }

    return data;
  }, [setTokenFound]);

  return (
    <>
      <div>
        <a
          class="whats-app"
          href={`https://api.whatsapp.com/send/?phone=55${contactNumber}`}
          target="_blank"
        >
          <img src={whatsApp} className="whatsApp-img" />
        </a>
      </div>
      <Helmet>
        <title>Nily</title>
        <meta name="description" content="All types data"></meta>
      </Helmet>

      <ThemeProvider theme={theme}>
        <SkeletonTheme
          baseColor={
            themes === ""
              ? localStorage.getItem("theme") !== "dark"
                ? "#597593"
                : "#355863"
              : themes !== "dark"
              ? "#597593"
              : "#355863"
          }
          highlightColor="#efefef"
        >
          <div
            // style={{ width: innerWidth > 1000 ? "" : innerWidth, }}
            className={`${
              innerWidth > 1000 ? "lg:w-screen" : ""
            } bg-background dark:bg-darkBackground `}
          >
            <Routing
              setCurrentTheme={setCurrentTheme}
              currentTheme={currentTheme}
              foreground={foreground !== null ? foreground : null}
              setForeground={setForeground}
            />
            <Message />
          </div>
        </SkeletonTheme>
      </ThemeProvider>
    </>
  );
}
export default App;
