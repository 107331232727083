import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AppConfig from "../../appConfig";
import ProductCard from "../../core-components/ProductCard";
import ViewAllButton from "../../core-components/ViewAllButton";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";


const RecentProduct = ({ mobile, recentProductData }) => {
  const { t } = useTranslation();
  const { innerWidth } = window;
  const history = useHistory();
  // settings for slider

  // Below function is for adding product to wishlist
  const addToWishlist = (data) => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      // wishItemToggle(data.id);
      _form.append("product_id", data.id);
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      history.push(AppConfig.routes.login);
    }
  };

  const user_id = sessionStorage.getItem("user_id");
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );

  return (
    <React.Fragment>
      <div className="bg-background dark:bg-darkBackground">
        <div className=" d-flex flex-column sm:flex-row align-items-center pr-8 pl-8 sm:px-10 justify-content-between ">
          <Typography
            variant="subtitle1"
            className=" text-nowrap  text-fontDark dark:text-fontWhite text-lg tracking-wider sm:tracking-normal sm:text-2xl uppercase"
          >
            {t("home.recentProducts")}
          </Typography>
          <div className="lg:float-right ">
            <ViewAllButton type="recent-products" />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 sm:grid-cols-4 md:gap-4  grid-cols-2  pt-10 gap-3 lg:gap-4 mx-2 sm:mx-10">
          {/* below condition reason : Because initially recentProductData will be undefined untill data comes from home component */}
          {recentProductData == undefined
            ? [1, 2, 3, 4, 5].map((ele, key) => (
              <Skeleton
                height={300}
                width={innerWidth < 1000 ? 180 : 200}
                className="lg:mx-10"
                key={key}
              />
            ))
            : recentProductData.slice(0, 5).map((image, key) => {
              return (
                <ProductCard
                  key={key}
                  image={image}
                  type="Top Product"
                  typeForUrl="top-product"
                  addToWishlist={addToWishlist}
                // wishArray={wishArray}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecentProduct;
