import { ActionTypes } from "../contants/ActionType";

const initialState = {
  categories: [],
  session_id: null,
  user: "",
  theme: "",
  cartCount: 0,
  wishlistCount: 0,
  cartItem: [],
  notificationCount: 0,
  cmsData: [],
  cartItems: [],
  InfiniteScollProducts: [],
  allData: [],
  loginImage: "",
}
export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CATEGORIES:
      {
        let newState = { ...state };
        newState.categories = action.payload;
        return newState;
      }

    case ActionTypes.ADD_SESSION_ID: {
      let newState = { ...state }
      newState.session_id = action.payload;
      return newState;
    }
    case ActionTypes.ADD_THEME: {
      let newState = { ...state }
      newState.theme = action.payload;
      return newState;
    }
    case ActionTypes.ADD_USER_DATA: {
      let newState = { ...state }
      newState.user = action.payload;
      return newState;
    }
    case ActionTypes.ADD_USER_CART_COUNT: {
      let newState = { ...state }
      newState.cartCount = action.payload;
      return newState;
    }
    case ActionTypes.ADD_ALL_DATA: {
      let newState = { ...state }
      newState.allData = action.payload;
      return newState;
    }
    case ActionTypes.ADD_USER_WISHLIST_COUNT: {
      let newState = { ...state }
      newState.wishlistCount = action.payload;
      return newState;
    }
    case ActionTypes.ADD_USER_CART_ITEM: {
      let newState = { ...state }
      newState.cartItem = [action.payload];
      return newState;
    }
    case ActionTypes.ADD_USER_Notification_COUNT: {
      let newState = { ...state }
      newState.notificationCount = action.payload;
      return newState;
    }
    case ActionTypes.ADD_CMS: {
      let newState = { ...state }
      newState.cmsData = action.payload;
      return newState;
    }
    case ActionTypes.USER_CART_ITEMS: {
      let newState = { ...state }
      newState.cartItems = action.payload;
      return newState;
    }
    case ActionTypes.SET_IMAGE: {
      let newState = { ...state }
      newState.loginImage = action.payload;
      return newState;
    }
    case ActionTypes.INFINITE_SCROLL: {
      // let newState = { ...state }
      // newState.InfiniteScollProducts = action.payload;
      // return newState;
      console.log("this is action payl;oad", action.payload)
      let newState = { ...state }
      const data = newState.InfiniteScollProducts
      const finalData = data.concat(action.payload)
      newState.InfiniteScollProducts = finalData
      return newState
    }

    default: return { ...state };

  }
}