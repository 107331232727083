import { AppBar, Button, InputLabel, makeStyles, Toolbar, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { LightMode, ModeNight } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InputBase from "@mui/material/InputBase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppConfig from "../../../appConfig";
import logo from "../../../assets/images/latest.png";
import { ActionTypes } from "../../../redux/contants/ActionType";
import store from "../../../redux/store";
import ApiService from "../../../services/apiService";

function Navbar({ visible, currentTheme, setCurrentTheme }) {
  const session_id = sessionStorage.getItem("session_id");
  const styles = makeStyles((theme) => ({
    customBadge: {
      backgroundColor: "black",
      color: "white",
      width: "14px",
      height: "14px",
      fontSize: 3,
    },
  }));
  const classes = styles();
  const [notification, setNotification] = React.useState();
  const totalCart = useSelector(({ allCategories }) => allCategories.cartCount);

  // get wishlist count from redux
  const wishlistData = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );

  // get notification count from redux store
  const notificationCount = useSelector(
    ({ allCategories }) => allCategories.notificationCount
  );
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);
  const openNotification = Boolean(anchorElNotification);

  // click on notification icon
  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
    // below api for telling backend that we have read the notification so that they can set unread count to 0
    ApiService.readNotifications().then((res) => { });
  };
  // close notification icon
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };
  const [searchedData, setSearchedData] = React.useState();
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #d2cdcd",
    backgroundColor: "white",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  // styling inputBase using mui styled
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),

      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));
  // handle change in search box function
  const handleChangeInSearchBox = (e) => {
    setSearchedData(e.target.value);
  };

  // start searching function
  const startSearching = (e) => {
    const form = new FormData();
    handleClose(e);
    form.append("search", searchedData);
    history.push({
      pathname: `/shop/search/product/${searchedData}`,
      state: { searchedData: searchedData, type: "search" },
    });
  };
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const handleShow = () => {
    setShow(true);

    const inputElement = document.querySelectorAll('.css-4t3x6l-MuiPaper-root-MuiDrawer-paper')
    const inputPlaceHolderArray = [...inputElement]
    inputPlaceHolderArray.forEach((ele) => {
      ele.classList.add('dark:bg-lightDarkBackground')
      ele.classList.add('text-fontDark')
    })
    // using set time out so that the component on which we are tring to add class gets render first because we can only add class to component which is present in dom 
    setTimeout(() => {
      // over writing menu class 
      const profileMenuElement = document.querySelectorAll('.css-4t3x6l-MuiPaper-root-MuiDrawer-paper')
      const profileMenuElementArray = [...profileMenuElement]
      console.log('profileMenuElementArray item', profileMenuElementArray)
      profileMenuElementArray.forEach((ele) => {
        ele.classList.add('dark:bg-lightDarkBackground')
      })

    }
      , 0
    )
  }

  const [anchorElprofile, setAnchorElProfile] = useState(null);

  const user_id = sessionStorage.getItem("user_id");

  // log out function
  const setLogout = () => {
    setAnchorElProfile(null);
    dispatch({ type: ActionTypes.ADD_USER_DATA, payload: "" });
    sessionStorage.setItem("user_id", null);
    sessionStorage.setItem("access_token", null);
    localStorage.setItem("access_token", null);
  };
  const [showDeletePopup, setDeletePopup] = useState(false);
  const handleCloseDelete = () => {
    setDeletePopup(false);
  }
  const accountDelete = () => {
    ApiService.DeleteAccount().then(response => {
      console.log(response);
      setAnchorElProfile(null);
      sessionStorage.setItem("user_id", null);
      sessionStorage.setItem("access_token", null);
      localStorage.setItem("access_token", null);
      history.push("/");
      window.location.reload();
    })
  }
  useEffect(() => {
    // get Cms Data
    ApiService.getCmsData().then((res) => {
      if (res.code === 200) {
        store.dispatch({
          type: ActionTypes.ADD_CMS,
          payload: res.data.data.cms,
        });
      }
    });
    // get Categories
    ApiService.getCategories().then((res) => {
      if (res.code === 200) {
        // setting categories data in redux store
        dispatch({
          type: ActionTypes.ADD_CATEGORIES,
          payload: res.data.data,
        });
      }
    });
    const form1 = new FormData();
    form1.append("session_id", user_id !== null ? user_id : session_id);
    if (
      (session_id !== "null" && session_id !== null) ||
      (user_id !== "null" && user_id !== null)
    ) {
      // get cart details and set cart count equal to length of the cart array
      ApiService.getCartDetails(form1).then((res) => {
        if (res.code === 200) {
          store.dispatch({
            type: ActionTypes.ADD_USER_CART_COUNT,
            payload: res.data.length,
          });
        }
      });
    }

    if (user_id !== null && user_id !== "null") {
      // get notification only when user is logged in
      ApiService.getNotifications().then((res) => {
        setNotification(res.data?.data?.my_notifications.data);
        store.dispatch({
          type: ActionTypes.ADD_USER_Notification_COUNT,
          payload: res.data?.data?.unread_count,
        });
      });
      // get wishlist detail
      ApiService.getWishlistDetails(user_id).then((res) => {
        if (res.code === 200) {
          store.dispatch({
            type: ActionTypes.ADD_USER_WISHLIST_COUNT,
            payload: res.data.data.products.data.length,
          });
        }
      });
    }
  }, [user_id]);
  const profileOpen = Boolean(anchorElprofile);
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };
  const overWriteCss = () => {
    // over writing mui placeholder class 
    const inputElement = document.querySelectorAll('.MuiInputBase-input')
    const inputPlaceHolderArray = [...inputElement]
    inputPlaceHolderArray.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-fontDark')
    })
    // using set time out so that the component on which we are tring to add class gets render first because we can only add class to component which is present in dom 
    setTimeout(() => {
      // over writing menu class 
      const profileMenuElement = document.querySelectorAll('.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper')
      const profileMenuElementArray = [...profileMenuElement]
      console.log('profileMenuElementArray item', profileMenuElementArray)
      profileMenuElementArray.forEach((ele) => {
        ele.classList.add('dark:bg-lightDarkBackground')
      })
      // const profileMenuElement = document.querySelectorAll('MuiMenu-list')
      // const profileMenuElementArray = [...profileMenuElement]

      // profileMenuElementArray.forEach((ele) => {
      //   ele.classList.add('dark:bg-darkBackground')
      // })
      // menu css for live 
      const profileMenuElementForLive = document.querySelectorAll('.css-177ic5c')
      const profileMenuElementForLiveArray = [...profileMenuElementForLive]

      profileMenuElementForLiveArray.forEach((ele) => {
        ele.classList.add('dark:bg-lightDarkBackground')
      })

    }, 0);

  }
  useEffect(() => {

    overWriteCss()
  }, [anchorElNotification, anchorElprofile])
  return (
    visible && (
      <>
        <Dialog open={showDeletePopup} onClose={handleCloseDelete}>

          <AppBar
            position="static"
            className="bg-primary text-white"
            elevation={1}
          >
            <Toolbar className="flex w-full dark:bg-darkBackground">
              <Typography variant={"subtitle1"} color="inherit">
                {t("deletePopup.delAccount")} *
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent className="dark:bg-lightDarkBackground" classes={{ root: 'p-14' }}>
            <Typography className="leading-normal dark:text-fontWhite">{t("deletePopup.delMsg")} - </Typography>
            <Typography className="leading-normal dark:text-fontWhite">1. {t("deletePopup.msgOne")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">2. {t("deletePopup.msgTwo")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">3. {t("deletePopup.msgThree")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">4. {t("deletePopup.msgFour")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">5. {t("deletePopup.msgFive")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">{t("deletePopup.delConfirm")}</Typography>
          </DialogContent>
          <DialogActions className="flex justify-center dark:bg-lightDarkBackground">

            <Button
              variant="contained"
              className=" static bg-primary  text-white  h-10 lg:self-end m-3 mt-4"
              onClick={handleCloseDelete}
            >
              {t("alertComponent.cancel")}
            </Button>

            <Button
              variant="contained"
              className=" static bg-primary text-white h-10 lg:self-end m-3 mt-4"
              onClick={() => {
                accountDelete();
                setDeletePopup(false);
              }}
            >
              {t("deletePopup.confirmToDelete")}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="d-flex bg-light dark:bg-lightDarkBackground z-50 py-1 my-0 justify-content-between fixed opacity-95 dark:opacity-98 w-100 shadow-2xl">
          <div className="fw-bold px-1 fs-2 d-flex">
            <TemporaryDrawer />
            <span className="px-3 pt-2 fs-3 dark:text-fontWhite" onClick={handleShow}>
              <MenuIcon />
            </span>
            <span>
              <img
                src={logo}
                onClick={() => history.push(AppConfig.routes.home)}
                alt=""
                className="pt-1 cursor-pointer w-12 h-12"
              />
            </span>
          </div>
          <div className="flex justify-around items-center mr-3">
            <span className="dark:text-fontWhite" onClick={handleShow}>
              <SearchIcon className="mx-2 dark:text-fontWhite" />
            </span>
            <span className="dark:text-fontWhite">
              {currentTheme === "white" ? (
                <LightMode onClick={() => setCurrentTheme("dark")} />
              ) : (
                <ModeNight
                  className="dark:text-gray-200"
                  onClick={() => setCurrentTheme("white")}
                />
              )}
            </span>
            {user_id !== "null" && user_id !== null && (
              <>
                <span className="px-2 dark:text-fontWhite fs-3">
                  <Badge
                    badgeContent={wishlistData}
                    classes={{ badge: classes.customBadge }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiBadge-badge": {
                        fontSize: 12,
                        height: 18,
                        left: "4px",
                        minWidth: 18,
                        background: AppConfig.color.secondary,
                        color: "white",
                      },
                    }}
                  >
                    <FavoriteIcon
                      className="dark:text-fontWhite"
                      onClick={() => {
                        history.push({
                          pathname: `/wishlist`,
                          state: { id: 0, type: "Wishlist" },
                        });
                      }}
                    />
                  </Badge>
                </span>
                <span>
                  <Badge
                    badgeContent={notificationCount}
                    classes={{ badge: classes.customBadge }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiBadge-badge": {
                        fontSize: 12,
                        height: 18,
                        minWidth: 18,
                        background: AppConfig.color.secondary,
                        color: "white",
                        marginRight: "5px",
                        marginTop: "4px",
                      },
                    }}
                  >
                    <NotificationsActiveIcon
                      className="mx-2 mt-1 dark:text-fontWhite text-black"
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={openNotification ? "true" : undefined}
                      onClick={handleClickNotification}
                    />
                  </Badge>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElNotification}
                    open={openNotification}
                    onClose={handleCloseNotification}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <div className="grid grid-flow-row mx-2 w-330 h-500 text-justify break-words dark:bg-lightDarkBackground">
                      {notification?.map((ele, key) => (
                        <React.Fragment>
                          <div
                            onClick={() => {
                              if (ele.data.action == "order_details") {
                                history.push("/my-orders/" + ele.data.other_id);
                              } else if (ele.data.action == "message") {
                                history.push({
                                  pathname: `/my-chats`,
                                  state: {
                                    chatId: ele.data.other_id,
                                    productId: ele.data.other_data.product_id,
                                    productName:
                                      ele.data.other_data.product_name,
                                  },
                                });
                                setAnchorElNotification(null);
                              } else if (ele.data.action == "product_details") {
                                history.push({
                                  pathname: `/product-details/${ele.data.other_data.slug}/${ele.data.other_id}`,
                                });
                                setAnchorElNotification(null);
                              } else if (ele.data.action == "cart") {
                                history.push({
                                  pathname: `/cart-details`,
                                });
                                setAnchorElNotification(null);
                              } else if (ele.data.action == "payouts") {
                                setAnchorElNotification(null);
                              }
                            }}
                            className="mx-2 my-2 cursor-pointer "
                          >
                            <div className="flex align-center justify-between ">
                              <div className="flex align-center">
                                <RadioButtonCheckedIcon
                                  sx={{
                                    height: 20,
                                    width: 20,
                                  }}
                                  className="mt-1 mr-2"
                                />
                                <Typography
                                  variant="subtitle1"
                                  className="text-md mr-4 dark:text-fontWhite font-bold"
                                >
                                  {ele.data.title}
                                </Typography>
                              </div>
                              <div className="flex flex-col">
                                <Typography className="float-right dark:text-fontWhite mt-1 text-xs text-nowrap text-right">
                                  {ele.created_at.slice(0, 10)}
                                </Typography>
                                <Typography className=" mt-1 text-xs text-right text-nowrap">
                                  {ele.created_at.slice(11, 19)}
                                </Typography>
                              </div>
                            </div>
                            <Typography
                              variant="span "
                              className="text-md dark:text-fontWhite mx-2 font-normal"
                            >
                              {ele.data.message}
                            </Typography>
                          </div>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </div>
                  </Menu>
                </span>
              </>
            )}
            <span className="px-2 fs-3">
              <Badge
                badgeContent={totalCart}
                classes={{ badge: classes.customBadge }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 12,
                    height: 18,
                    minWidth: 18,
                    background: AppConfig.color.secondary,
                    color: "white",
                  },
                }}
              >
                <ShoppingBagOutlinedIcon className="dark:text-fontWhite"
                  onClick={() => history.push(AppConfig.routes.cart)}
                />
              </Badge>
            </span>
            <span className="mx-2">
              {(user_id === null || user_id === "null") && (
                <span
                  className=" py-4  dark:text-fontWhite text-sm font-medium uppercase rounded-md cursor-pointer "
                  onClick={() => {
                    history.push({
                      pathname: `/login`,
                      state: {
                        navigationUrl: window.location.href,
                      },
                    });
                  }}
                >
                  <Tooltip title={t("header.login")}>
                    <AccountCircleIcon className="text-black dark:text-fontWhite" />
                  </Tooltip>
                </span>
              )}
              {user_id !== null && user_id !== "null" && (
                <React.Fragment>
                  <Tooltip title={t("header.account")}>
                    <AccountCircleIcon
                      className="mt-1 dark:text-fontWhite "
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={profileOpen ? "true" : undefined}
                      onClick={handleClickProfile}
                    />
                  </Tooltip>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElprofile}
                    open={profileOpen}
                    onClose={handleCloseProfile}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        history.push(AppConfig.routes.profile);
                        handleCloseProfile();
                      }}
                      className="px-3 -my-2 max-w-330 dark:bg-lightDarkBackground dark:text-fontWhite text-sm font-medium rounded-md cursor-pointer"
                    >
                      {t("header.profile")}
                    </MenuItem>
                    <MenuItem
                      className="px-3 max-w-330 -my-2 dark:text-fontWhite dark:bg-lightDarkBackground text-sm font-medium rounded-md cursor-pointer"
                      onClick={() => {
                        history.push(AppConfig.routes.bankDetails);
                        handleCloseProfile();
                      }}
                    >
                      {t("profileMenu.myBankDetails")}
                    </MenuItem>
                    <MenuItem
                      className="px-3 max-w-330 -my-2 dark:text-fontWhite dark:bg-lightDarkBackground text-sm font-medium rounded-md cursor-pointer"
                      onClick={() => {
                        history.push(AppConfig.routes.myEarnings);
                        handleCloseProfile();
                      }}
                    >
                      {t("profileMenu.myEarnings")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push(AppConfig.routes.myChats);
                        handleCloseProfile();
                      }}
                      className="px-3 max-w-330 -my-2 dark:text-fontWhite dark:bg-lightDarkBackground text-sm font-medium rounded-md cursor-pointer"
                    >
                      {t("header.myChats")}
                    </MenuItem>
                    {user_id !== "null" && user_id !== null && (
                      <MenuItem
                        className="px-3 max-w-330 -my-2 text-sm dark:text-fontWhite dark:bg-lightDarkBackground font-medium rounded-md cursor-pointer "
                        onClick={() => {
                          history.push(AppConfig.routes.myOrders);
                          handleCloseProfile();
                        }}
                      >
                        {t("header.myOrders")}
                      </MenuItem>
                    )}
                    <MenuItem
                      className="px-3 max-w-330 -my-2 text-sm dark:text-fontWhite dark:bg-lightDarkBackground font-medium  rounded-md cursor-pointer "
                      onClick={() => {
                        history.push(AppConfig.routes.cart);
                        handleCloseProfile();
                      }}
                    >
                      {t("header.myCart")}
                    </MenuItem>
                    {user_id !== "null" && user_id !== null && (
                      <MenuItem
                        className="px-3 max-w-330 -my-2 dark:text-fontWhite dark:bg-lightDarkBackground text-sm font-medium rounded-md cursor-pointer"
                        onClick={() => {
                          history.push({
                            pathname: `/manage-addresses`,
                            state: { id: 0, type: "Following" },
                          });
                          handleCloseProfile();
                        }}
                      >
                        {t("profileMenu.manageAddress")}
                      </MenuItem>
                    )}
                    <MenuItem
                      className="px-3 max-w-330 -my-2 dark:text-fontWhite dark:bg-lightDarkBackground text-sm font-medium rounded-md cursor-pointer"
                      onClick={() => {
                        history.push(AppConfig.routes.myReviews);
                        handleCloseProfile();
                      }}
                    >
                      {t("profileMenu.myReviews")}
                    </MenuItem>
                    {user_id !== "null" && user_id !== null && (
                      <MenuItem
                        className="px-3 max-w-330 -my-2 text-sm dark:text-fontWhite dark:bg-lightDarkBackground font-medium rounded-md cursor-pointer "
                        onClick={() => {
                          history.push({
                            pathname: `/details/my-following`,
                            state: { id: 0, type: "Following" },
                          });
                          handleCloseProfile();
                        }}
                      >
                        {t("header.myFollowing")}
                      </MenuItem>
                    )}
                    <hr className="border-fontDark" />
                    <MenuItem
                      className="px-3 max-w-330 -my-2 dark:text-fontWhite dark:bg-lightDarkBackground text-sm font-medium rounded-md cursor-pointer"
                      onClick={() => {
                        setDeletePopup(true);
                        handleCloseProfile();
                      }}>
                      {t("profileMenu.deleteAccount")}
                    </MenuItem>
                    <hr className="border-fontDark" />
                    <MenuItem
                      onClick={() => {
                        setLogout();
                        history.push(AppConfig.routes.home);
                        handleCloseProfile();
                      }}
                      className="px-3 -my-2 text-sm dark:text-fontWhite dark:bg-lightDarkBackground max-w-330 font-medium rounded-md cursor-pointer"
                    >
                      {t("header.logout")}
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </span>
          </div>
        </div>
      </>
    )
  );

  function TemporaryDrawer() {
    return (
      <div>
        <React.Fragment>
          <Drawer anchor={"left"} open={show} onClose={handleClose}>
            <Box sx={{ width: 250 }} className="" role="presentation">
              <span
                className="w-100 dark:text-fontWhite  d-flex justify-center p-4"
                onClick={() => {
                  history.push(AppConfig.routes.home);
                  setShow(false);
                }}
              >
                <img className="w-45 h-20" src={logo} />
              </span>
              <div className="mx-4">
                <Search>
                  <SearchIconWrapper
                    onClick={() => {
                      startSearching("Enter");
                    }}
                  >
                    <SearchIcon />
                  </SearchIconWrapper>
                  <form
                    onSubmit={(event) => {
                      startSearching(event);
                    }}
                  >
                    <StyledInputBase
                      placeholder={t("header.search")}
                      // onKeyPress={(e) => {
                      //   startSearching(e);
                      // }}
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => {
                        handleChangeInSearchBox(e);
                      }}
                      value={searchedData}
                      autoFocus
                    />
                  </form>
                </Search>
              </div>
              <Divider />
              <List
                className="font-bold mt-4"
                onClick={() => {
                  setTimeout(() => setShow(false), 200);
                }}
              >
                <ListItem
                  disablePadding
                  onClick={() => {
                    if (user_id !== null && user_id !== "null")
                      history.push(AppConfig.routes.profile);
                    else history.push(AppConfig.routes.login);
                  }}
                >
                  <ListItemButton className="dark:text-fontWhite">
                    <ListItemText primary={t("header.profile")} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  onClick={() => {
                    if (user_id !== null && user_id !== "null")
                      history.push(AppConfig.routes.myChats);
                    else history.push(AppConfig.routes.login);
                  }}
                >
                  <ListItemButton className="dark:text-fontWhite">
                    <ListItemText primary={t("header.myChats")} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  onClick={() => {
                    if (user_id !== null && user_id !== "null")
                      history.push(AppConfig.routes.myOrders);
                    else history.push(AppConfig.routes.login);
                  }}
                >
                  <ListItemButton className="dark:text-fontWhite">
                    <ListItemText primary={t("header.myOrders")} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  onClick={() => {
                    history.push(AppConfig.routes.cart);
                  }}
                >
                  <ListItemButton className="dark:text-fontWhite">
                    <ListItemText primary={t("header.myCart")} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  onClick={() => {
                    if (user_id !== null && user_id !== "null")
                      history.push("/details/my-following");
                    else history.push(AppConfig.routes.login);
                  }}
                >
                  <ListItemButton className="dark:text-fontWhite">
                    <ListItemText primary={t("header.myFollowing")} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </React.Fragment>
      </div>
    );
  }
}

export default Navbar;
