import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { matchPath, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AppConfig from "../../../appConfig";
import SupporChat from "../../../Pages/supportChat/SupporChat";
import ApiService from "../../../services/apiService";
import Footer2 from './Footer2';
export default function Footer(props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const user_id = sessionStorage.getItem("user_id");
  const cmsData = useSelector(({ allCategories }) => allCategories.cmsData);
  const field = useSelector(({ allCategories }) => allCategories.categories);
  const { t } = useTranslation();
  const [openChatModel, setOpenChatModel] = useState(false)
  const [contactNumberArray, setContactNumberArray] = useState([]);
  const [contactPersonArray, setContactPersonArray] = useState([]);
  const [emailArray, setEmailArray] = useState([]);
  const currenttheme = localStorage.getItem("theme");
  const [currentTheme, setCurrentTheme] = useState(currenttheme);
  const [addressArray, setAddressArray] = useState([]);

  // In below useEffect we are calling contactusData api to get the contact us data
  useEffect(() => {
    // below api will give us  contact us data
    ApiService.contactUsData().then((res) => {
      // there can be more than one contact nbumber,Contact Person ,email,address so we will be pushing all contact number in one array and will map it while rendering
      res.data?.map((ele, key) => {
        if (ele.attr_key == "Contact Number") {
          setContactNumberArray((contactNumberArray) => [
            ...contactNumberArray,
            ele.attr_value,
          ]);
        } else if (ele.attr_key == "Contact Person") {
          setContactPersonArray((contactPersonArray) => [
            ...contactPersonArray,
            ele.attr_value,
          ]);
        } else if (ele.attr_key == "Email") {
          setEmailArray((emailArray) => [...emailArray, ele.attr_value]);
        } else if (ele.attr_key == "Address") {
          setAddressArray((addressArray) => [...addressArray, ele.attr_value]);
        }
      });
    });
  }, []);
  /* matchpath is used to match paths like ["/home/user/:id" with "/home/user/mihir"
   so it checks if path is in specified format if not it returns null]
   we dont want to show footer on my chats and not found page so we applied a condition here
  that footer does not render when it is on my-chats or path is not matched with any of the available paths*/
  return (pathname.includes('cart') || pathname.includes('checkOut') || pathname.includes('login')) ? <Footer2 setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} /> :
    pathname == "/my-chats" ||
      ![...Object.values(AppConfig.routes), "/"]
        .map((path, key) => {
          return matchPath(pathname, {
            path,
            exact: true,
          })?.url;
        })
        .includes(pathname) ? null : (
      <React.Fragment>
        <div
          className={`grid grid-flow-row bg-background dark:bg-darkBackground text-primary ${props.mobile ? "shadow-none" : "shadow-footersecondary"
            }`}
        >
          <div className="grid grid-cols-1 lg:justify-items-center text-primary gap-6 pt-2 sm:mx-10 lg:mx-0 sm:grid-cols-2 lg:grid-cols-4 sm:justify-items-start ml-0">
            <div className=" mx-10  ">
              <Typography
                variant={"h6"}
                className="text-primary  dark:text-fontWhite pt-3 pl-3 pr-3"
              >
                {t("footer.quickShop")}
              </Typography>
              <List
                sx={{
                  border: "2px",
                }}
                className="grid grid-cols-2 sm:block  text-nowrap justify-center"
              >

                {field.length === 0 ?
                  [1, 2, 3, 4, 5].map((ele, key) => (<Skeleton variant="text" key={key} className='my-2' />)) :
                  field.slice(0, 5).map((ele, key) => (
                    <React.Fragment key={key}>
                      <ListItem
                        alignItems="flex-start"
                        className="-mb-3 text-primary  dark:text-fontWhite "
                        key={key}
                      >
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                className="text-primary dark:text-fontWhite"
                              >
                                {ele.name}
                              </Typography>
                            </React.Fragment>
                          }
                          onClick={() =>
                            history.push({
                              pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                              state: { id: ele.id, type: "Categories" },
                            })

                          }
                          className="font-thin  cursor-pointer"
                          classes={{
                            secondary: "text-14 list-item-text-primary ",
                          }}
                        />
                      </ListItem>
                    </React.Fragment>
                  ))}
              </List>
            </div>
            <div className=" mx-10  ">
              <Typography
                variant={"h6"}
                className="pt-3 pl-3  pr-3 text-primary  dark:text-fontWhite"
              >
                {t("footer.informations")}
              </Typography>
              <List
                className="grid grid-cols-1  align-start justify-items-start text-nowrap  mt-0"
                sx={{
                  border: "2px",
                }}
              >

                {cmsData.length === 0 ?
                  [1, 2, 3].map((ele, key) => (<Skeleton variant="text" key={key} className='my-2 w-40' />))
                  :
                  cmsData.slice(0, 3).map((info, key) => (
                    <ListItem
                      alignItems="flex-start"
                      className="-mb-3 text-dark"
                      key={key}
                    >
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              className=" cursor-pointer text-primary  dark:text-fontWhite"
                              variant="body2"
                              onClick={() =>
                                history.push({
                                  pathname: `/cms/${info.slug}`,
                                  state: { type: info.slug },
                                })
                              }
                            >
                              {info.title}
                            </Typography>
                          </React.Fragment>
                        }
                        className="font-thin  "
                        classes={{
                          secondary: "text-14 list-item-text-primary ",
                        }}
                      />
                    </ListItem>
                  ))}
              </List>
            </div>
            <div className=" mx-10  ">
              <Typography
                variant={"h6"}
                className="pt-3 pl-3 pr-3 text-nowrap text-primary  dark:text-fontWhite"
              >
                {t("footer.customerService")}
              </Typography>
              <List
                className="grid grid-cols-1 sm:block text-nowrap items-start justify-center"
                sx={{
                  border: "2px",
                }}
              >
                {console.log(cmsData,user_id)}
                {cmsData.length === 0 ?
                  [1, 2, 3].map((ele, key) => (<Skeleton variant="text" key={key} className='my-2' />))
                  :
                  cmsData.slice(3).map((info, key) => (
                    info.slug === 'support' ?
                      user_id !== 'null' ?
                        <ListItem
                          alignItems="flex-start"
                          className="-mb-3 text-dark"
                          key={key}
                        >
                          <ListItemText
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  className=" cursor-pointer text-primary dark:text-fontWhite"
                                  variant="body2"
                                  onClick={() => {
                                    console.log("key", key)
                                    console.log("lenght", cmsData.length - 1)
                                    // if (key == 2) {
                                      setOpenChatModel(true)
                                    // }
                                    // else {
                                    //   history.push({
                                    //     pathname: `/cms/${info.slug}`,
                                    //     state: { type: info.slug },
                                    //   })
                                    // }
                                  }
                                  }
                                >
                                  {info.title}
                                </Typography>
                              </React.Fragment>
                            }
                            className="font-thin  "
                            classes={{
                              secondary: "text-14 list-item-text-primary ",
                            }}
                          />
                        </ListItem>
                        : ""
                      :
                      <ListItem
                        alignItems="flex-start"
                        className="-mb-3 text-dark"
                        key={key}
                      >
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                className=" cursor-pointer text-primary dark:text-fontWhite"
                                variant="body2"
                                onClick={() => {
                                  console.log("key", key)
                                  console.log("lenght", cmsData.length - 1)
                                  if (key == 2) {
                                    setOpenChatModel(true)
                                  }
                                  else {
                                    history.push({
                                      pathname: `/cms/${info.slug}`,
                                      state: { type: info.slug },
                                    })
                                  }
                                }
                                }
                              >
                                {info.title}
                              </Typography>
                            </React.Fragment>
                          }
                          className="font-thin  "
                          classes={{
                            secondary: "text-14 list-item-text-primary ",
                          }}
                        />
                      </ListItem>
                  ))}
                  <Typography className="px-4 py-3 cursor-pointer text-sm text-primary dark:text-fontWhite"
                    onClick={()=>{
                      setOpenChatModel(true);
                    }}>
                  {t("footer.supportChat")}
                  </Typography>
              </List>
            </div>

            <div className=" mx-10  ">
              <Typography
                variant={"h6"}
                className="pt-3 pl-3 pr-3 lg:pl-0 lg:pr-0 text-primary dark:text-fontWhite"
              >
                {t("footer.contactUs")}
              </Typography>
              <List
                className="grid grid-cols-1  lg:block mt-2 mx-4 lg:mx-0 text-nowrap items-start justify-center"
                sx={{
                  border: "2px",
                }}
              >
                <ListItemText
                  secondary={

                    contactNumberArray.length === 0 ?
                      [1, 2, 3].map((ele, key) => (<Skeleton variant="text" key={key} className='my-1' />))
                      : <div className="lg:pb-1">
                        {/* <div className='flex items-center'> */}
                        {

                          contactNumberArray.map((ele, key) => (
                            <React.Fragment key={key}>
                              <CallIcon className="mr-3 bg-transparent text-primary dark:text-fontWhite" key={key} />
                              <Typography
                                // sx={{ display: "inline" }}
                                component="span"
                                className=" text-primary dark:text-fontWhite"
                                variant="body1"
                              // onClick={() =>
                              //   history.push({
                              //     pathname: `/cms/${info.slug}`,
                              //     state: { type: info.slug },
                              //   })
                              // }
                              >
                               <a href={ `tel:${ele}`} className="hover:text-primary hover:no-underline"> {ele}</a>
                              </Typography>
                            </React.Fragment>
                          ))}
                      </div>
                  }
                  className="font-thin  "
                  classes={{
                    secondary: "text-14 list-item-text-primary ",
                  }}
                />

                <ListItemText
                  secondary={
                    <div className="pb-1">
                      {/* <div className='flex items-center'> */}
                      {contactPersonArray.map((ele, key) => (
                        <Typography
                          key={key}
                          // sx={{ display: "inline" }}
                          component="span"
                          className=" cursor-pointer text-primary dark:text-fontWhite "
                          variant="body2"
                        // onClick={() =>
                        //   history.push({
                        //     pathname: `/cms/${info.slug}`,
                        //     state: { type: info.slug },
                        //   })
                        // }
                        >
                          <a href={ `tel:${ele}`} className="hover:text-primary hover:no-underline"> {ele}</a>
                        </Typography>
                      ))}
                    </div>
                  }
                  className="font-thin  "
                  classes={{
                    secondary: "text-14 list-item-text-primary ",
                  }}
                />

                <ListItemText
                  secondary={
                    <div className="pb-1">
                      {/* <div className='flex items-center'> */}
                      {emailArray.map((ele, key) => (
                        <React.Fragment key={key}>
                          <MailIcon className="mr-3 text-primary dark:text-fontWhite bg-transparent" />
                          <Typography
                            // sx={{ display: "inline" }}
                            component="span"
                            className=" cursor-pointer text-primary dark:text-fontWhite"
                            variant="body2"
                          // onClick={() =>
                          //   history.push({
                          //     pathname: `/cms/${info.slug}`,
                          //     state: { type: info.slug },
                          //   })
                          // }
                          >
                             <a href={ `mailto:${ele}`}  className="hover:text-primary hover:no-underline"> {ele}</a>
                          </Typography>
                        </React.Fragment>
                      ))}
                    </div>
                  }
                  className="font-thin  "
                  classes={{
                    secondary: "text-14 list-item-text-primary ",
                  }}
                />
                <div className="pb-1 w-full h-full"></div>
                <ListItemText
                  secondary={
                    <React.Fragment>
                      {/* <div className='flex items-center'> */}
                      {addressArray.map((ele, key) => (
                        <div className="flex" key={key}>
                          <LocationOnIcon className="mr-3 text-primary dark:text-fontWhite bg-transparent" />
                          <Typography
                            // sx={{ display: "inline" }}
                            component="span"
                            className=" cursor-pointer text-primary dark:text-fontWhite text-wrap"
                            variant="body2"
                            onClick={() => {
                              window.open(`https://maps.google.com/maps?q=${ele}&t=&z=13&ie=UTF8&iwloc=&output=embed`)
                            }}
                          // onClick={() =>
                          //   history.push({
                          //     pathname: `/cms/${info.slug}`,
                          //     state: { type: info.slug },
                          //   })
                          // }
                          >
                            {ele}
                          </Typography>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                  className="font-thin  "
                  classes={{
                    secondary: "text-14 list-item-text-primary ",
                  }}
                />
              </List>
            </div>
          </div>
          <div className="grid sm:mb-5 mb-8 bg-black ">
            <Divider className=" bg-primary" />
          </div>
        </div>

        {openChatModel &&
          <SupporChat openChatModel={openChatModel} setOpenChatModel={setOpenChatModel} />
        }
      </React.Fragment>
    );
}
