import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppConfig from "./appConfig";
import Cms from "./Cms";
import Footer from "./components/common/footer/Footer";
import MobileFooter from "./components/common/footer/MobileFooter";
import Header from "./components/common/header/Header";
import MobileHeader from "./components/common/header/MobileHeader";
import Brand from "./core-components/Brand";
import Notification from "./core-components/Notification";
import AllReviews from "./Pages/AllReviews";
import Login from "./Pages/Authentication/Login";
import CartDetails from "./Pages/CartDetails";
import Checkout from "./Pages/Checkout/Checkout";
import Home from "./Pages/Home";
import ManageAddress from "./Pages/ManageAddress";
import MyChats from "./Pages/MyChats/MyChats";
import MyFollowing from "./Pages/MyFollowing";
import MyProfile from "./Pages/MyProfile";
import NotFound from "./Pages/NotFound";
import CancelOrder from "./Pages/Orders/CancelOrder";
import MyOrders from "./Pages/Orders/MyOrders";
import Order from "./Pages/Orders/Order";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import Shop from "./Pages/shop/Shop";
import VendorDetails from "./Pages/VendorDetails";
import ViewAll from "./Pages/ViewAll";
import Wishlist from "./Pages/wishlist/Wishlist";
import AllBrands from './core-components/AllBrands';
import BankDetails from "./Pages/BankDetails";
import myEarnings from "./Pages/MyEarnings";
import MyReviews from "./Pages/myReviews";

function Routing({ setCurrentTheme, currentTheme, foreground, setForeground }) {
  const [viewWidth, setViewWidth] = React.useState(window.offsetWidth);
  // const [params, setParams] = useState();
  const location = window.location.pathname;

  useEffect(() => {
    setViewWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setViewWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setViewWidth(window.innerWidth);
      });
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  return (
    <BrowserRouter>

      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            theme: {
              primary: "red",
            },
          },
        }}
      />
      {foreground !== null && (
        <>
          <Notification setForeground={setForeground} foreground={foreground} />
        </>
      )}

      {/* <ScrollTop /> */}

      {viewWidth > AppConfig.breakpoints.tablet && <Header setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} visible={true} />}

      {viewWidth <= AppConfig.breakpoints.tablet && (
        <MobileHeader setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} visible={true} />
      )}
      <div className={`lg:pt-28 pt-16 sm:pt-20 w-full `}>
        <Switch>

          <Route exact path="/" component={Home} />
          <Route
            exact
            path={AppConfig.routes.login}
            component={() => <Login mobile={viewWidth < 800} />}
          />
          <Route
            exact
            path={AppConfig.routes.shopByCategories}
            component={Shop}
          />
          <Route exact path={AppConfig.routes.home} component={Home} />
          <Route exact path={AppConfig.routes.shop} component={Shop} />
          

          <Route exact path={AppConfig.routes.cart} component={CartDetails} />
          <Route exact path={AppConfig.routes.checkout} component={Checkout} />
          <Route exact path={AppConfig.routes.ViewAll} component={ViewAll} />
          <Route
            exact
            path={AppConfig.routes.productDetails}
            component={ProductDetails}
          />
          <Route
            exact
            path={AppConfig.routes.bankDetails}
            component={BankDetails}
          />
          <Route
            exact
            path={AppConfig.routes.myEarnings}
            component={myEarnings}
          />
          <Route
            exact
            path={AppConfig.routes.myReviews}
            component={MyReviews}
          />
          <Route
            exact
            path={AppConfig.routes.myOrders}
            component={() => <MyOrders mobile={viewWidth < 800} />}
          />
          <Route exact path={AppConfig.routes.wishList} component={Wishlist} />
          <Route
            exact
            path={AppConfig.routes.myChats}
            component={() => (
              <div className="w-full h-full top-0 left-0 dark:bg-darkBackground fixed sm:pt-2 mt-14 sm:mt-16 lg:-mt-4">
                <MyChats />
              </div>
            )}
          />
          <Route
            exact
            path={AppConfig.routes.cancelOrder}
            component={CancelOrder}
          />
          <Route
            exact
            path={AppConfig.routes.returnOrder}
            component={CancelOrder}
          />
          <Route exact path={AppConfig.routes.profile} component={MyProfile} />

          <Route
            exact
            path={AppConfig.routes.myfollowing}
            component={MyFollowing}
          />
          <Route
            exact
            path={AppConfig.routes.vendordetails}
            component={VendorDetails}
          />
          <Route
            exact
            path={AppConfig.routes.allbrands}
            component={AllBrands}
          />
          <Route
            exact
            path={AppConfig.routes.allreviews}
            component={AllReviews}
          />
          <Route exact path={AppConfig.routes.viewOrder} component={Order} />
          <Route exact path={AppConfig.routes.Cms} component={Cms} />
          <Route exact path={AppConfig.routes.manageAddress} component={ManageAddress} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
      <Footer />
      {viewWidth <= AppConfig.breakpoints.mobile && !location.includes('login') && !location.includes('cart') && !location.includes('checkOut') && <MobileFooter />}
    </BrowserRouter>
  );
}
export default Routing;
