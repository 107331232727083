import {
  Divider,
  List, ListItemText,
  Typography
} from "@material-ui/core";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ApiService from "../../../services/apiService";
export default function Footer2(props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [openChatModel, setOpenChatModel] = useState(false)
  const [contactNumberArray, setContactNumberArray] = useState([]);
  const [contactPersonArray, setContactPersonArray] = useState([]);
  const [emailArray, setEmailArray] = useState([]);

  const [addressArray, setAddressArray] = useState([]);

  // In below useEffect we are calling contactusData api to get the contact us data
  useEffect(() => {
    // below api will give us  contact us data
    ApiService.contactUsData().then((res) => {
      // there can be more than one contact nbumber,Contact Person ,email,address so we will be pushing all contact number in one array and will map it while rendering
      res.data?.map((ele, key) => {
        if (ele.attr_key == "Contact Number") {
          setContactNumberArray((contactNumberArray) => [
            ...contactNumberArray,
            ele.attr_value,
          ]);
        } else if (ele.attr_key == "Contact Person") {
          setContactPersonArray((contactPersonArray) => [
            ...contactPersonArray,
            ele.attr_value,
          ]);
        } else if (ele.attr_key == "Email") {
          setEmailArray((emailArray) => [...emailArray, ele.attr_value]);
        } else if (ele.attr_key == "Address") {
          setAddressArray((addressArray) => [...addressArray, ele.attr_value]);
        }
      });
    });
  }, []);
  /* matchpath is used to match paths like ["/home/user/:id" with "/home/user/mihir"
   so it checks if path is in specified format if not it returns null]
   we dont want to show footer on my chats and not found page so we applied a condition here
  that footer does not render when it is on my-chats or path is not matched with any of the available paths*/
  return (
    <React.Fragment>
      <div
        className={`w-100 bg-background dark:bg-darkBackground text-primary  ${props.mobile ? "shadow-none" : "shadow-footersecondary"
          }`}
      >

        <div className=" w-100 flex  justify-center">
          {/* <Typography
            variant={"h6"}
            className="pt-3 pl-3 pr-3 lg:pl-0 lg:pr-0 text-primary dark:text-fontWhite"
          >
            {t("footer.contactUs")}
          </Typography> */}
          <List
            className="lg:flex md:flex grid grid-cols-2 px-3  mt-2 text-center items-center justify-center"
            sx={{
              border: "2px",
            }}
          >
            <ListItemText
              secondary={
                <div className="lg:pb-1">
                  <div className='lg:flex md:flex block items-center'>
                    {contactNumberArray.map((ele, key) => (
                      <React.Fragment key={key}>
                        <div className='lg:flex md:flex block'>
                          <div>
                            <CallIcon className="mx-3 bg-transparent text-primary dark:text-fontWhite" key={key} />
                          </div>
                          <div>
                            <Typography
                              // sx={{ display: "inline" }}
                              component="span"
                              className=" text-primary dark:text-fontWhite"
                              variant="body1"
                            // onClick={() =>
                            //   history.push({
                            //     pathname: `/cms/${info.slug}`,
                            //     state: { type: info.slug },
                            //   })
                            // }
                            >
                               <a href={ `tel:${ele}`}  className="hover:text-primary hover:no-underline"> {ele}</a>
                            </Typography>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              }
              className="font-thin  lg:flex md:flex block"
              classes={{
                secondary: "text-14 list-item-text-primary ",
              }}
            />



            <ListItemText
              secondary={
                <div className="pb-1">
                  {/* <div className='flex items-center'> */}
                  {emailArray.map((ele, key) => (
                    <React.Fragment key={key}>
                      <MailIcon className="mx-3 text-primary dark:text-fontWhite bg-transparent" />
                      <Typography
                        // sx={{ display: "inline" }}
                        component="span"
                        className=" cursor-pointer text-primary dark:text-fontWhite"
                        variant="body2"
                      // onClick={() =>
                      //   history.push({
                      //     pathname: `/cms/${info.slug}`,
                      //     state: { type: info.slug },
                      //   })
                      // }
                      >
                          <a href={ `mailto:${ele}`}  className="hover:text-primary hover:no-underline"> {ele}</a>
                      </Typography>
                    </React.Fragment>
                  ))}
                </div>
              }
              className="font-thin  "
              classes={{
                secondary: "text-14 list-item-text-primary ",
              }}
            />
            <ListItemText
              secondary={
                <React.Fragment>
                  <div className='col-span-2 my-2'>
                    {addressArray.map((ele, key) => (
                      <div className="flex" key={key}>
                        <LocationOnIcon className="lg:mr-3  text-primary dark:text-fontWhite bg-transparent" />
                        <Typography
                          // sx={{ display: "inline" }}
                          component="span"
                          className=" cursor-pointer text-primary dark:text-fontWhite text-wrap"
                          variant="body2"
                          onClick={() => {
                            window.open(`https://maps.google.com/maps?q=${ele}&t=&z=13&ie=UTF8&iwloc=&output=embed`)
                          }}
                        // onClick={() =>
                        //   history.push({
                        //     pathname: `/cms/${info.slug}`,
                        //     state: { type: info.slug },
                        //   })
                        // }
                        >
                          {ele}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              }
              className="font-thin  col-span-2"
              classes={{
                secondary: "text-14 list-item-text-primary ",
              }}
            />
            <Divider className=" bg-primary col-span-2" />
          </List>
        </div>
      </div>
    </React.Fragment>
  );
}
