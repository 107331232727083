import { Tooltip } from "@material-ui/core";
import LanguageIcon from "@mui/icons-material/Language";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../utils/translations/i18n";

export default function FadeMenu({ language, setLanguage }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const intialLanguage =
      window.sessionStorage.getItem("language") || "English";
    setLanguage(intialLanguage);
  }, []);

  useEffect(() => {
    if (window.sessionStorage.getItem("language") !== language)
      window.sessionStorage.setItem("language", language);
    if (language == "Spanish") {
      i18n.changeLanguage("es");
    } else if (language == "Portuguese") {
      i18n.changeLanguage("pt");
    } else {
      i18n.changeLanguage("en");
    }
  }, [language]);
  const handleClose = (lang) => {
    setAnchorEl(null);
    if (lang == "Spanish") {
      setLanguage("Spanish");
    } else if (lang == "Portuguese") {
      setLanguage("Portuguese");
    } else if (lang == "English") {
      setLanguage("English");
    } else {
      setLanguage(sessionStorage.getItem('language'));
    }
  };

  return (
    <div className="h-100 w-full   justify-center me-0 d-flex align-items-center">
      <Tooltip title={t("header.language")}>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className=" flex flex-col-reverse sm:flex-row justify-end sm:gap-1 p-0 sm:mb-1"
        >
          <div className="text-xs mt-1 sm:mt-0 text-fontDark dark:text-fontWhite   sm:font-bold">
            {language[0] == "E" ? "EN" : language[0] == "S" ? "SP" : "PT"}
          </div>
          <LanguageIcon className="text-fontDark dark:text-fontWhite  p-0" />
        </Button>
      </Tooltip>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            setLanguage("English");
            handleClose("English");
            window.location.reload(true);
          }}
        >
          English
        </MenuItem>
        <MenuItem
          onClick={() => {
            setLanguage("Spanish");
            handleClose("Spanish");
            window.location.reload(true);
          }}
        >
          Spanish
        </MenuItem>
        <MenuItem
          onClick={() => {
            setLanguage("Portuguese");
            handleClose("Portuguese");
            window.location.reload(true);
          }}
        >
          Portuguese
        </MenuItem>
      </Menu>
    </div>
  );
}
