import { AppBar, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormHelperText, InputLabel, makeStyles, Select, TextField, Toolbar, Typography } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { Rating } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card } from "semantic-ui-react";
import TextFieldFormsy from "../../core-components/TextFieldFormsy";
import ApiService from "../../services/apiService";
import apiService from "../../services/apiService/apiService";

const Order = (props) => {
  const useStyles = makeStyles((theme) => ({

    ul: {
      "& .MuiPaginationItem-root": {
        padding: 0,
      }
    }
  }));
  const classes = useStyles();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState();
  const history = useHistory();
  const [cancel_reason, setCancelReason] = useState('');
  const [comment, setComment] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const { t } = useTranslation();
  const [itemId, setItemId] = useState();
  const [requestId, setRequestId] = useState();
  const [error, setError] = useState('');
  //
  const reason = [
    {
      id: t("reasons.returnReasonOne"),
      name: t("reasons.returnReasonOne"),
    },
    {
      id: t("reasons.returnReasonTwo"),
      name: t("reasons.returnReasonTwo"),
    },
    {
      id: t("reasons.returnReasonThree"),
      name: t("reasons.returnReasonThree"),
    },
  ];
  const customerClassOuter =
    "text-muted d-flex pt-1 text-xs font-normal capitalize tracking-wide";
  const customerClassInner = "me-2 text-dark font-normal tracking-normal";
  useEffect(() => {

    getOrder();
  }, []);
  const getOrder = async () => {
    const order = (await apiService.getOrderById(props.match.params.orderId)).data.data[0]
      ;

    await setOrder(order);
    await setCustomer(JSON.parse(order.address));

    setLoading(false);
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 150);
    }, 500);
  };
  const renderCustomerDetails = () => {
    return (
      <div className="mt-3 flex  flex-column">
        <span className={customerClassOuter + " dark:text-fontWhite"}>
          <span className={customerClassInner + " flex-1 dark:text-fontWhite"}>
            {t("order.shippingAddress")} :
          </span>{" "}
          <span className="flex-3 dark:text-fontWhite">{customer.address}</span>
        </span>
        <span className={customerClassOuter + " "}>
          <span className={customerClassInner + " flex-1 dark:text-fontWhite"}>
            {t("order.mobile")} :
          </span>
          <span className="flex-3 dark:text-fontWhite">{customer.mobile}</span>
        </span>
        <span className={customerClassOuter + " dark:text-fontWhite"}>
          <span className={customerClassInner + " flex-1 dark:text-fontWhite"}>
            {t("order.deliveryAt")}:
          </span>{" "}
          <span className="flex-3 dark:text-fontWhite">{customer.address_type}</span>
        </span>
      </div>
    );
  };
  const handleReturnProduct = (type, id, item_id) => {
    setLoading(true)
    let formData = new FormData();
    formData.append('item_id', itemId === undefined ? item_id : itemId)
    if (type == "Requested") {
      formData.append('type', cancel_reason);
      formData.append('comment', comment);
    } else {
      formData.append('type', cancel_reason === '' ? null : cancel_reason);
      formData.append('comment', comment === '' ? null : comment);
    }
    if (id) {
      formData.append('id', id)
    } else {
      formData.append('id', requestId)
    }
    formData.append('status', type);
    ApiService.returnRequest(formData).then((res) => {
      if (res.code === 200) {
        setCancelReason('');
        setComment('');
        setLoading(false)
        getOrder();
        setOpenModal(false);
        setOpenAlert(false);
      }
      else {
        setLoading(false);
        setError(res.errors);
        setOpenAlert(false);
      }

    })
  }
  const handleChange = (e) => {
    if (e.target.name === "reason") {
      setCancelReason(e.target.value);
    } else {
      setComment(e.target.value);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setOpenAlert(false);
    setOpenRating(false);
    setFeedbackData('');
  }

  const [feedbackData, setFeedbackData] = useState({
    feedback: "",
    rating: ""
  });
  
  const handleFeedback = (e) => {
    setFeedbackData({...feedbackData, [e.target.name]: e.target.value});
  }

  const giveRating = () => {
    let form = new FormData();
    form.append("order_item_id", order?.get_item[0]?.id);
    form.append("product_id", order?.get_item[0]?.product_id);
    form.append("rating", feedbackData.rating);
    form.append("description", feedbackData.feedback);
    ApiService.addProductRating(form).then((res)=>{
      if(res.code === 200) {
        setOpenRating(false);
        window.location.reload();
      }
    })
  }
  const [rating, setRating] = useState([]);
  let userRating = rating?.data?.filter((item) => item.order_item_id === order?.get_item[0]?.id || 
    item.product_id === order?.get_item[0]?.product_id);

  useEffect(() => {
    ApiService.getCustomerReview().then(response => {
      setRating(response?.data?.data?.ratings);
    })
  }, [])

  return (
    <>
      {loading ?

        <div className="text-center ">
          <CircularProgress style={{
            width: '50px',
            height: '50px',
            margin: '100px',
            color: '#597593'
          }} />
        </div>
        : <>
          <Dialog open={openModal} onClose={handleModalClose} fullWidth maxWidth="xs">

            <AppBar
              position="static"
              className="bg-primary text-white"
              elevation={1}
            >
              <Toolbar className="flex w-full dark:bg-darkBackground">
                <Typography variant={"subtitle1"} color="inherit">
                  {t("cancelOrder.reasonForReturn")}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent className="dark:bg-lightDarkBackground" classes={{ root: 'p-14' }}>
              <DialogContentText id="alert-dialog-slide-description">

                <div className="grid grid-cols-1 gap-5  ">
                  <div>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">
                        {t("cancelOrder.reasonForReturn")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select-outlined"
                        value={cancel_reason}
                        required

                        name="reason"
                        onChange={handleChange}
                        className='dark:bg-lightDarkBackground bg-primary p-0 text-left'
                      >
                        {reason.map((ele) => (
                          <MenuItem value={ele.id} className="-my-2 text-fontDark dark:text-fontWhite dark:bg-lightDarkBackground bg-backgroundColor">{ele.name}</MenuItem>
                        ))}
                      </Select>

                      {error && <FormHelperText>{error.type}</FormHelperText>}

                    </FormControl>
                  </div>
                  <div className="">
                    <TextField
                      id="outlined-basic"
                      name="comment"
                      placeholder={t("cancelOrder.comments")}
                      type="text"
                      onChange={handleChange}
                      value={comment}
                      multiline
                      rows={5}
                      variant="outlined"
                      className='text-fontDark dark:text-button w-full'
                    />
                    {error?.comment && <FormHelperText>{error.comment}</FormHelperText>}
                  </div>
                </div>
              </DialogContentText>

            </DialogContent>
            <DialogActions className="dark:bg-lightDarkBackground">

              <Button
                variant="contained"
                className=" static bg-primary  text-white  h-10 lg:self-end m-3 mt-4"
                onClick={handleModalClose}
              >
                {t("alertComponent.cancel")}
              </Button>

              <Button
                variant="contained"
                className=" static bg-primary text-white h-10 lg:self-end m-3 mt-4"
                onClick={() => handleReturnProduct('Requested')}
              >
                {t("cancelOrder.returnCancel")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openAlert} onClose={handleModalClose}>

            <AppBar
              position="static"
              className="bg-primary text-white"
              elevation={1}
            >
              <Toolbar className="flex w-full dark:bg-darkBackground">
                <Typography variant={"subtitle1"} color="inherit">
                  {t("cancelOrder.cancelHeading")}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent className="dark:bg-lightDarkBackground" classes={{ root: 'p-14' }}>
              <Typography className="dark:text-fontWhite">{t("cancelOrder.confirmMsg")}</Typography>
            </DialogContent>
            <DialogActions className="dark:bg-lightDarkBackground">

              <Button
                variant="contained"
                className=" static bg-primary  text-white  h-10 lg:self-end m-3 mt-4"
                onClick={handleModalClose}
              >
                {t("alertComponent.cancel")}
              </Button>

              <Button
                variant="contained"
                className=" static bg-primary text-white h-10 lg:self-end m-3 mt-4"
                onClick={() => handleReturnProduct('Cancelled')}
              >
                {t("cancelOrder.returnRequestCancel")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openRating} onClose={handleModalClose}>

            <AppBar
              position="static"
              className="bg-primary text-white"
              elevation={1}
            >
              <Toolbar className="flex w-full dark:bg-darkBackground">
                <Typography variant={"subtitle1"} color="inherit">
                  {t("cancelOrder.feedback")}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent classes={{ root: 'p-14' }} className="flex flex-col justify-center dark:bg-lightDarkBackground">
              <Typography className="dark:text-fontWhite">{t("cancelOrder.feedbackMsg")}</Typography>
              <Rating
                className="-mx-1 my-3 w-max dark:text-fontWhite"
                id="simple-controlled"
                value={feedbackData.rating}
                onChange={(e)=>handleFeedback(e)}
                name="rating"
              />
              <TextFieldFormsy
                variant="filled"
                label={t("cancelOrder.feedbackComment")}
                isRating={true}
                onChange={(e)=>handleFeedback(e)}
                value={feedbackData.feedback}
                name="feedback"
                className="text-fontDark bg-primary dark:text-fontWhite"
              />
            </DialogContent>
            <DialogActions className="flex justify-center dark:bg-lightDarkBackground">

              <Button
                variant="contained"
                className=" static bg-primary  text-white  h-10 lg:self-end m-3 mt-4"
                onClick={handleModalClose}
              >
                {t("alertComponent.cancel")}
              </Button>

              <Button
                variant="contained"
                className=" static bg-primary text-white h-10 lg:self-end m-3 mt-4"
                onClick={() => giveRating()}
              >
                {t("cancelOrder.submitRating")}
              </Button>
            </DialogActions>
          </Dialog>
          <div className="w-100 z-10">
            <div className="w-100  d-flex justify-content-center font-black bg-dark">
              <span className="uppercase text-white p-4 text-3xl tracking-wider">
                {t("order.orderDetails")}:
              </span>
            </div>
          </div>
          <div className="lg:m-10 mb-10 md:m-4 p-2 px-4">

            {!loading && (
              <>
                <div className="d-flex flex-column">
                  <Chip
                    id="order_info"
                    className="p-2 d-flex h-12 lg:mt-0 mt-2 flex-column text-md font-semibold shadow-md border-4"
                    label={`${t('order.orderId')}: ${order.order_number}`}
                  ></Chip>
                  <div className="flex justify-content-end">

                    <Button
                      variant="contained"
                      className=" static  h-10 lg:self-end m-3 mt-4"
                      onClick={() => history.push("/my-orders")}
                    >
                      {t("order.backToOrder")}
                    </Button>
                  </div>
                </div>

                {order.get_item.map((item, key) => (
                  <div className="d-flex dark:bg-lightDarkBackground flex-column lg:flex-column position-relative justify-content-between border-2 my-10  p-2 rounded-2xl shadow-2xl">
                    <div className="block    " key={key}>
                      <div className="justify-content-between flex">
                        <div className="cursor-pointer"
                          onClick={() => {
                            history.push(`/product-details/${item.product_detail[0].slug}/${item.product_detail[0].id}`)
                          }}>
                          <img
                            className="bg-dark w-130 h-130 object-contain shadow-order lg:self-start self-center rounded-full border-4 border-dark"
                            src={item.product_detail[0].ImageSrc}
                          />
                        </div>
                        <div className="flex items-start">
                                 
                          {userRating?.length === 0 ?
                              item.status === "Delivered" ?
                                <Button className="hover:opacity-80 opacity-100 rounded-md bg-primary text-nowrap h-10 lg:self-start m-3 mt-4 text-light" onClick={() => { setOpenRating(true); setItemId(item.id); }}>
                                  {t("order.rating")}
                                </Button>
                                : ''
                              :
                            (<>
                            <div className="m-2.5"><Typography className="text-center">Your Rating</Typography>
                            {userRating?.map((item)=>{
                              return(
                                <>
                                  <Rating
                                  value={item.rating}
                                  readOnly />
                                </>
                              );
                            })}
                            </div></>)
                          }
                          {item.status === "Delivered" ?
                            item?.return_request === null || item?.return_request?.status === "Cancelled" ?
                              (
                                <Button
                                  onClick={() => { setOpenModal(true); setItemId(item.id); setRequestId(item.return_request?.id); }}
                                  className="hover:opacity-80 opacity-100 rounded-md bg-primary text-nowrap h-10 lg:self-start m-3 mt-4 text-light"
                                >
                                  {t("myOrders.returnOrder")}
                                </Button>
                              )
                              :
                              item?.return_request?.status === "Requested" || item?.return_request?.status === "request" ?
                                (
                                  <Button
                                    onClick={() => { setOpenAlert(true); setItemId(item.id); setRequestId(item.return_request.id); }}
                                    className="hover:opacity-80 opacity-100 rounded-md bg-primary text-nowrap h-10 lg:self-start m-3 mt-4 text-light"
                                  >
                                    {t("myOrders.returnCancelOrder")}
                                  </Button>
                                )
                                :
                                item?.return_request?.status === "Approved" ?
                                  <label className="dark:text-fontWhite mt-6">Return request is {item?.return_request?.status}</label>
                                  :
                                  <label className="dark:text-fontWhite mt-6">Return request is {item?.return_request?.status}</label>
                            : ''}

                          {item.status === "Dispatched" && (
                            order?.tracking_id !== null || order.tracking_url !== null ?
                            <>
                            <label className="dark:text-fontWhite text-base mt-6">Tracking ID : {order.tracking_id}</label>
                            <Button className="hover:opacity-80 opacity-100 rounded-md bg-primary text-nowrap h-10 lg:self-start m-3 mt-4 text-light" onClick={() => window.open(order.tracking_url, '_blank')}>
                              {t("order.trackOrder")}
                            </Button>
                            </> : ''
                          )}

                        </div>
                      </div>
                      <div
                        id="product-header"
                        className="d-flex flex-column text-primary text-2xl lg:mt-0 mt-4 font-bold px-4  lg:mx-8"
                      >
                        <span className="w-9/10 dark:text-fontWhite cursor-pointer"
                          onClick={() => {
                            history.push(`/product-details/${item.product_detail[0].slug}/${item.product_detail[0].id}`)
                          }}>
                          {item.product_detail[0].product_name} <span><b>{`(BRAND: ${item.product_detail[0].brand})`}</b></span>
                        </span>
                        <span
                          id="vendor_name"
                          className="d-flex dark:text-fontWhite tracking-widest lg:flex-row text-muted text-sm  font-normal  flex-column justify-content-between mx-1  py-1"
                        >
                        <span> {t("myOrders.soldBy")}:  <span className="text-muted text-sm uppercase font-normal font-bold">{order.get_vendor.business_name}</span></span>
                          <span
                            id="quantity"
                            className="d-flex dark:text-fontWhite tracking-widest flex-column  lg:mt-0 mt-2 text-sm text-muted"
                          >
                            <span>{t("order.qty")}: <span className="text-md font-bold mx-1">{item.quantity}</span></span>
                          </span>
                          <span

                            className="d-flex dark:text-fontWhite tracking-widest  lg:mt-0 mt-2 text-sm text-muted"
                          >
                                 <span className="text-sm "> {`${Object.keys(JSON.parse(item?.product_option))}`}</span> :  <span className="text-md font-bold mx-1">{`${Object.values(JSON.parse(item?.product_option))}`}</span>
                                </span>
                        </span>
                        <span
                          id="brand"
                          className="text-sm dark:text-fontWhite d-flex lg:flex-row flex-column justify-content-between font-normal m-1 mt-0 capitalize text-dark tracking-widest"
                        >

                          <span className="tracking-normal dark:text-fontWhite lg:mt-0 mt-2  ">
                            {t("order.status")}:{" "}
                            <span
                              className={`font-bold ${item.status === 'Cancelled' ? 'text-red-600' : ""} ${item.status == "Dispatch" ? "text-success" : ""
                                }`}
                            >
                              {item.status}
                            </span>
                          </span>
                        </span>
                        <div
                          id="payment"
                          className=" d-flex lg:flex-row flex-column justify-content-between text-base text-dark font-normal mx-1 mt-3"
                        >
                          <span
                            id="offerPrice"
                            className="text-success dark:text-fontWhite text-sm font-semibold"
                          >
                            <span className="text-dark dark:text-fontWhite font-normal">
                              {item.product_detail[0].currency_data.symbol}{" "}
                            </span>
                            {item.product_detail[0].offer_price}
                          </span>
                          <span className="text-sm dark:text-fontWhite lg:mt-0 mt-2  tracking-wide">
                            {t("order.paymentMethod")}:{" "}
                            <span
                              id="method"
                              className="text-success dark:text-fontWhite font-semibold lg:mt-0 mt-2 "
                            >
                              {order.payment_method}{" "}
                            </span>
                          </span>
                        </div>
                        <div className='flex justify-between'>
                          <div className="text-muted font-normal dark:text-fontWhite text-sm tracking-wide m-1 mt-2">
                            {t("order.orderedOn")}:{" "}
                            <span className="text-dark dark:text-fontWhite font-normal capitalize text-sm">
                              {/* {new Date(order.created_at).toDateString()} */}
                              {moment(order.created_at).format(
                                "DD-MM-yyyy"
                              )}
                            </span>
                          </div>
                          <div className="text-muted font-normal dark:text-fontWhite text-sm tracking-wide m-1 mt-2">
                            {t("cancelOrder.deliveryFee")}:{" "}
                            <span className="text-dark dark:text-fontWhite font-normal font-bold capitalize text-sm">

                              {Number(order?.delivery_charges)?.toFixed(2)}

                            </span>
                          </div>

                        </div>
                        <div className="text-muted font-normal dark:text-fontWhite text-sm tracking-wide m-1 mt-2">
                          {t("order.orderAmount")}:{" "}
                          <span className="text-dark dark:text-fontWhite font-normal font-bold capitalize text-sm">

                            {Number(order.total_price).toFixed(2)}

                          </span>
                        </div>
                        <div className="text-muted font-normal dark:text-fontWhite text-sm tracking-wide m-1 mt-2">
                          {t("order.discountAmount")}:{" "}
                          <span className="text-dark dark:text-fontWhite font-normal font-bold capitalize text-sm">
                            {Number(order.discount_price).toFixed(2)}

                          </span>
                        </div>
                        <div id="customer-details" className="mt-4 d-flex flex-column">
                          <span className="d-flex  dark:text-fontWhite align-items-center lg:flex-row flex-column justify-content-between mx-2 mt-4 text-nowrap text-dark font-normal text-sm uppercase tracking-wider">
                            {t("order.customerDetails")}
                            <Chip
                              id="transaction_id"
                              className="w-min   lg:p-2 p-0 lg:mt-0 mt-2 rounded-lg text-black text-xs font-normal opacity-90"
                              label={`${t('myOrders.transactionId')}: ${order.transaction_id}`}
                            ></Chip>
                          </span>
                          <div className="shadow-xl  w-100 text-gray-200 p-0 my-2 mx-0  ms-0 rounded-2xl">
                            <Card
                              className=" mb-0 mt-2 px-4 py-3  dark:bg-darkBackground rounded-2xl font-semibold text-primary border-2 uppercase   shadow-sm"
                              header={customer.name}
                              extra={renderCustomerDetails()}
                            ></Card>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                ))}
              </>
            )}
          </div>
        </>}
    </>
  );
};

export default Order;
