import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


export default function Cms() {
  const params = useParams();
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const useStyles = makeStyles((theme) => ({
    text: {
      "& h3": { color: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#fff" : "#050505" : themes === 'dark' ? "#fff" : "#050505" },
      "& p": { color: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#fff" : "#050505" : themes === 'dark' ? "#fff" : "#050505" }
    }
  }));
  const cmsData = useSelector(({ allCategories }) => allCategories.cmsData);
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.type]);

  return (
    cmsData.length > 0 &&
    cmsData.map(
      (info) =>
        params.type == info.slug && (
          <>
            <Helmet>
              <title>{`Nily - ${info.slug}`}</title>
              <meta name="description" content={info.slug} />
            </Helmet>
            <div
              className="bg-contain bg-no-repeat h-40vh w-80vw text-center bg-center mx-auto my-7.5"
              style={{
                backgroundImage: `url(${info.ImageSrc})`,
              }}
            ></div>
            <div className="w-80vw text-left my-7.5 mx-auto">
              <p className={classes.text}>{ReactHtmlParser(info.content)}</p>
            </div>
          </>
        )
    )
  );
}
