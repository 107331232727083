import { Card, CircularProgress } from '@material-ui/core';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ApiService from '../../services/apiService';
export default function PaymentForm(props) {
  const [form, setForm] = React.useState({ payment_method: 'Online', service: {} });
  const user_id = sessionStorage.getItem("user_id");
  const [cartData, setCartData] = React.useState([]);
  const [currency_type, setCurrencyType] = React.useState();
  const [currencyId, setCurrencyId] = React.useState();
  const [currencyCovert, setCurrencyConvert] = React.useState(false)
  const [serviceData, setServiceData] = React.useState([]);
  const [exchangeRate, setExchangerate] = React.useState();
  const [deliveryError, setDeliveryError] = React.useState(null);
  const free = {
    "service_name": "free_delivery",
    "service_type": "free_delivery",
    "currency_type": "R$",
    "service_amount": 0,
  }

  const [loading, setLoading] = React.useState(true);
  const setCheck = (e) => {
    // if (currencyId === 20) {

    //   const data = e;
    //   data.service_amount = (data.service_amount / exchangeRate).toFixed(2);
    //   setForm({ ...form, ['service']: data })
    //   if (currencyCovert === false) { props.handleReceiveProps({ ...data, service_amount: data.service_amount / exchangeRate }) } else {
    //     props.handleReceiveProps(e)
    //   }
    // } else {
    setForm({ ...form, ['service']: e })
    props.handleReceiveProps(e)
    // }
  }
  React.useEffect(() => {
    let tempArray = [];
    const _form = new FormData();
    let exchange = 0;
    ApiService.getCartDetails(user_id).then(res => {
      setCartData(res.data);
      _form.append('vendor_id', res.data[0].vendor_id)
      _form.append('user_address_id', props.data.addressId)
      // form.append('shipper_postal_code','65247')
      ApiService.getCurrencyRate().then(result => {
        result.data?.data.map((item, key) => {
          if (res.data[0].get_product_data.currency_data.symbol === item.symbol) {
            setExchangerate(item.exchange_rate);
            exchange = item.exchange_rate
          }
        })
      })
      ApiService.getDeliveryFee(_form).then(response => {
        setCurrencyType(res.data[0].get_product_data.currency_data.symbol);
        setCurrencyId(res.data[0].get_product_data.currency_data.id);
        if (response.code === 200) {
          setLoading(false);
          if (typeof response.data.data !== 'undefined') {
            setCurrencyConvert(true);

            response.data.data.map((item, key) => {
              tempArray.push({ service_name: item.name, service_amount: item.price ? item.price : 0, currency_type: res.data[0].get_product_data.currency_data.symbol, delivery_time: item.delivery_time })
            })
          } else {
            if (res.data[0].get_product_data.currency_data.id === 20) {
              response.data.map((item, key) => {
                tempArray.push({ service_type: item.serviceType, service_name: item.serviceName, service_amount: (item.ratedShipmentDetails[0].totalNetFedExCharge / exchange).toFixed(2), currency_type: res.data[0].get_product_data.currency_data.symbol })
              })
            } else {
              response.data.map((item, key) => {
                tempArray.push({ service_type: item.serviceType, service_name: item.serviceName, service_amount: item.ratedShipmentDetails[0].totalNetFedExCharge, currency_type: res.data[0].get_product_data.currency_data.symbol })
              })
            }
          }
        } else if (response.code === 400) {
          setLoading(false);
          props.handleError(true);
          setDeliveryError(response.errors)
        }

        setServiceData(tempArray);
      })
    })

  }, [])
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className='text-center lg:px-24'>
        {loading ?
          <CircularProgress className='mt-10 text-center justify-center' style={{ color: '#597593' }} />
          :
          <React.Fragment>
            <Card className='dark:bg-lightDarkBackground' >
              <Grid spacing={3} >
                <div className="mt-10 px-8 grid grid-flow-row mx-4 gap-4">
                  <RadioGroup
                    aria-label="gender"
                    defaultValue={form.payment_method}
                    name="payment_method"
                  // onChange={setCheck}
                  >

                    <div className="grid grid-cols-5">

                      <div className="col-span-4 block mx-4 my-2 ">
                        <div className="flex">
                          <CreditCardIcon className="mx-4 dark:text-fontWhite " />
                          <Typography className="dark:text-fontWhite">{t('review.online')}</Typography>
                        </div>
                        <div className="text-left mx-4">
                          <Typography className="pl-10 dark:text-fontWhite text-xs " variant="subtitle2"> {t('paymentForm.paymentOptions')}</Typography>
                        </div>
                      </div>
                      <div>
                        <FormControlLabel value="Online" className="float-right dark:text-fontWhite" control={<Radio checked={true} />} label="" />
                      </div>
                    </div>

                  </RadioGroup>
                </div>
              </Grid>
            </Card>
            {deliveryError !== null ?
              <Card className='mt-6 text-left px-12 py-4 dark:bg-lightDarkBackground'>
                <Typography className="text-red-500">{deliveryError}</Typography>
              </Card>
              :
              <Card className='mt-6 text-left px-12 py-4 dark:bg-lightDarkBackground'>
                <Typography className="dark:text-fontWhite">{t('review.deliveryServices')}</Typography>
                <RadioGroup
                  aria-label="gender"
                  defaultValue={form.service?.service_name}
                  name="service"
                  className='px-8 py-4'
                >
                  <div className="grid grid-cols-1">
                    <FormControlLabel value="free_delivery" onChange={() => setCheck(free)} disabled={cartData.length > 0 && cartData[0].free_delivery === false ? true : false} className="float-right text-fontDark dark:text-fontWhite" control={<Radio className='float-left text-left' />} label={<div>
                      <Typography className="dark:text-fontWhite">{t('review.freeDelivery')}{` (10 ${t('review.to')} 15 ${t('review.days')})`}</Typography>
                      <Typography className="dark:text-secondary text-xs text-green-600">
                        {cartData.length > 0 && cartData[0].vendor.length > 0 ?
                          cartData[0].vendor[0].min_order === null ?
                            t('review.freeDeliveryNotMessage') :
                            <>
                              {t('review.freeDeliveryMessage')} {currency_type} {cartData[0].vendor[0].min_order}
                            </>
                          : ""
                        }
                      </Typography>
                    </div>} />
                    {serviceData.map((item, key) => (
                      item.service_amount > 0 && <FormControlLabel value={item.service_name} onChange={() => setCheck(item)} key={key} className="float-right text-fontDark dark:text-fontWhite" control={<Radio className='float-left text-left' />} label={<div>
                        <Typography className="dark:text-fontWhite">{item.service_name} {`(${currency_type} ${item.service_amount})`}
                          {/* {`${item.delivery_time !== '' ? (`${item.delivery_time}`) : ''}`} */}
                        </Typography>
                      </div>} />
                    ))}
                  </div>
                </RadioGroup>
              </Card>}
          </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
}