const en = {
  translation: {
    alertComponent: {
      otherSellerProductsinCart:
        "Your cart contains products from other seller please romove the cart",
      alert: "Alert",
      removeOtherAddThis: "Remove other items and add this",
      cancel: "Cancel",
    },
    productcard: {
      addtowishlist: "Add to wishlist",
      removefromwishlist: "Remove from wishlist",
    },
    header: {
      search: "Search...",
      language: "Language",
      categories: "Categories",
      home: "Home",
      alert: "Alert",
      stockNotAvailable: " Stock Not Available....",
      ok: "Ok",
      profile: "Profile",
      myChats: "My chats",
      myFollowing: "My following",
      logout: "Log Out",
      myCart: "My cart",
      myOrders: "My orders",
      wishlist: "Wishlist",
      notifications: "Notifications",
      cart: "Cart",
      login: "Login",
      account: "Account",
    },
    paymentForm: {
      paymentOptions: "Debit Card, Credit Card,Net Banking, UPI",
      cashOnDelivery: "Cash on delivery",
    },
    checkOutForm: {
      payNow: "Pay now",
    },
    addressForm: {
      city: "City",
      close: 'Close',
      document: 'CPF',
      state: "State",
      locality: "Locality",
      shippingAddress: "Shipping address",
      address: "Address",
      addAddress: "Add New address",
      saveAddress: 'Save Address',
      editAddress: 'Edit Address',
      name: "Name",
      mobile: "Mobile",
      landmark: "Landmark",
      pinCode: "PinCode",
      addressType: "Address Type",
      searchplace: 'Search places...',
      home: "Home",
      office: "Office",
      country: "Country",
      NearByLocation: "Near By Location",
      statecode: "State Code",
      countrycode: "Country Code",
      confirmation: "Confirmation",
      confirmationMessage: "Since your business address country code has been changed, the default currency and previously added products will be set to inactive. Please confirm or cancel the request."
    },
    noFound: {},
    order: {
      shippingAddress: "Shipping Address",
      mobile: "Mobile",
      deliveryAt: "Delivery at",
      orderDetails: "Order Details",
      qty: "Qty",
      status: "Status",
      paymentMethod: "Payment Method",
      orderedOn: "Ordered on",
      customerDetails: "Customer details",
      trackOrder: "Track Order",
      backToOrder: "Back to Order",
      orderAmount: "Order Amount",
      orderId: 'Order ID',
      more: 'More',
      discountAmount: 'Order Discount Amount',
      rating: 'Give Rating',
    },

    cancelOrder: {
      reasonForCancel: "Reason for cancellation",
      reasonForReturn: "Reason for return",
      orderCancellation: "Order Cancellation",
      orderCancel: "Order cancel",
      returnCancel: "Order Return",
      cancelHeading: "Cancel Return Request?",
      returnRequestCancel: "Yes, I want to cancel",
      continue: "Continue",
      itemInfo: "Item information",
      qty: "Qty",
      deliveryFee: "Delivery Fee",
      orderDiscount: "Order Discount",
      productAmount: "Product Amount",
      price: "Price",
      comments: "Comments",
      confirmMsg: "Are you sure, You want to cancel return request for this order?",
      feedback: "Give your feedback",
      feedbackMsg: "How would you rate this product?",
      submitRating: "Submit",
      feedbackComment: "Add your review",
    },
    footer: {
      quickShop: "Quick Shop",
      informations: "Informations",
      customerService: "Customer Service",
      contactUs: "Contact Us",
      supportChat: "Support Chat",
    },
    mobileFooter: {
      home: "Home",
      category: "Category",
      new: "New",
      me: "Me",
      bag: "Bag",
      myOrders: "My orders",
      categoriesDrawer: {
        categories: "Categories",
      },
    },
    common: { addToCart: "Add to cart" },
    card: {
      button: { addToCart: "Add to Cart", productDetail: "View Details" },
    },
    shop: {
      home: "Home",
      mobileDisplay: "Mobile display",
      categoryHeading: "Categories",
      showingHeading: "Showing",
      size: "size",
      price: "Price",
      brand: "Brand",
      discount: "Discount",
      andMore: "and more",
      color: "Color",
      filter: "Filters",
      allFilter: "All Filters",
      applyAllFilters: "Apply all filters",
      clear: "Clear",
      more: "more",
      productFilters: "Product Filters",
      noFilters: "No filters",
      showingProducts: "Showing Products",
      products: "Products",
      of: "of",
      noProductFound: "No product found",
      noProducts: "No products",
    },
    productDetails: {
      all: "all",
      one: "One",
      two: "Two",
      three: "Three",
      four: "Four",
      five: "Five",
      soldBy: "Sold By",
      outOfStock: "Out of stock",
      addToWishList: "Add to Wishlist",
      viewShop: "View Shop",
      chatWithUs: "Chat With Us",
      ratings: "Ratings",
      followers: "Followers",
      products: "Products",
      productDetails: "Product Details",
      productRatingAndReviews: "Product Ratings & Reviews",
      similarProducts: "Similar Products",
      reviews: "Reviews",
      removeFromWishlist: "Remove from Wishlist",
      share: "Share",
      off: 'OFF',
      youSave: "You Save",
    },
    myOrders: {
      filters: "Filters",
      orderStatus: "Order Status",
      onTheWay: "On the Way",
      delivered: "Delivered",
      cancelled: "Cancelled",
      returned: "Returned",
      orderTime: "Order Time",
      last30Days: "Last 30 Days",
      processing: "Processing",
      older: "Older",
      soldBy: "Sold By",
      viewOrder: "View Order",
      cancelOrder: "Cancel Order",
      returnOrder: "Return Request",
      returnCancelOrder: "Cancel Return Request",
      address: "Address",
      paymentDetail: "Payment Detail",
      COd: "COD",
      transactionId: "Transaction ID ",
      myOrders: "My Orders",
      filter: "Filters",
      openMainMenu: "Open main menu",
      orderedOn: "Ordered On",
      orderAmount: "Order Amount",
      qty: "Qty",
    },
    myProfile: {
      profile: "Profile",
      alert: "Alert",
      ok: "ok",
      updateProfile: "Update",
      firstName: "First Name",
      city: "City",
      email: "Email Address",
      phone: "Phone Number",
      state: "State",
      address: "Address",
      dialing_code: "Country Code",
      contactMessage: "Contact support to change Phone Number",
      personalInfo: "Personal Information",
      accountInfo: "Account Information",
      alert: "OTP Verification",
      message: "OTP has been sent to your mobile number",
      emailMessage: "OTP has been sent to your email",
      otpError: "otp field is required",
      image:"recommended size 500x500px",
      phoneError:"phone number already exists",
      emailError:"e-mail already exists",
      changePassword: "Change Password",
      password: "Old Password",
      newPass: "New Password",
      confirmPass: "confirm Password",
      confirmPassError: "Confirm Password does not match",
    },
    providingFeatures: {
      features: {
        i: {
          heading: "Safe payment",
          subHeading: "Pay with the world's most safe payment methods",
        },
        ii: {
          heading: "Confidence",
          subHeading: "Protection covers your purchase and personal data",
        },
        iii: {
          heading: "WORLDWIDE DELIVERY",
          subHeading: "FREE & fast shipping to over 200+ countries & regions.",
        },
        iv: {
          heading: "HOTLINE",
          subHeading:
            "Talk to help line for your question on 4141 456 789, 4125 666 888",
        },
      },
    },
    cartDetails: {
      product: "Product",
      quantity: "Quantity",
      total: "Total",
      price: "Price",
      shopNow: "Shop Now",
      noItems: "No items in the cart",
      checkout: "CheckOut",
      subtotal: "Sub Total",
      discount: "Discount Order",
      shipping: "Shipping",
      yourCart: "Your Cart",
      addOnlyTenProducts: "You can only add ten products",
      action: "Action",
      ok: "ok",
      nocartitem: 'No items in cart',
      cartEmpty: "Your Cart is Empty.",
      wishlistEmpty: "Your Wishlist is Empty."
    },

    checkout: {
      alert: "Alert",
      ok: "Ok",
      checkout: "Check out",
      refresh: "Refresh",
      orderProcessing: "Your Order Payment is Processing",
      thankYouForOrder: "Thank you for order",
      orderNumberIs: "Order number is",
      transactionIdIs: "Transaction id is",
      continueShopping: "Continue shopping",
      back: "Back",
      next: "Next",
      placeOrder: "Place order",
      payNow: "Pay now",
      shippingAddress: "Shipping Address",
      paymentMethod: 'Payment Method',
      orderSummary: "Order Summary",
    },
    review: {
      to: "to",
      days: "Day's",
      minimumOrderValue: "Minimum order value is",
      maimumDiscountIS: "Maximum discount is",
      orderSummary: "Order Summary",
      productName: "Product Name",
      originalPrice: "Original Price",
      margin: "Margin",
      offerPrice: "Offer Price",
      orderAmount: "Order Amount",
      offerDiscount: "Product Discount",
      subTotal: "Sub Total",
      Qty: "Qty",
      productAmount: "Product Price",
      deliveryFee: "Delivery Fee",
      marginPrice: "Margin Price",
      orderDiscount: "Coupon Discount",
      orderTotal: "Order Total",
      youSave: "You Save",
      chooseCoupon: "Choose Coupon",
      remove: "Remove",
      applyCoupon: "Apply Coupon",
      youSaveAdditional: "You save additional",
      shipping: "Shipping Address",
      paymentMethod: "Payment Method",
      total: "Total",
      errorMessage: 'Please select valid address',
      margin: "Enter margin amount",
      message: "Calculating Shipping Cost",
      orderPlaceMessage: "Your Order is Processing...",
      paymentMessage: 'Pay With Your Card..',
      applyCoupen: "Apply Coupon Code",
      sellingMessage: 'Reselling the product?',
      marginMessage: 'click on yes to add your margin',
      yes: "Yes",
      close: 'Close',
      enterMargin: 'Enter margin amount',
      addMargin: 'Add margin',
      marginError1: 'please enter margin upto',
      marginError2: 'of the total amount',
      couponApply: 'Coupon Applied',
      valid: 'Valid till',
      totalAmount: 'Total Amount',
      paymentdetails: 'Payment Details',
      reviewOrder: 'Review your order',
      online: 'Online',
      deliveryServices: "Select Delivery Services",
      freeDeliveryMessage: "on orders above",
      freeDeliveryNotMessage: "not eligible",
      freeDelivery: 'Free Delivery',
      serviceSelectMessage: "Please Select Delivery Service",
      marginError:'Margin amount should be less than or equal to'
    },

    reasons: {
      returnReasonOne: "Defect piece.",
      returnReasonTwo: "Received wrong product.",
      returnReasonThree: "Not required.",
      cancelReasonOne: "I want to cancel due to product quality issues",
      cancelReasonTwo: "Price for the product has decreased",
      cancelReasonThree: "Expected delivery time is very long",
      cancelReasonFour: "I have purchased the product elsewhere",
      cancelReasonFive: "I want to change address for the order",
      cancelReasonSix: "I have changed my mind",
      cancelReasonSeven: "I want to convert my order to Prepaid",
      cancelReasonEight: "I want to change my phone number",
    },
    wishList: {
      addToCart: "Add to Cart",
      product: "Product",
      offerPrice: "Offer Price",
      price: "Price",
      yourWishlist: "My Wishlist",
      addOnlyTenProducts: "You can only add ten products",
    },
    attributeModal: {
      price: "Price",
      chooseOptions: "Choose Options",
      noProducts: "There are no item in wishlist",
      shopNow: "Shop now",
      addToCart: "Add to cart",
      alert: "Alert",
      ok: "Ok",
    },
    chats: {
      chats: "Chats",
      noChats: "You dont have any chats",
      noThanks: "No thanks",
      upgradeNow: "Upgrade now",
      makeAOffer: "Make a offer",
      acceptOffer: "Accept offer",
      am: "am",
      pm: "pm",
      addToCart: "Add to cart",
    },
    login: {
      loginWithPhone: "Login with phone",
      loginWithEmail: "Login with email",
      login: "Login",
      newUser: "New user ? Create an account",
      sendOtp: "Send OTP",
      signUp: "Sign Up Form",
      signUpButton: "Sign Up",
      or: "Or",
      resendOtp: "Resend the OTP",
      forgotPassword:"Forgot Password",
      enterPhone: "Enter your phone number",
      dialingCode: "dialing_code",
      alreadyAccount: "Already have an account ? Login",
      verify: "Verify",
      signUp: "Sign Up",
      enterTheOtp: "Please enter the otp",
      password: "Enter the password",
      emailPhone: "Email/Mobile Number",
      email: "Enter the email",
      name: "Enter your full name",
      codeError: 'please select country dialing code',
      phoneError: 'please enter valid mobile number',
      termsError:"please accept terms and conditions. ",
      termCondition: "I accept all the terms and conditions.",
      nameError:"name field is required",
      forgotMessage:"Please enter your registered phone and we will sent you an OTP",
    },
    pageNotFound: {
      oops: "    oops! page not found",
      unAvailable:
        "    The page you are trying to access might have been removed, changed, or is unavailable.",
      goToHome: "   Go to home page",
      tryExploring: "    Try Exploring",
    },
    vendorDetails: {
      myFollowing: "My Following",
      vendorDetails: "Vendor Details",
    },
    brands: {
      allbrands: "Shop By Brands",
    },
    profileMenu: {
      profile: "Profile",
      myBankDetails: "My Bank Details",
      myEarnings: "My Earnings",
      myReviews: "My Reviews",
      deleteAccount: "Delete Account",
      myChats: "My Chats",
      myOrders: "My Orders",
      myCart: "My Cart",
      myFollowing: "My Following",
      manageAddress: "Manage Address",
      logout: "Logout",
    },
    deletePopup: {
      confirmToDelete: "Confirm",
      delAccount: "Delete Account",
      delMsg: "By deleting your account the following information will be deleted and cannot be undone",
      msgOne: "Personal Details",
      msgTwo: "Seller Profile (if available)",
      msgThree: "Personal Documents",
      msgFour: "Addresses",
      msgFive: "Bank Details",
      delConfirm: "Please click on confirm to delete your account now?",
    },
    reviewTr: {
      title: "My Review",
      titleEarn: "My Earnings",
      viewRec: "View Receipt",
      TransactionId: "transaction Id",
    },
    chatPopUp: {
      actualPrice: "Actual Price",
      makeAOffer: "Make a Offer",
      am: "am",
      pm: "pm",
      acceptOffer: "Accept offer",
      send: "Send",
      addToCart: "Add to cart",
    },
    sortForm: {
      newArrivals: "New Arrivals",
      mostPopular: "Most Popular",
      priceLowToHigh: "price(low to high)",
      priceHighToLow: "price(high to low",
      ratingHighToLow: "Ratings(High to Low)",
      sort: 'Sort',
    },
    myFollowing: {
      ratings: "Ratings",
      followings: "Followings",
      products: "Products",
      allProducts: "All products",
      showing: "Showing",
      follow: "Follow",
      following: "Following",
    },
    bankDetails: {
      title: "Bank Details",
      bankName: "Bank Name",
      accountNumber: "Account Number",
      confirmAccountNumber: "Confirm Account Number",
      swiftCode: "SWIFT CODE / ROUTING Number",
      accountHolderName: "Account Holder Name",
      bankCode: "Bank Code",
      branchCode: "Branch Code",
      accountType: "Account Type",
      submit: "Submit",
      bankNameError: "Bank name is required.",
      NameError: "Account holder name is required.",
      AccountError: "Account number is required.",
      accountError: "Account number should be more than 6.",
      confirmAccountError: "Confirm Account number is required.",
      ifscError: "Swift code / Routing Number is required.",
      bankCodeError: "Bank Code is required.",
      branchCodeError: "Branch Code is required.",
      accountTypeError: "Account Type is required.",
    },
    home: {
      recentProducts: "Fresh Arrivals",
      messageText: "Type your message",
      button: { viewAll: "View All" },
      categories: { title: "Categories" },
      brand: { title: "Shop by Brands" },
      fCollection: { title: "Featured Products" },
      flashSale: { title: "Flash Sale", left: "Left" },
      trProducts: { title: "Trending Products" },
      topProducts: { title: "Top Products" },
      nearbySellers: { title: "Nearby Sellers" },
      providingFeatures: {
        i: {
          main: "Free Shipping & Return",
          secondary: "Free shipping on all US orders",
        },
        ii: {
          main: "Money Guarantee",
          secondary: "30 days money back guarantee",
        },
        iii: {
          main: "Online Support",
          secondary: "We support online 24/7 on day",
        },
        iv: {
          main: "Secure Payments",
          secondary: "All payment are Secured and trusted",
        },
      },
    },
  },
};
export default en;