import { AccordionDetails, makeStyles } from "@material-ui/core";
import GirlIcon from "@mui/icons-material/Girl";
import HomeIcon from "@mui/icons-material/Home";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Accordion } from "semantic-ui-react";
import AppConfig from "../../../appConfig";
import FadeMenu from "../../../core-components/FadeMenu";

function Footer() {
  const [language, setLanguage] = React.useState("English");
  const user_id = sessionStorage.getItem("user_id");
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const type = {
    id: localStorage.getItem("_typeid"),
    slug: localStorage.getItem("_typeslug"),
    shop: localStorage.getItem("_typeshop"),
  };
  const { t } = useTranslation();

  const [subCategory, setSubCategory] = useState(false);
  const [childCategory, setChildCategory] = useState();
  const [childId, setChildId] = useState();
  const openSubCategories = (id) => {
    const child = fields.filter((item) => { return item.id === id });
    setChildId(child[0].id);
    setChildCategory(child[0]);
    setSubCategory(true);
    setSubChild(false);
    setLastChildMenu(false);
  }

  const [singleChildData, setSingleChildData] = useState();
  const [singleChildId, setSingleChildId] = useState();
  const [subChild, setSubChild] = useState(false);
  const openChilds = (id) =>{
    const childs = childCategory.childrens.map((item) => { return item });
    const subChilds = childs.filter((e)=>{ return ( e.id === id && e.childrens)});
    const singleChild = subChilds.map((item)=>{return item });
    setSingleChildData(singleChild);
    setSingleChildId(singleChild[0].id);
    setSubChild(true);
  }

  const [lastChildId, setLastChildId] = useState();
  const [lastChildMenu, setLastChildMenu] = useState();
  const handleOpenChilds = () =>{
    setLastChildId(singleChildData[0].id);
    setLastChildMenu(true);
  }

  const useStyles = makeStyles((theme) => ({
    header: {
      boxShadow: "none",
    },
    panel: {
      boxShadow: "none",
    },
    expanded: {
      boxShadow: "none",
    },
    root: {
      width: 300,
    },
    margin: {
      height: theme.spacing(3),
    },
    thumb: {
      background: "white",
      border: "2px solid black",
      "&~&": {
        background: "white",
        border: "2px solid black",
      },
    },
    mark: {
      background: "black",
    },
    rail: {
      background: "black",
    },
    track: {
      background: "black",
      height: "3px",
    },
    valueLabel: {
      "&>*": {
        background: "black",
      },
    },
  }));

  // getting categories data from redux
  const fields = useSelector(({ allCategories }) => allCategories.categories);
  const classes = useStyles();

  // rendering category list
  const renderTopLevelList = (parent) => {
    return parent?.childrens?.map((ele) => {
      return (
        <React.Fragment>
          <div className="flex-1 w-full ">
            <ListItem
              button
              className={classes.menuItem}
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                  state: {
                    type: "Categories",
                    id: ele.id,
                  },
                });
              }}
            >
              <h5 className="font-sans px-2 text-black hover:text-dark">
                {ele.name}
              </h5>
            </ListItem>
          </div>
        </React.Fragment>
      );
    });
  };

  // for rendering second dropdown
  const renderMiddleLevelList = () => {
    const allchildrens = fields.map((par) => par.childrens);
    const target = []
      .concat(...allchildrens)
      .find((child) => child.id == type.id);
    if (target?.childrens.length > 0) {
      return target?.childrens?.map((ele) => {
        return (
          <React.Fragment>
            <div className="flex-1 w-full px-4">
              <ListItem
                button
                className={classes.menuItem}
                onClick={() => {
                  history.push({
                    pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                    state: {
                      type: "Categories",
                      id: ele.id,
                    },
                  });
                }}
              >
                <h5 className="font-sans px-2 text-black hover:text-dark">
                  {ele.name}
                </h5>
              </ListItem>
            </div>
          </React.Fragment>
        );
      });
    }
    if (!target?.childrens?.length) {
      const everyChildrenList = []
        .concat(...allchildrens)
        .map((child) => child.childrens);
      const everyChildren = [].concat(...everyChildrenList);
      const subtarget = everyChildren.find((sub) => sub?.id == type.id);
      let sublist = everyChildren.filter(
        (c) => c?.parent_id == subtarget?.parent_id
      );
      const parentsub = []
        .concat(...allchildrens)
        .find((e) => e?.id == subtarget?.parent_id);
      const topparentsub = fields.find((e) => e?.id == parentsub?.parent_id);
      return (
        parentsub &&
        topparentsub && (
          <>
            <div
              className="text-black cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${topparentsub?.slug}/${topparentsub?.id}`,
                  state: {
                    type: "Categories",
                    id: topparentsub?.id,
                  },
                });
              }}
            >
              {topparentsub.name}
            </div>
            <div
              className="text-black px-4 py-2 cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${parentsub?.slug}/${parentsub?.id}`,
                  state: {
                    type: "Categories",
                    id: parentsub?.id,
                  },
                });
              }}
            >
              {parentsub.name}
            </div>
            {sublist.map((ele) => {
              return (
                <React.Fragment>
                  <div className="flex-1 w-full px-4">
                    <ListItem
                      button
                      className={classes.menuItem}
                      onClick={() => {
                        history.push({
                          pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                          state: {
                            type: "Categories",
                            id: ele.id,
                          },
                        });
                      }}
                    >
                      <h5 className="font-sans px-2 text-black hover:text-dark">
                        {ele.name}
                      </h5>
                    </ListItem>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        )
      );
    }
  };

  // renderOptions is our main dropdown
  const renderOptions = () => {
    let parent;
    parent = fields.find((par) => par.id == type.id);
    if (parent) return renderTopLevelList(parent);
    else return renderMiddleLevelList();
  };

  const renderMenu = (
    <React.Fragment>
      {/* Helmet is used for seo optimization  */}
      <Helmet>
        <title>{`Nily - ${type.slug}`}</title>
        <meta name="description" content="Categories" />
        <meta property="og:site_name" content="Nily" />
        <meta property="og:title" content={type.slug} />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content={type.shop} />
        {/* <meta property="og:image" content={data?.ImageSrc}></meta> */}
      </Helmet>
      {fields.length > 0 && type?.id && (
        <div className="py-2 mb-4">
          <Accordion className="border-0 shadow-none" defaultExpanded>
            <AccordionDetails className="">
              <div className="flex-1 w-full text-black">
                {/* {getParentName()} */}
                <List component="flex-1" disablePadding>
                  {fields.length > 0 && renderOptions()}
                </List>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </React.Fragment>
  );
  function getParentName() {
    let p;
    p = fields.find((par) => par.id == type.id);
    if (p?.name)
      return (
        <div
          className="text-secondary text-2xl font-bold text-center mb-4 cursor-pointer"
          onClick={() => {
            history.push({
              pathname: `/shop/category-product/${p.slug}/${p.id}`,
              state: {
                id: p.id,
                type: "Categories",
              },
            });
          }}
        >
          {p.name}
        </div>
      );
    else {
      const allchildrens = fields.map((par) => par.childrens);
      let parentName;
      const target = []
        .concat(...allchildrens)
        .find((child) => child.id == type.id); //flatten array
      if (target?.name) {
        let p;
        p = fields.find((par) => par.id === target.parent_id);

        return (
          <div className="text-black d-flex flex-column">
            <div
              className="cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${p.slug}/${p.id}`,
                  state: {
                    id: p.id,
                    type: "Categories",
                  },
                });
              }}
            >
              {p.name || null}
            </div>
            <div
              className="px-4 py-2 cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${target.slug}/${target.id}`,
                  state: {
                    id: target.id,
                    type: "Categories",
                  },
                });
              }}
            >
              {target.name}
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
  }

  const toggleDrawer = (opentoggle) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(opentoggle);
  };
  const list = () => (
    <Box
      className="bg-gray-200"
      sx={{ width: 250, height: '100%' }}
      role="presentation"
    // onClick={toggleDrawer(false)}
    // onKeyDown={toggleDrawer(false)}
    >
      <div className="text-xl p-4 font-bold w-full text-center uppercase text-primary tracking-widest">
        {t("mobileFooter.categoriesDrawer.categories")}
      </div>
      <List>
        {fields.map((item) => {
          return (
            <>
              <div className="flex justify-between items-center">
                <Box
                  className="bg-gray-200"
                  sx={{ width: 250, height: '100%' }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <ListItem
                    className=" text-black hover:bg-white hover:text-primary bg-gray-200 text-base"
                    onClick={() => {
                      toggleDrawer(false);
                      history.push({
                        pathname: `/shop/category-product/${item.slug}/${item.id}`,
                        state: {
                          type: "Categories",
                          id: item.id,
                          name: item.name,
                        },
                      })
                    }
                    }
                  >
                    {item.name}
                  </ListItem>
                </Box>
                {item.childrens.length !== 0 && (subCategory && childId === item.id ? <span className="text-xl w-6"
                  onClick={() => setSubCategory(false)}>&#8722;</span> :
                  <span className="text-xl w-6"
                    onClick={() => openSubCategories(item.id)}>&#43;</span>)}
              </div>
              {subCategory && childId === item.id && <div className="grid-flow-row w-full">
                {item.childrens.map((item, index) => {
                  return(
                    <>
                      <div className="flex bg-gray-100 px-4">
                    <Box
                      className=""
                      sx={{ width: 250, height: '100%' }}
                      role="presentation"
                      onClick={toggleDrawer(false)}
                      onKeyDown={toggleDrawer(false)}
                    >
                     <p className="py-2 flex justify-between items-center"
                            onClick={() => {
                              toggleDrawer(false);
                              history.push({
                                pathname: `/shop/category-product/${item.slug}/${item.id}`,
                                state: {
                                  type: "Categories",
                                  id: item.id,
                                  name: item.name,
                                },
                              })
                            }
                            }>{item.name}</p>
                      </Box> 
                        {item.childrens.length !== 0 && (subChild && singleChildId === item.id ?
                        <span className="text-xl w-6"
                            onClick={() => setSubChild(false)}>&#8722;</span> :
                         <span className="text-xl w-6"
                        onClick={() => openChilds(item.id)}>&#43;</span>)}
                      </div>
                      {subChild && singleChildId === item.id && <div className="grid-flow-col w-full bg-gray-50">
                        {item.childrens.map((child)=>{
                          return (
                            <>
                            <div className="flex px-7 py-2">
                              <Box
                                className=""
                                sx={{ width: 250, height: '100%' }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                              >
                                  <p className="p-1" onClick={() => {
                                    toggleDrawer(false);
                                    history.push({
                                      pathname: `/shop/category-product/${child.slug}/${child.id}`,
                                      state: {
                                        type: "Categories",
                                        id: child.id,
                                        name: child.name,
                                      },
                                    })
                                  }
                                  }>{child.name}</p>
                              </Box> 
                                {child.childrens.length !== 0 && (lastChildMenu && lastChildId === item.id ?
                                <span className="text-xl w-6"
                                  onClick={() => setLastChildMenu(false)}>&#8722;</span> :
                                <span className="text-xl w-6"
                                  onClick={() => handleOpenChilds(item.id)}>&#43;</span>)}
                              </div>
                              {lastChildMenu && lastChildId === item.id && <div className="grid-flow-col w-full bg-whiteBackground">
                                {child.childrens.map((res)=>{
                                  return (
                                    <Box
                                      className="py-2 px-10"
                                      sx={{ width: 'max-content', height: '100%' }}
                                      role="presentation"
                                      onClick={toggleDrawer(false)}
                                      onKeyDown={toggleDrawer(false)}
                                    >
                                    <p className="p-1" onClick={() => {
                                      toggleDrawer(false);
                                      history.push({
                                        pathname: `/shop/category-product/${res.slug}/${res.id}`,
                                        state: {
                                          type: "Categories",
                                          id: res.id,
                                          name: res.name,
                                        },
                                      })
                                    }
                                    }>{res.name}</p>
                                    </Box>
                                  )
                                })}
                              </div>}
                            </>
                          )
                        })}
                      </div>}
                    </>
                    )
                })}
              </div>}
            </>
          );
        })}
      </List>
      {/* <Divider />
      {fields.length > 0 && (
        <div className="grid-flow-row w-60">{renderMenu}</div>
      )} */}
    </Box>
  );
  return (
    <div className="d-flex justify-around dark:bg-darkBackground py-2 items-center shadow-footer w-100 bg-white opacity-95 z-10 fixed bottom-0">
      <div
        className="text-center flex-1"
        onClick={() => {
          history.push(AppConfig.routes.home);
        }}
      >
        <HomeIcon className="dark:text-fontWhite" />
        <p className="dark:text-fontWhite">{t("mobileFooter.home")}</p>
      </div>
      <div onClick={toggleDrawer(true)} className="text-center flex-1">
        <ManageSearchIcon className="dark:text-fontWhite " />
        <p className="dark:text-fontWhite">{t("mobileFooter.category")}</p>
      </div>
      <div className="text-center flex-1">
        <GirlIcon className="dark:text-fontWhite" />
        <p className="dark:text-fontWhite"
          onClick={() => {
            if (user_id !== null && user_id !== "null")
              history.push("/my-orders");
            else {
              history.push(AppConfig.routes.login);
            }
          }}
        >
          {t("mobileFooter.myOrders")}
        </p>
      </div>
      <div className="text-center flex-1">
        <span className="cursor-pointer dark:text-fontWhite">
          <FadeMenu language={language} setLanguage={setLanguage} />
        </span>
      </div>
      <div
        className="text-center flex-1"
        onClick={() => {
          if (user_id !== null && user_id !== "null")
            history.push(AppConfig.routes.profile);
          else history.push(AppConfig.routes.login);
        }}
      >
        <PermIdentityIcon className="dark:text-fontWhite" />
        <p className="dark:text-fontWhite">{t("mobileFooter.me")}</p>
      </div>
      <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}

export default Footer;
