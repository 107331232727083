import React from "react";
import Skeleton from "react-loading-skeleton";
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
export default function Banner(props) {
  const NavigateToNextPage = (img) => {
    img.category_id !== null && (
      <>
        {history.push({
          pathname: `/shop/category-product/${img.categories_data.slug}/${img.category_id}`,
          state: {
            id: img.category_id,
            type: "category-product",
          },
        })}
      </>
    );
    img.service_id !== null && (
      <>
        {history.push({
          pathname: `/product-details/${img.product_data.slug}/${img.service_id}`,
          state: {
            id: img.service_id,
            type: "Featured Collection",
          },
        })}
      </>
    );
    img.link !== null && <>{(window.location.href = `${img.link}`)}</>;
  };
  const history = useHistory();
  return (
    <React.Fragment>

      {props.loading ?
        <Skeleton height={350} width={'100%'} />
        :
        props?.layout?.image_type === "Slider" ? (
          <div className="w-full items-center content-start my-4 justify-between">
            <div
              className={`grid w-full mx-0 px-0 sm:px-10 grid-${props.layout.class} ${props.layout.span} mx-0`}
            >
              <Carousel
                infiniteLoop
                useKeyboardArrows
                autoPlay
                showStatus={false}
                stopOnHover={false}
                showThumbs={false}
                showIndicators={false}
                swipeable={!props.mobile}
              >
                {props?.layout?.banner_layout?.structure.map((img, key) => (
                  <div
                    key={key}
                    onClick={() => NavigateToNextPage(img)}
                    className="cursor-pointer w-full h-full"
                  >
                    {<img className="w-full h-auto" src={img.url} />}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        ) : (
          <div className="relative w-full items-center content-start my-4 justify-between ">
            <div
              className={`grid  w-full mx-0 px-0 sm:px-10 grid-${props.layout?.banner_layout?.class} ${props.layout?.banner_layout?.span}`}
            >
              <Carousel
                infiniteLoop
                useKeyboardArrows
                autoPlay
                showStatus={false}
                stopOnHover={false}
                showThumbs={false}
                showIndicators={false}
              >
                {props?.layout?.banner_layout?.structure.map((item, key) => (
                  <React.Fragment key={key}>
                    <div
                      onClick={() => {
                        NavigateToNextPage(item);
                      }}
                      className={`grid grid-${item.class} mx-0 ${item.span} cursor-pointer `}
                    >
                      <div className="w-full h-full">
                        <img src={item.url} className="w-full h-auto" />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </Carousel>
            </div>
          </div>
        )}
    </React.Fragment>
  );
}
