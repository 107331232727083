import {
  AppBar, Button, Divider,
  IconButton, InputLabel, makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { LightMode, ModeNight } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useColorTheme from "use-color-theme";
import AppConfig from "../../../appConfig";
import logo from "../../../assets/images/NewLogo.png";
import FadeMenu from "../../../core-components/FadeMenu";
import CartDetails from '../../../Pages/CartDetails';
import { ActionTypes } from "../../../redux/contants/ActionType";
import store from "../../../redux/store";
import ApiService from "../../../services/apiService";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #d2cdcd",
  backgroundColor: "white",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(1),
    marginRight: "-1.5em",
    marginTop: "-0.5em",
    width: "auto",
    transform: "scale(0.75)",
  },
}));
// const id = sessionStorage.getItem("session_id");
const user_id = sessionStorage.getItem("user_id");
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    color: "white",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "28ch",
      justifyContent: "center",
      "&:focus": {
        width: "20ch",
        color: "red",

      },
    },
  },
  // '& .MuiInputBase-input': {
  '& .MuiInputBase-input::placeholder': {
    // textOverflow: 'ellipsis !important',  
    filter: "brightness(500%)",
    opacity: "10"
  }
  // }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: AppConfig.color.button,
    color: "white",
    width: "14px",
    height: "14px",
    fontSize: 3,
  },
}));
function Header2(props) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const colorTheme = useColorTheme(false);
  const dispatch = useDispatch();
  const [searchedData, setSearchedData] = React.useState();
  const [menuPosition, setMenuPosition] = React.useState(false);
  const [language, setLanguage] = React.useState("English");

  const field = useSelector(({ allCategories }) => allCategories.categories);

  const user = useSelector(({ allCategories }) => allCategories.user);
  const classes = styles();
  const [childData, setChildData] = React.useState([]);
  const handleItemClick = (event) => {
    setMenuPosition(!menuPosition);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // logout function
  const setLogout = () => {
    setAnchorElProfile(null);
    dispatch({ type: ActionTypes.ADD_USER_DATA, payload: "" });
    // setting user id and access token to null
    sessionStorage.setItem("user_id", null);
    sessionStorage.setItem("access_token", null);
    localStorage.setItem("access_token", null);
    history.push("/");
    window.location.reload();
  };

  // handling change in searchbox
  const handleChangeInSearchBox = (e) => {
    setSearchedData(e.target.value);
  };

  // start searching function
  const startSearching = (e) => {
    if (e.code == "Enter") {
      const form = new FormData();

      form.append("search", searchedData);
      history.push({
        pathname: `/shop/search/product/${searchedData}`,
        state: { searchedData: searchedData, type: "search" },
      });
    }
  };
  const [notification, setNotification] = React.useState();

  // getting notification count redux store
  const notificationCount = useSelector(
    ({ allCategories }) => allCategories.notificationCount
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();
  const data = useSelector(({ allCategories }) => allCategories.cartItem);
  const temp = useSelector(({ allCategories }) => allCategories);
  const wishlistData = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElprofile, setAnchorElProfile] = React.useState(null);
  const open = Boolean(anchorEl);
  const [cartOverviewOpen, setCartOverviewOpen] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(0)
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);
  const openNotification = Boolean(anchorElNotification);
  const profileOpen = Boolean(anchorElprofile);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // when we click on notification this function will get Called
  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);

    // calling readNotifications api to set unread_count to 0
    ApiService.readNotifications().then((res) => { });
  };
  const overWriteCss = () => {
    // over writing mui placeholder class 
    const inputElement = document.querySelectorAll('.MuiInputBase-input')
    const inputPlaceHolderArray = [...inputElement]
    inputPlaceHolderArray.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-fontDark')
    })
    // using set time out so that the component on which we are tring to add class gets render first because we can only add class to component which is present in dom 
    setTimeout(() => {
      // over writing menu class 
      const profileMenuElement = document.querySelectorAll('.css-6hp17o-MuiList-root-MuiMenu-list')
      const profileMenuElementArray = [...profileMenuElement]

      profileMenuElementArray.forEach((ele) => {
        ele.classList.add('dark:bg-darkBackground')
      })
      // menu css for live 
      const profileMenuElementForLive = document.querySelectorAll('.css-r8u8y9')
      const profileMenuElementForLiveArray = [...profileMenuElementForLive]

      profileMenuElementForLiveArray.forEach((ele) => {
        ele.classList.add('dark:bg-darkBackground')
      })

    }, 0);

  }
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);



  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
    setAnchorElSub(null);
  };
  const totalCart = useSelector(({ allCategories }) => allCategories.cartCount);
  const cmsData = useSelector(({ allCategories }) => allCategories.cmsData);
  const [id, setId] = React.useState();
  let [total, setTotal] = React.useState(0);
  const [finalTotal, setFinalTotal] = React.useState(0);
  const [anchorElSub, setAnchorElSub] = React.useState(null);
  const [anchorElCat, setAnchorElCat] = React.useState(null);
  const [anchorElChild, setAnchorElChild] = React.useState({
    anchorElChild: null,
    id: null,
  });
  const [anchorElSubChild, setAnchorElSubChild] = React.useState({
    anchorElSubChild: null,
    id: null,
  });
  const [anchorElLastSubChild, setAnchorElLastSubChild] = React.useState({
    anchorElLastSubChild: null,
    id: null,
  });
  const openCat = Boolean(anchorElCat);
  const session_id = sessionStorage.getItem("session_id");

  const handleClickCat = (event) => {
    setAnchorElCat(event.currentTarget);
  };
  const handleCloseCat = () => {
    setAnchorElCat(null);
    setAnchorElChild({ anchorElChild: null, id: null });
    setAnchorElSubChild({ anchorElSubChild: null, id: null });
    setAnchorElLastSubChild({ anchorElLastSubChild: null, id: null });
  };
  const getUserDetails = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      window.sessionStorage.setItem("location_lat", position.coords.latitude);
      window.sessionStorage.setItem("location_long", position.coords.longitude);
    });
  };
  const [showDeletePopup, setDeletePopup] = useState(false);
  const handleCloseDelete = () => {
    setDeletePopup(false);
  }
  const accountDelete = () => {
    ApiService.DeleteAccount().then(response => {
      console.log(response);
      setAnchorElProfile(null);
      sessionStorage.setItem("user_id", null);
      sessionStorage.setItem("access_token", null);
      localStorage.setItem("access_token", null);
      history.push("/");
      window.location.reload();
    })
  }
  React.useEffect(() => {
    // Below function will set user location in session storage after which we can get near by sellers
    getUserDetails();
    const _form = new FormData();

    _form.append(
      "session_id",
      user_id !== "null" && user_id !== null ? user_id : session_id
    );

    data[0]?.slice(0).forEach((ele) => {
      total = total + parseInt(ele.offer_price);
    });
    setFinalTotal(total);
    const id = new FormData();
    id.append("session_id", user_id !== "null" ? user_id : session_id);
    // Now we will be setting home page data in redux like notifications,categories,cartCount,wishlist count

    ApiService.getCategories().then((res) => {
      if (res.code === 200) {
        dispatch({
          type: ActionTypes.ADD_CATEGORIES,
          payload: res.data.data,
        });
      }
    });

    const form1 = new FormData();
    form1.append("session_id", user_id !== null ? user_id : session_id);
    if (
      (session_id !== "null" && session_id !== null) ||
      (user_id !== "null" && user_id !== null)
    ) {
      // get cart details and set cart count equal to length of the cart array
      pathname != "/cart-details" &&
        ApiService.getCartDetails(form1).then((res) => {
          if (res.code === 200) {
            store.dispatch({
              type: ActionTypes.ADD_USER_CART_COUNT,
              payload: res.data.length,
            });
          }
        });
    }

    // get cms data
    ApiService.getCmsData(_form).then((res) => {
      if (res.code === 200) {
        store.dispatch({
          type: ActionTypes.ADD_CMS,
          payload: res.data.data.cms,
        });
      }
    });

    if (typeof user_id != undefined && user_id !== null && user_id !== "null") {
      // get notification count only if user has logged in
      ApiService.getNotifications().then((res) => {
        setNotification(res.data?.data?.my_notifications.data);
        store.dispatch({
          type: ActionTypes.ADD_USER_Notification_COUNT,
          payload: res.data?.data?.unread_count,
        });
      });
    }
    // get wishlist count only if user has logged in
    if (user_id !== null && user_id !== "null") {
      pathname != "/wishlist" &&
        ApiService.getWishlistDetails(user_id).then((res) => {
          if (res.code === 200) {

            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: res.data.data.products.data.length,
            });

          }
        });
    }


  }, [user_id]);

  const [openModal, setOpenModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const renderChild = (ele) =>
    ele.childrens.map((child, key) => (
      <React.Fragment>
        <div className="flex">
          <span
            className="cursor-pointer hover:text-md hover:text-black text-sm"
            onClick={(e) => {
              history.push({
                pathname: `/shop/category-product/${child.slug}/${child.id}`,
                state: { id: child.id, type: "Categories" },
              });
              setAnchorElCat(null);
            }}
          >
            {child.name}
          </span>
        </div>
      </React.Fragment>
    ));

  const renderMenu = isOpen ? (
    <React.Fragment>
      {typeof childData !== "undefined" && childData.childrens.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorElCat}
          open={openCat}
          onClose={handleCloseCat}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="w-full bg-none ml-5 mt-0.5 mr-auto pl-2 "
        >
          <div className="grid grid-flow-row mx-2 text-justify break-words bg-none">
            {childData.childrens.slice(0).map((ele, key) => (
              <MenuItem
                className="w-90vw whitespace-normal break-normal bg-none"
                key={key}
              >
                <div className="block  w-100">
                  <div
                    onClick={(e) => {
                      history.push({
                        pathname: `/shop/category-product/${ele.slug}${ele.id}`,
                        state: {
                          id: ele.id,
                          type: "Categories",
                        },
                      });
                      setAnchorElCat(null);
                    }}
                  >
                    <h6 className="fw-bold text-button">{ele.name}</h6>
                  </div>
                  <div className=" pt-2">
                    {ele.childrens.length > 0 &&
                      ele.childrens.map((subChild, index) => (
                        <div
                          onClick={(e) => {
                            history.push({
                              pathname: `/shop/category-product/${subChild.slug}/${subChild.id}`,
                              state: {
                                id: subChild.id,
                                type: "Categories",
                              },
                            });
                            setAnchorElCat(null);
                          }}
                        >
                          <span className="text-sm text-button">
                            {subChild.name}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </MenuItem>
            ))}
          </div>
        </Menu>
      )}
    </React.Fragment>
  ) : (
    ""
  );
  const renderHeading = (
    <React.Fragment>
      <span
        className="px-3 py-2 text-sm font-medium uppercase rounded-md cursor-pointer "
        onClick={() => history.push(AppConfig.routes.home)}
      >
        {t("header.home")}
      </span>
      {isOpen ? (
        <span
          className={`px-3 py-2 text-sm font-medium uppercase cursor-pointer hover:cursor-pointer ${openCat ? "border-white border-b-4" : "border-b-0"
            }`}
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={openCat ? "true" : undefined}
          onClick={handleClickCat}

        // onMouseLeave={handleCloseCat}
        >
          {t("header.categories")}
        </span>
      ) : (
        <span
          className={`px-3 py-2 text-sm font-medium uppercase cursor-pointer hover:cursor-pointer ${openCat ? "border-white border-b-4" : "border-b-0"
            }`}
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={openCat ? "true" : undefined}
          onMouseEnter={handleClickCat}
        >
          {t("header.categories")}
        </span>
      )}
      {renderMenu}
    </React.Fragment>
  );

  return (
    props.visible && (
      <React.Fragment>
        <Dialog
          open={openModal}
          onClose={handleModalClose}
          fullWidth
          maxWidth="sm"
        >
          <AppBar
            className="text-white bg-headerappbar"
            position="static"
            elevation={1}
          >
            <Toolbar className="flex w-full">
              <Typography variant={"subtitle1"} color="inherit">
                {t("header.alert")}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent classes={{ root: "p-20" }}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="p-4"
            >
              ({t("header.stockNotAvailable")})
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="normal-case whitespace-no-wrap px-4 py-2 bg-black text-white"
              variant="contained"
              onClick={handleModalClose}
              type="button"
            >
              {t("header.ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showDeletePopup} onClose={handleCloseDelete}>

          <AppBar
            position="static"
            className="bg-primary text-white"
            elevation={1}
          >
            <Toolbar className="flex w-full dark:bg-darkBackground">
              <Typography variant={"subtitle1"} color="inherit">
                {t("deletePopup.delAccount")} *
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent className="dark:bg-lightDarkBackground" classes={{ root: 'p-14' }}>
            <Typography className="leading-normal dark:text-fontWhite">{t("deletePopup.delMsg")} - </Typography>
            <Typography className="leading-normal dark:text-fontWhite">1. {t("deletePopup.msgOne")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">2. {t("deletePopup.msgTwo")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">3. {t("deletePopup.msgThree")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">4. {t("deletePopup.msgFour")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">5. {t("deletePopup.msgFive")}</Typography>
            <Typography className="leading-normal dark:text-fontWhite">{t("deletePopup.delConfirm")}</Typography>
          </DialogContent>
          <DialogActions className="flex justify-center dark:bg-lightDarkBackground">

            <Button
              variant="contained"
              className=" static bg-primary  text-white  h-10 lg:self-end m-3 mt-4"
              onClick={handleCloseDelete}
            >
              {t("alertComponent.cancel")}
            </Button>

            <Button
              variant="contained"
              className=" static bg-primary text-white h-10 lg:self-end m-3 mt-4"
              onClick={() => {
                accountDelete();
                setDeletePopup(false);
              }}
            >
              {t("deletePopup.confirmToDelete")}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="fixed bg-background w-full shadow-xl z-50">
          <nav className=" ">
            <div className="flex w-full  bg-whiteBackground dark:bg-darkBackground items-center lg:h-22 sm:h-20">
              <div className="flex-none w-auto">
                <img
                  src={logo}
                  onClick={() => history.push(AppConfig.routes.home)}
                  alt=""
                  className="ml-6 p-2 sm:-mt-2 lg:mt-0 cursor-pointer lg:w-22 lg:h-22 sm:w-20 sm:h-20"
                />
              </div>
              <div className=" flex flex-col w-full lg:ml-4 justify-between">
                <div className="flex h-3/6 justify-between mr-6">
                  <div className='text-center'>
                    <div className="w-92 mx-3">
                      <Search className="searchNoAnimation">
                        <SearchIconWrapper>
                          <SearchIcon className="dark:text-fontWhite" />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder={t("header.search")}
                          onKeyPress={(e) => {
                            startSearching(e);
                          }}
                          inputProps={{ "aria-label": "search" }}
                          onChange={(e) => {
                            handleChangeInSearchBox(e);
                          }}
                        />
                      </Search>
                    </div>
                  </div>
                  <div className="iconsbuttons gap-2 lg:gap-3 flex justify-end">
                    <span className="cursor-pointer">
                      <FadeMenu language={language} setLanguage={setLanguage} />
                    </span>
                    {props.currentTheme === "dark" ? (
                      <LightMode
                        className="mt-3.5 dark:text-lightsky cursor-pointer"
                        onClick={() => {
                          // localStorage.setItem('theme','light');
                          store.dispatch({
                            type: ActionTypes.ADD_THEME,
                            payload: 'white',
                          })
                          props.setCurrentTheme("white")
                        }}
                      />
                    ) : (
                      <ModeNight
                        className="mt-3.5 text-black cursor-pointer"
                        onClick={() => {
                          // localStorage.setItem('theme','dark')
                          store.dispatch({
                            type: ActionTypes.ADD_THEME,
                            payload: 'dark',
                          })
                          props.setCurrentTheme("dark")
                        }}
                      />
                    )}
                    {user_id !== "null" && user_id !== null && (
                      <span
                        className=" py-3  text-sm font-medium uppercase rounded-md cursor-pointer "
                        onClick={() => {
                          history.push({
                            pathname: `/wishlist`,
                            state: { id: 0, type: "Wishlist" },
                          });
                        }}
                      >
                        <Badge
                          badgeContent={wishlistData}
                          classes={{ badge: classes.customBadge }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiBadge-badge": {
                              fontSize: 12,
                              height: 18,
                              left: "4px",
                              minWidth: 18,
                              background: AppConfig.color.secondary,
                              color: "white",
                            },
                          }}
                        >
                          <Tooltip title={t("header.wishlist")}>
                            <FavoriteIcon className="text-fontDark dark:text-fontWhite" />
                          </Tooltip>
                        </Badge>
                      </span>
                    )}
                    {user_id !== null && user_id !== "null" && (
                      <IconButton
                        className="bg-none"
                        size="medium"
                        aria-label="search"
                        color="inherit"
                      >
                        <Badge
                          badgeContent={notificationCount}
                          classes={{ badge: classes.customBadge }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiBadge-badge": {
                              fontSize: 12,
                              height: 18,
                              minWidth: 18,
                              background: AppConfig.color.secondary,
                              color: "white",
                            },
                          }}
                        >
                          <Tooltip title={t("header.notifications")}>
                            <NotificationsActiveIcon
                              className="p-0 text-fontDark dark:text-fontWhite"
                              id="basic-button"
                              aria-controls="basic-menu"
                              aria-haspopup="true"
                              aria-expanded={
                                openNotification ? "true" : undefined
                              }
                              onClick={handleClickNotification}
                            />
                          </Tooltip>
                        </Badge>
                      </IconButton>
                    )}
                    <Menu

                      id="basic-menu"
                      anchorEl={anchorElNotification}
                      open={openNotification}
                      onClose={handleCloseNotification}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <div className="grid grid-flow-row w-330 dark:bg-lightDarkBackground mx-2 text-justify break-words ">
                        {notification?.map((ele, key) => (
                          <React.Fragment>
                            <div
                              onClick={() => {
                                if (ele.data.action == "order_details") {
                                  history.push(
                                    "/my-orders/" + ele.data.other_id
                                  );
                                } else if (ele.data.action == "message") {
                                  history.push({
                                    pathname: `/my-chats`,
                                    state: {
                                      chatId: ele.data.other_id,
                                      productId: ele.data.other_data.product_id,
                                      productName:
                                        ele.data.other_data.product_name,
                                    },
                                  });
                                  setAnchorElNotification(null);
                                } else if (
                                  ele.data.action == "product_details"
                                ) {
                                  history.push({
                                    pathname: `/product-details/${ele.data.other_data.slug}/${ele.data.other_id}`,
                                  });
                                  setAnchorElNotification(null);
                                } else if (ele.data.action == "cart") {
                                  history.push({
                                    pathname: `/cart-details`,
                                  });
                                  setAnchorElNotification(null);
                                } else if (ele.data.action == "payouts") {
                                  setAnchorElNotification(null);
                                }
                              }}
                              className="mx-2 my-2 cursor-pointer"
                            >
                              <div className="flex align-center justify-between">
                                <div className="flex  align-center">
                                  <RadioButtonCheckedIcon
                                    sx={{
                                      height: 20,
                                      width: 20,
                                    }}
                                    className="mt-1 mr-2"
                                  />
                                  <Typography
                                    variant="subtitle1"
                                    className="text-md dark:text-fontWhite mr-8 font-bold"
                                  >
                                    {ele.data.title}
                                  </Typography>
                                </div>
                                <div className="flex flex-col">
                                  <div>
                                    <Typography className="float-right dark:text-fontWhite mt-1 text-xs text-right text-nowrap">
                                      {ele.created_at.slice(0, 10)}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography className=" mt-1 dark:text-fontWhite text-xs text-right text-nowrap">
                                      {ele.created_at.slice(11, 19)}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <Typography
                                variant="span "
                                className="text-md mr-2  dark:text-fontWhite font-normal"
                              >
                                {ele.data.message}
                              </Typography>
                            </div>
                            <Divider />
                          </React.Fragment>
                        ))}
                      </div>
                    </Menu>

                    <IconButton
                      className="bg-none"
                      size="medium"
                      aria-label="search"
                      color="inherit"
                    >
                      <Badge
                        badgeContent={totalCart}
                        classes={{ badge: classes.customBadge }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            fontSize: 12,
                            height: 18,
                            minWidth: 18,
                            background: AppConfig.color.secondary,
                            color: "white",
                          },
                        }}
                      >
                        {/* <Tooltip title={t("header.cart")}> */}
                        {/* <ShoppingBagOutlinedIcon
                          className="text-fontDark dark:text-fontWhite"
                          id="basic-button"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={() => history.push(AppConfig.routes.cart)}
                        /> */}
                        <ShoppingBagOutlinedIcon
                          className="text-fontDark dark:text-fontWhite"
                          id="basic-button"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onMouseEnter={() => setCartOverviewOpen(true)}
                          onClick={() => history.push(AppConfig.routes.cart)}
                        />
                        {/* </Tooltip> */}
                        {(totalCart > 0 && cartOverviewOpen && !pathname.includes('cart')) &&
                          <div
                            // onClick={(e) => e.stopPropagation()}
                            onMouseEnter={async () => {
                              await clearTimeout(timeoutId);
                              await setTimeoutId(null);
                              setCartOverviewOpen(true);
                            }}
                            onMouseLeave={async () => {
                              const id = await setTimeout(() => {
                                setCartOverviewOpen(false);
                              }, 500);
                              setTimeoutId(id);
                            }}
                            className={`${cartOverviewOpen ? "" : "hidden"
                              } top-full  flex flex-col items-center p-4 pt-2 -translate-x-full  rounded-xl shadow-3xl min-h-0 right-0 w-96 z-50 max-h-96 overflow-hidden absolute bg-login dark:bg-lightDarkBackground`}
                          >
                            <div className="text-lg text-button dark:text-fontWhite uppercase tracking-widest">
                              {t('header.cart')}
                            </div>
                            <hr className="bg-fontDark w-4/12 text-fontWhite h-0.75" />
                            <div className="w-full mb-2">
                              {" "}
                              <CartDetails overview={cartOverviewOpen} />
                            </div>
                          </div>}
                      </Badge>
                    </IconButton>
                    {(user_id === null || user_id === "null") && (
                      <span
                        className=" py-4 px-0 text-sm font-medium uppercase rounded-md cursor-pointer "
                        onClick={() =>
                          history.push({
                            pathname: `/login`,
                            state: {
                              navigationUrl: window.location.href,
                            },
                          })
                        }
                      >
                        <Tooltip title={t("header.login")}>
                          <AccountCircleIcon className="text-fontDark dark:text-fontWhite" />
                        </Tooltip>
                      </span>
                    )}
                    {user_id !== null && user_id !== "null" && (
                      <IconButton
                        className="bg-none"
                        size="medium"
                        aria-label="search"
                        color="inherit"
                      >
                        <Tooltip title={t("header.account")}>
                          <AccountCircleIcon
                            className="mt-1 text-fontDark dark:text-fontWhite"
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={profileOpen ? "true" : undefined}
                            onClick={handleClickProfile}
                          />
                        </Tooltip>

                        <Menu

                          id="basic-menu"
                          anchorEl={anchorElprofile}
                          open={profileOpen}
                          onClose={handleCloseProfile}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem className="d-block -mt-2 dark:bg-lightDarkBackground dark:text-fontWhite text-center">
                            <Typography>{user.user?.name}</Typography>
                            <Divider className="h-1 my-2 bg-button" />
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              history.push(AppConfig.routes.profile);
                              setAnchorElProfile(!anchorElprofile);
                            }}
                            className="text-sm max-w-330 dark:bg-lightDarkBackground hover:bg-primary dark:text-fontWhite hover:text-white"
                          >
                            {t("profileMenu.profile")}
                          </MenuItem>
                          <MenuItem
                            className="text-sm max-w-330 dark:bg-lightDarkBackground hover:bg-primary dark:text-fontWhite hover:text-white"
                            onClick={() => {
                              history.push(AppConfig.routes.bankDetails);
                              setAnchorElProfile(!anchorElprofile);
                            }}
                          >
                            {t("profileMenu.myBankDetails")}
                          </MenuItem>
                          <MenuItem
                            className="text-sm max-w-330 dark:bg-lightDarkBackground hover:bg-primary dark:text-fontWhite hover:text-white"
                            onClick={() => {
                              history.push(AppConfig.routes.myEarnings);
                              setAnchorElProfile(!anchorElprofile);
                            }}
                          >
                            {t("profileMenu.myEarnings")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              history.push(AppConfig.routes.myChats);
                              setAnchorElProfile(!anchorElprofile);
                            }}
                            className="text-sm dark:bg-lightDarkBackground dark:text-fontWhite max-w-330 hover:bg-primary hover:text-white"
                          >
                            {t("profileMenu.myChats")}
                          </MenuItem>
                          {user_id !== "null" && user_id !== null && (
                            <MenuItem
                              className=" py-2 text-sm dark:bg-lightDarkBackground dark:text-fontWhite hover:bg-primary hover:text-white font-medium  cursor-pointer "
                              onClick={() => {
                                history.push(AppConfig.routes.myOrders);
                                setAnchorElProfile(!anchorElprofile);
                              }}
                            >
                              {t("profileMenu.myOrders")}
                            </MenuItem>
                          )}
                          <MenuItem
                            className="py-2 text-sm dark:bg-lightDarkBackground dark:text-fontWhite hover:bg-primary hover:text-white font-medium  cursor-pointer "
                            onClick={() => {
                              history.push(AppConfig.routes.cart);
                              setAnchorElProfile(!anchorElprofile);
                            }}
                          >
                            {t("profileMenu.myCart")}
                          </MenuItem>
                          {user_id !== "null" && user_id !== null && (
                            <MenuItem
                              className=" py-2 text-sm dark:bg-lightDarkBackground dark:text-fontWhite hover:bg-primary hover:text-white font-medium  cursor-pointer "
                              onClick={() => {
                                history.push({
                                  pathname: `/manage-addresses`,
                                  state: { id: 0, type: "Following" },
                                });
                                setAnchorElProfile(!anchorElprofile);
                              }}
                            >
                              {t("profileMenu.manageAddress")}
                            </MenuItem>
                          )}
                          <MenuItem
                            className="text-sm max-w-330 dark:bg-lightDarkBackground hover:bg-primary dark:text-fontWhite hover:text-white border-b border-fontDark"
                            onClick={() => {
                              history.push(AppConfig.routes.myReviews);
                              setAnchorElProfile(!anchorElprofile);
                            }}
                          >
                            {t("profileMenu.myReviews")}
                          </MenuItem>
                          {user_id !== "null" && user_id !== null && (
                            <MenuItem
                              className=" py-2 text-sm dark:bg-lightDarkBackground dark:text-fontWhite hover:bg-primary hover:text-white font-medium  cursor-pointer "
                              onClick={() => {
                                history.push({
                                  pathname: `/details/my-following`,
                                  state: { id: 0, type: "Following" },
                                });
                                setAnchorElProfile(!anchorElprofile);
                              }}
                            >
                              {t("profileMenu.myFollowing")}
                            </MenuItem>
                          )}
                          <hr className="border-fontDark" />
                          <MenuItem
                            className="py-2 text-sm dark:bg-lightDarkBackground dark:text-fontWhite hover:bg-primary hover:text-white font-medium  cursor-pointer"
                            onClick={() => {
                              setDeletePopup(true);
                              setAnchorElProfile(!anchorElprofile);
                            }}>
                            {t("profileMenu.deleteAccount")}
                          </MenuItem>
                          <hr className="border-fontDark" />
                          <MenuItem
                            onClick={() => {
                              setLogout();
                              setAnchorElProfile(!anchorElprofile);
                            }}
                            className="text-sm -mb-2  dark:bg-lightDarkBackground dark:text-fontWhite max-w-330 hover:bg-primary hover:text-white"
                          >
                            {t("profileMenu.logout")}
                          </MenuItem>
                        </Menu>
                      </IconButton>
                    )}
                  </div>
                </div>
                <div className="-mt-1">
                  <nav className="mr-3 flex justify-between ">
                  </nav>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </React.Fragment>
    )
  );
}
export default Header2;
