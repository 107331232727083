import axios from "axios";
import AppConfig from "../appConfig";
import JwtService from "../services/jwtService";
const lang = localStorage.getItem('i18nextLng')
const http = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: 75000,
  headers: {
    Accept: "application/json",
    "lang": lang,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Content-Security-Policy": "script-src 'self' https://admin.nily.com.br"
  },
});

http.interceptors.request.use(async (config) => {
  if (JwtService.getAccessToken()) {
    const token = JwtService.getAccessToken();

    config.headers.Authorization = token ? "Bearer " + token : "Bearer ";
  }

  return config;
});

http.interceptors.response.use(
  async function (response) {
    return { status: true, code: response.status, data: response.data };
  },
  function ({ response }) {
    // return Promise.reject({status: false, code: response.status, ...response.data});
    if (typeof response !== "undefined") {
      return { status: false, code: response.status, ...response.data };
    } else {
      return { status: false, code: 504, message: "Gateway Timeout." };
    }
  }
);

export default {
  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  get: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .get(url, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  getSync: function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .get(url, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  post: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  put: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .put(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  delete: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .delete(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  options: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .options(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetch: (options) => http(options),
};
